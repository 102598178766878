import { HammerModule, HAMMER_GESTURE_CONFIG, provideClientHydration, withHttpTransferCacheOptions, withI18nSupport } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AuthInterceptor } from 'app/shared/interceptors/auth.interceptor';
import { AuthService, authFactory } from 'app/shared/services/auth/auth.service';
import { CachingInterceptor } from 'app/shared/interceptors/caching.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomLoaderFactory } from './custom-loader-factory';
import { TranslateLoader, MissingTranslationHandler, provideTranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from 'app/shared/shared.module';
import { AppLayoutComponent } from './shared/layouts/app-layout/app-layout';
import { LightLayoutComponent } from './shared/layouts/light-layout/light-layout';
import { CustomMissingTranslationHandler } from './shared/services/translations/missing-translation.handler';
import { VersioningInterceptor } from 'app/shared/interceptors/versioning.interceptor';
import { getAuthServiceConfigs } from 'app/shared/services/third-parties-auth/third-parties-auth.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ScreenTrackingService, UserTrackingService, getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { WhiteLabelHomePage } from './shared/containers/home/white-label/white-label-home.page';
import { environment } from '@env/environment';
import { CoreModule } from 'app/core/core.module';
import {
  getFirestore,
  provideFirestore
} from '@angular/fire/firestore';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { AppRoutingModule } from 'app/app.routing.module';
import { HammerConfig } from './HammerGestureConfig';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { AngularFireAnalyticsModule, CONFIG } from '@angular/fire/compat/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { CoreStoreModule } from './core-store.module';

@NgModule({ declarations: [
        AppComponent,
        AppLayoutComponent,
        LightLayoutComponent,
        WhiteLabelHomePage
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAnalyticsModule,
        AppRoutingModule,
        AuthModule,
        BrowserAnimationsModule,
        CoreModule,
        EffectsModule.forRoot([]),
        HammerModule,
        SharedModule,
        CoreStoreModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
        FontAwesomeModule], providers: [
        SsrCookieService,
        FeatureFlagsService,
        { provide: APP_INITIALIZER, useFactory: authFactory, deps: [AuthService], multi: true },
        { provide: 'SocialAuthServiceConfig', useFactory: getAuthServiceConfigs },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: VersioningInterceptor, multi: true },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        {
            provide: CONFIG,
            useValue: {
                linker: {
                    domains: ['id90travel.com', 'cruise.id90travel.com', 'cs.cruisebase.com']
                }
            }
        },
        ScreenTrackingService,
        UserTrackingService,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideAuth(() => getAuth()),
        provideAnalytics(() => getAnalytics()),
        provideFirestore(() => getFirestore()),
        providePerformance(() => getPerformance()),
        provideRemoteConfig(() => getRemoteConfig()),
        provideTranslateService({
            loader: {
                provide: TranslateLoader,
                useFactory: (CustomLoaderFactory),
                deps: [Store, AirlinesService]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler
            },
        }),
        provideHttpClient(withInterceptorsFromDi()),
        provideClientHydration(
          withHttpTransferCacheOptions({
            includePostRequests: true,
            includeRequestsWithAuthHeaders: true,
          }),
          withI18nSupport(),
        ),
    ] })
export class AppModule { }
