import { Action } from '@ngrx/store';

export enum MemberInterestsActionTypes {
  GetMemberInterests = '[Profile] Get Member Interests',
  GetMemberInterestsSuccess = '[Profile] Get Member Interests Success',
  GetMemberInterestsFailure = '[Profile] Get Member Interests Failure',
  SetMemberStatus = '[Profile] Set Member Subscription Status',
  SetMemberStatusSuccess = '[Profile] Set Member Subscription Status Success',
  SetMemberStatusFailure = '[Profile] Set Member Subscription Status Failure',
}

export class GetMemberInterests implements Action {
  readonly type = MemberInterestsActionTypes.GetMemberInterests;
  constructor(public payload: { email: string }) {}
}

export class GetMemberInterestsSuccess implements Action {
  readonly type = MemberInterestsActionTypes.GetMemberInterestsSuccess;

  constructor(public payload: { interests: any }) {}
}

export class GetMemberInterestsFailure implements Action {
  readonly type = MemberInterestsActionTypes.GetMemberInterestsFailure;

  constructor(public payload: { error: any }) {}
}

export class SetMemberStatus implements Action {
  readonly type = MemberInterestsActionTypes.SetMemberStatus;

  constructor(public payload: { listName: string, subscribed: boolean }) {}
}

export class SetMemberStatusSuccess implements Action {
  readonly type = MemberInterestsActionTypes.SetMemberStatusSuccess;

  constructor(public payload: { response: any }) {}
}

export class SetMemberStatusFailure implements Action {
  readonly type = MemberInterestsActionTypes.SetMemberStatusFailure;

  constructor(public payload: { error: any }) {}
}


export type MemberInterestsActionsUnion =
  | GetMemberInterests
  | GetMemberInterestsSuccess
  | GetMemberInterestsFailure
  | SetMemberStatus
  | SetMemberStatusSuccess
  | SetMemberStatusFailure
