import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Id90UserGuard } from 'app/auth/store/guards/id90user.guard';
import { LoggedUserGuard } from 'app/auth/store/guards/logged-user.guard';
import { VerifiedUserGuard } from 'app/auth/store/guards/verified-user.guard';
import { ProfilePage } from './containers/profile/profile.page';
import { FirstTimeLoginGuard } from 'app/auth/store/guards/first-time-login-guard';
import { GenericUserGuard } from 'app/auth/store/guards/generic-user.guard';
import { JWTLoginGuard } from 'app/auth/store/guards/jwt-login.guard';
import { MFAGuard } from 'app/auth/store/guards/mfa.guard';
import { MissingInfoGuard } from 'app/auth/store/guards/missing-info.guard';
import { PasswordOldCheckGuard } from 'app/auth/store/guards/password-old-check.guard';
import { AppLayoutComponent } from 'app/shared/layouts/app-layout/app-layout';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [FirstTimeLoginGuard, GenericUserGuard, JWTLoginGuard, PasswordOldCheckGuard, MFAGuard, MissingInfoGuard],
    children: [
      {
        path: '',
        component: ProfilePage,
        pathMatch: 'full',
        canActivate: [LoggedUserGuard, VerifiedUserGuard],
        data: { title: 'Profile' }
      },
      {
        path: ':tab/:section',
        component: ProfilePage,
        pathMatch: 'full',
        canActivate: [LoggedUserGuard, VerifiedUserGuard, Id90UserGuard],
        data: { title: 'Profile', snapshotParams: true }
      },
      {
        path: ':tab',
        component: ProfilePage,
        pathMatch: 'full',
        canActivate: [LoggedUserGuard, VerifiedUserGuard, Id90UserGuard],
        data: { title: 'Profile', snapshotParams: true }
      },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule { }
