import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { take, takeUntil } from 'rxjs/operators';
import * as fromProfile from 'app/profile/store/reducers';
import { GetMemberInterests, SetMemberStatus } from 'app/profile/store/actions/member-interests.actions';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';

@Component({
  selector: 'notification-center-component',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})

export class NotificationCenterComponent implements OnInit {
  @Input() memberEmail: string = '';
  loading = true;
  success = null;
  error = null;
  icons = { faChevronLeft };
  subscriptionForm: FormGroup = this.fb.group({
    interests: this.fb.array([])
  });
  ngUnsubscribe = new Subject<void>();
  memberInterestsSuccess$ = this.store.pipe(select(fromProfile.getMemberInterestsSuccess));
  memberInterestsPending$ = this.store.pipe(select(fromProfile.getMemberInterestsPending));
  memberSetInterestStatusSuccess$ = this.store.pipe(select(fromProfile.getMemberStatusSuccess));

  @Output() showTabs = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public formErrorsHelper: FormErrorsHelper,
    private store: Store<fromProfile.State> ,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    if (this.memberEmail) {
      this.store.dispatch(new GetMemberInterests({ email: this.memberEmail }));
      this.listenForSuccess();
    }
  }

  listenForSuccess() {
    this.memberInterestsSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((memberInterests: any) => {
      if (!memberInterests) return;
      this.populateForm(memberInterests);
    });
  }

  populateForm(memberInterests: any[]) {
    const interestsArray = this.subscriptionForm.get('interests') as FormArray;
    interestsArray.clear();
    
    memberInterests.forEach(interest => {
      interestsArray.push(
        this.fb.group({
          listId: [interest.id],
          listName: [interest.name],
          isSubscribed: [interest.subscribed]
        })
      );
    });
  }

  get interests() {
    return (this.subscriptionForm.get('interests') as FormArray).controls;
  }

  listenForUpdateSuccess() {
    this.memberInterestsSuccess$.pipe(take(1)).subscribe(
      () => {
        this.success = 'profile.info.successful_update';
        setTimeout(() => this.success = '', 1500);
      },
      (error) => {
        this.error = error.error.error_description;
      }
    );
  }

  getSubscriptionValue(memberInterests: any, listId: string): boolean {
    const interest = memberInterests.find((item: any) => item.id === listId);
    return interest ? interest.subscribed : false;
  } 

  toggleSubscription(listName: string) {
    const interestsArray = this.subscriptionForm.get('interests') as FormArray;
    const interestControl = interestsArray.controls.find((control) => control.value.listName === listName);
  
    if (interestControl) {
      const currentSubscribeValue = interestControl.get('isSubscribed')?.value;
      const newSubscribeValue = !currentSubscribeValue;
      interestControl.patchValue({ isSubscribed: newSubscribeValue });
      this.store.dispatch(new SetMemberStatus({ listName, subscribed: newSubscribeValue }));
      this.analyticsService.logEvent('notification_change', {
        method: 'Email',
        newValue: newSubscribeValue,
        listName
      });
      this.listenForUpdateSuccess();
    }
  }

  closeAlert() {
    this.error = null;
    this.success = null;
  }

  getTranslateKey(listId: string, section: string) {
    return `profile.notification_center.items.${listId}.${section}`;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
