import { Action } from '@ngrx/store';

export enum InviteStatActionTypes {
  LoadInvitesStat = '[Invite] Load Stat Invites',
  LoadInvitesStatSuccess = '[Invite] Load Stat Invites Success',
  LoadInvitesStatFailure = '[Invite] Load Stat Invites Failure',
  ResetInvitesStat = '[Invite] Reset Stat Invites'
}

export class LoadInvitesStat implements Action {
  readonly type = InviteStatActionTypes.LoadInvitesStat;
}

export class LoadInvitesStatSuccess implements Action {
  readonly type = InviteStatActionTypes.LoadInvitesStatSuccess;

  constructor(public payload: { stats: any }) {}
}

export class LoadInvitesStatFailure implements Action {
  readonly type = InviteStatActionTypes.LoadInvitesStatFailure;

  constructor(public payload: { error: any }) {}
}

export class ResetInvitesStat implements Action {
  readonly type = InviteStatActionTypes.ResetInvitesStat;
}


export type InvitesStatActionsUnion = LoadInvitesStat
  | LoadInvitesStatSuccess
  | LoadInvitesStatFailure
  | ResetInvitesStat;
