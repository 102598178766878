import {
    AfterViewInit,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Action, Store, select } from '@ngrx/store';
import { UserSelectorComponent } from 'app/auth/components/user-selector/user-selector.component';
import * as fromAuth from 'app/auth/store/reducers';
import { HotelReviewModal } from 'app/hotels/containers/hotel-review-modal/hotel-review-modal';
import * as fromProfile from 'app/profile/store/reducers';
import { CarouselComponent } from 'app/shared/components/carousel/carousel.component';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { SlugifyHelper } from 'app/shared/helpers/slugify.helper';
import { featureFlags } from 'app/shared/models/featureFlags';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { SeoService } from 'app/shared/services/seo/seo.service';
import { SessionService } from 'app/shared/services/session/session.service';
import {
    AbandonedHotelActions,
    AirportsRecentArrivalsSearchesActions,
    AirportsRecentDeparturesSearchesActions,
    AllInclusiveActions,
    CarsRecentSearchesActions,
    CurrentLocationActions,
    HotelsRecentSearchesActions,
    HotelsRecentViewsActions,
    RecommendedDestinationsActions,
    TrendingDestinationsActions
} from 'app/shared/store/actions';
import * as fromShared from 'app/shared/store/reducers';
import { pick } from 'lodash';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import * as fromRoot from 'reducers';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { startWith, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'search',
  templateUrl: './search.html',
  styleUrls: ['./search.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SearchPage implements OnInit, AfterViewInit, OnDestroy {
  userLoggedIn = false;
  baseImagePath = '/angular_assets/images/homepage/bg-search-';
  searchImages = [
    `${this.baseImagePath}hotels.png`,
    `${this.baseImagePath}cars.jpg`,
    `${this.baseImagePath}cruise.jpg`,
    // `${this.baseImagePath}flights.jpg`
  ];
  backgroundImage = '';
  selectedTab = 'hotels-tab';
  schema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: 'ID90 Travel',
    url: 'https://www.id90travel.com'
  };

  loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
  userStatistics$ = this.store.pipe(select(fromAuth.getUserStatistics));
  userCredits$ = this.store.pipe(select(fromProfile.getUserCredits));
  userCreditHistory$ = this.store.select(fromProfile.getUserCreditHistory);
  profileUser$ = this.store.pipe(select(fromProfile.getUserProfile));
  allInclusiveDestinations$ = this.store.pipe(select(fromShared.getAllInclusiveDestinations));
  allInclusiveDestinationsLoaded$ = this.store.pipe(select(fromShared.getAllInclusiveDestinationsLoaded));
  trendingDestinations$ = this.store.pipe(select(fromShared.getTrendingDestinations));
  trendingDestinationsPending$ = this.store.pipe(select(fromShared.getTrendingDestinationsPending));
  trendingDestinationsLoaded$ = this.store.pipe(select(fromShared.getTrendingDestinationsLoaded));
  hotelsRecentSearches$ = this.store.pipe(select(fromShared.getHotelsRecentSearches));
  hotelsRecentSearchesPending$ = this.store.pipe(select(fromShared.getHotelsRecentSearchesPending));
  hotelsRecentSearchesLoaded$ = this.store.pipe(select(fromShared.getHotelsRecentSearchesLoaded));
  hotelsRecentViews$ = this.store.pipe(select(fromShared.getHotelsRecentViews));
  recommendedDestinations$ = this.store.pipe(select(fromShared.getRecommendedDestinations));
  recommendedDestinationsLoaded$ = this.store.pipe(select(fromShared.getRecommendedDestinationsLoaded));
  carsRecentSearches$ = this.store.pipe(select(fromShared.getCarsRecentSearches));
  carsRecentSearchesPending$ = this.store.pipe(select(fromShared.getCarsRecentSearchesPending));
  airportsRecentDeparturesSearchesLoaded$ = this.store.pipe(select(fromShared.getAirportsRecentDeparturesSearchesLoaded));
  airportsRecentArrivalsSearchesLoaded$ = this.store.pipe(select(fromShared.getAirportsRecentArrivalsSearchesLoaded));
  carsRecentSearchesLoaded$ = this.store.pipe(select(fromShared.getCarsRecentSearchesLoaded));
  abandonedHotel$ = this.store.pipe(select(fromShared.getAbandonedHotel));
  abandonedHotelLoaded$ = this.store.pipe(select(fromShared.getAbandonedHotelLoaded));
  isFraudUser$ = this.store.pipe(select(fromAuth.getFraudStatus));
  airline = this.activatedRoute.snapshot.queryParams['airline'] || '';
  shareCode = this.activatedRoute.snapshot.queryParams['share_code'] || '';
  userAccount$ = this.store.pipe(select(fromAuth.selectAuthStatusState));
  availableTools$ = this.store.pipe(select(fromAuth.getAvailableTools));
  currentLocation$ = this.store.pipe(select(fromShared.getCurrentLocation));

  userStatistics: any = {};
  userCredits: any = {};
  featureFlags = featureFlags;
  private ngUnsubscribe = new Subject<void>();
  private routeSub: Subscription;
  isFraudUser = false;
  isMobileView = this.sizeScreen();
  customerSupportModalOpen = false;
  memberRewardsAvailable = false;

  public showSearchHeroBanner = false;

  constructor(
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private generalHelper: GeneralHelper,
    private store: Store<fromRoot.State>,
    private router: Router,
    private slugifyHelper: SlugifyHelper,
    private cookieService: SsrCookieService,
    private seoService: SeoService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private featureFlagsService: FeatureFlagsService
  ) { }

  ngOnInit() {
    this.saveCodes();
    this.memberRewardsAvailable = this.featureFlagsService.isFeatureEnabled(featureFlags.MEMBER_REWARDS);
    this.showSearchHeroBanner = this.featureFlagsService.isFeatureEnabled(featureFlags.HOTEL_HERO_SECTION);
    this.loggedIn$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((loggedIn) => {
      if (loggedIn) {
        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((arQueryParams) => {
          const url = `${window.location.pathname}${window.location.href.split(window.location.pathname).pop()}`;
          if (url.split('?')[0] === '/hotel-review') { this.openHotelReviewModal(arQueryParams); }
        });
        this.checkFraudUser();
        this.userStatistics$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(statistics => this.userStatistics = statistics);
        this.loadResultsFromStore(this.trendingDestinationsLoaded$, true, true, new TrendingDestinationsActions.Load({}));
        this.loadResultsFromStore(this.hotelsRecentSearchesLoaded$, true, true, new HotelsRecentSearchesActions.Load());
        this.loadResultsFromStore(this.carsRecentSearchesLoaded$, true, true, new CarsRecentSearchesActions.Load());
        this.loadResultsFromStore(this.abandonedHotelLoaded$, true, true, new AbandonedHotelActions.Load());
        this.loadResultsFromStore(this.recommendedDestinationsLoaded$, true, true, new RecommendedDestinationsActions.Load());
        this.loadResultsFromStore(this.allInclusiveDestinationsLoaded$, true, true, new AllInclusiveActions.Load());
        this.loadResultsFromStore(this.airportsRecentArrivalsSearchesLoaded$,
          true,
          true,
          new AirportsRecentArrivalsSearchesActions.Load());
        this.loadResultsFromStore(this.airportsRecentDeparturesSearchesLoaded$,
          true,
          true,
          new AirportsRecentDeparturesSearchesActions.Load());
        this.loadRecentViews();
      }
    });

    this.currentLocation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((currentLocation) => {
      if (!currentLocation) {
        this.store.dispatch(new CurrentLocationActions.Load());
      } else {
        this.store.dispatch(new TrendingDestinationsActions.Load({}));
      }
    });

    this.userAccount$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((auth) => {
      this.userLoggedIn = auth.loggedIn;
      if (auth && this.userLoggedIn) {
        if (!auth.sessionTimeOut && this.shareCode) {
          return this.router.navigate(['/share-itinerary']);
        }
        this.airline = auth.user.account.airlineCode;
        this.openCustomerUserModal(auth);
      }
    });

    this.routeSub = this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      if (params.tab) {
        this.selectedTab = `${params.tab}-tab`;
        if (this.selectedTab) {
          this.onSearchTabChanged(this.selectedTab);
        }
      }
    });

    combineLatest([
      this.profileUser$,
      this.userCreditHistory$,
      this.userCredits$]).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(([
        user,
        creditsHistory,
        credits
      ]: any) => {
        if (user && credits) {
          this.userCredits = Object.assign({}, credits, { currency: user ? user.currency : 'USD' });
          if (creditsHistory) {
            this.userCredits.history = creditsHistory;
          }
        }
      });
  }

  openCustomerUserModal(state) {
    const operator = state.user.call_center_admin;
    const userSelected = state.customerSupport && state.customerSupport.userSelectedByOperator;
    if (operator && !userSelected && !this.customerSupportModalOpen) {
      this.customerSupportModalOpen = true;
      this.modalService.open(UserSelectorComponent);
    }
  }

  ngAfterViewInit(): void {
    if (this.selectedTab) {
      this.onSearchTabChanged(this.selectedTab);
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileView = this.sizeScreen();
  }

  loadResultsFromStore(observable$: Observable<any>, startingValue: boolean, checkLoaded: boolean, action: Action) {
    observable$.pipe(
      startWith(startingValue),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((loaded: boolean) => {
      if (checkLoaded) {
        if (!loaded) {
          this.store.dispatch(action);
        }
      } else {
        this.store.dispatch(action);
      }
    });
  }

  loadRecentViews(): void {
    this.store.dispatch(new HotelsRecentViewsActions.Load());
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  user() {
    return this.sessionService.getUser() || null;
  }
  sizeScreen() {
    return window.screen.width <= 990;
  }


  onSearchTabChanged(event) {
    const bgIndex = this.setSearchBackground(event);
    this.backgroundImage = this.searchImages[bgIndex];
    this.setPageTitle(event);

  }

  checkAirlineAndUser(airlineCode, userName) {
    const user = this.user();
    return airlineCode === user['airline_code'] && userName === user['employee_number'];
  }

  setPageTitle($page) {
    if ($page === 'hotels-tab') {
      this.seoService.setTitle('Hotel Search');
    } else if ($page === 'cars-tab') {
      this.seoService.setTitle('Car Search');
    }
  }

  setSearchBackground($page) {
    let bgIndex = 0;
    if ($page === 'hotels-tab') {
      bgIndex = 0;
    } else if ($page === 'cars-tab') {
      bgIndex = 1;
    }
    // We don't want to change the image because the tab is redirecting outside ID90
    // else if ($page === 'cruises-tab') {
    //   bgIndex = 2;
    // }
    return bgIndex;
  }

  scrollToFeatures() {
    this.generalHelper.scrollToElement('#features');
  }

  onHotelSearchInitiated($event) {
    const destinationSlug = this.slugifyHelper.slugify($event.destination);
    return this.router.navigate([
      `/hotels/search/${destinationSlug}`,
      pick($event, [
        'checkin',
        'checkout',
        'destination',
        'guests[]',
        'businessTrip',
        'latitude',
        'longitude',
        'locationType',
        'locationResolution',
        'locationCountryCode',
        'accommodationType'
      ])
    ]);
  }

  closeAppBanner() {
    this.cookieService.set('hideBanner', 'true');
  }

  openHotelReviewModal(params) {
    const hotelReviewModal = this.modalService.open(HotelReviewModal, { size: 'lg', backdrop: 'static' });
    hotelReviewModal.componentInstance.completeItineraryId = params.itinerary_id ? params.itinerary_id : null;

    hotelReviewModal.result.then(
      (resp) => {
        if (resp.redirect) { this.router.navigateByUrl('/'); }
      }
    );
  }

  checkFraudUser() {
    combineLatest([this.availableTools$, this.isFraudUser$]).pipe(take(1))
      .subscribe(([tools, fraudUser]) => {
        this.isFraudUser = fraudUser;
        if (this.isFraudUser && (tools && (tools.length > 1 || tools[0] !== 'id90'))) {
          return this.router.navigate(['/flights']);
        }
      });
  }

  saveCodes() {
    if (this.shareCode) {
      this.sessionService.setShareCode(this.shareCode);
    }
  }
}
