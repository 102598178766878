import { createAction, props } from '@ngrx/store';

export const loadRate = createAction(
  '[Rate] Load Rate',
  props<{ currencyCode: string }>()
);

export const loadRateSuccess = createAction(
  '[Rate] Load Rate Success',
  props<{ rate: any }>()
);

export const loadRateFailure = createAction(
  '[Rate] Load Rate Failure',
  props<{ error: any }>()
);


export const clear = createAction(
  '[Rate] Clear Rate'
);
