import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from 'reducers';
import * as fromSearchFilters from './search-filters.reducer';

export interface CruisesState {
  searchFilters: fromSearchFilters.State;
}

export interface State extends fromRoot.State {
  cruises: CruisesState;
}

export const reducers: ActionReducerMap<CruisesState> = {
  searchFilters: fromSearchFilters.reducer
};

export const selectCruisesState = createFeatureSelector<CruisesState>('cruises');
export const selectSearchFiltersState = createSelector(selectCruisesState, (state: CruisesState) => state.searchFilters);
export const getLoadSearchFiltersPending = createSelector(selectSearchFiltersState, fromSearchFilters.getLoadSearchFiltersPending);
export const getLoadSearchFiltersSuccess = createSelector(selectSearchFiltersState, fromSearchFilters.getLoadSearchFiltersSuccess);