import { Action } from '@ngrx/store';

export enum SearchFiltersActionTypes {
  LoadSearchFilters = '[Cruises] Load Search Filters',
  LoadSearchFiltersSuccess = '[Cruises] Load Search Filters Success',
  LoadSearchFiltersFailure = '[Cruises] Load Search Filters Failure',
}

export class loadSearchFilters implements Action {
  readonly type = SearchFiltersActionTypes.LoadSearchFilters;
  constructor(public payload: { params: string }) {}
}

export class LoadSearchFiltersSuccess implements Action {
  readonly type = SearchFiltersActionTypes.LoadSearchFiltersSuccess;

  constructor(public payload: { response: any }) {}
}

export class LoadSearchFiltersFailure implements Action {
  readonly type = SearchFiltersActionTypes.LoadSearchFiltersFailure;

  constructor(public payload: { error: any }) {}
}

export type SearchFiltersActionsUnion =
  | loadSearchFilters
  | LoadSearchFiltersSuccess
  | LoadSearchFiltersFailure;