import { AbandonedHotelEffects } from "./abandoned-hotel.effects";
import { AirlineEffects } from "./airlines.effects";
import { AirportEffects } from "./airports.effects";
import { AirportsRecentArrivalsSearchesEffects } from "./airports-recent-arrivals-searches.effects";
import { AirportsRecentDeparturesSearchesEffects } from "./airports-recent-departure-searches.effects";
import { AllInclusiveEffects } from "./all-inclusive.effects";
import { CarsRecentSearchesEffects } from "./cars-recent-searches.effects";
import { CountryEffects } from "./country.effects";
import { CurrentLocationEffects } from "./current-location.effects";
import { FlagsEffects } from "./flags.effects";
import { FlightWatchlistEffects } from "./flight-watchlist.effects";
import { GamificationEffects } from "./gamification.effects";
import { HotelsRecentViewsEffects } from "./hotels-recent-views.effects";
import { LanguageEffects } from "./language.effects";
import { RateEffects } from "./rate.effects";
import { RecentSearchesEffects } from "./recent-searches.effects";
import { RecommendedDestinationsEffects } from "./recommended-destinations.effects";
import { RouterEffects } from "./router.effects";
import { TrendingDestinationsEffects } from "./trending-destinations.effects";
import { UnlinkEmailEffects } from "./unlink-email.effects";

export const effects = [
  AbandonedHotelEffects,
  AirlineEffects,
  AirportEffects,
  AirportsRecentArrivalsSearchesEffects,
  AirportsRecentDeparturesSearchesEffects,
  AllInclusiveEffects,
  CarsRecentSearchesEffects,
  CountryEffects,
  CurrentLocationEffects,
  FlagsEffects,
  FlightWatchlistEffects,
  GamificationEffects,
  HotelsRecentViewsEffects,
  LanguageEffects,
  RateEffects,
  RecentSearchesEffects,
  RecommendedDestinationsEffects,
  RouterEffects,
  TrendingDestinationsEffects,
  UnlinkEmailEffects,
];

export * from "./abandoned-hotel.effects";
export * from "./airlines.effects";
export * from "./airports-recent-arrivals-searches.effects";
export * from "./airports-recent-departure-searches.effects";
export * from "./airports.effects";
export * from "./all-inclusive.effects";
export * from "./cars-recent-searches.effects";
export * from "./country.effects";
export * from "./current-location.effects";
export * from "./flags.effects";
export * from "./flight-watchlist.effects";
export * from "./gamification.effects";
export * from "./hotels-recent-views.effects";
export * from "./language.effects";
export * from "./rate.effects";
export * from "./recent-searches.effects";
export * from "./recommended-destinations.effects";
export * from "./router.effects";
export * from "./trending-destinations.effects";
export * from "./unlink-email.effects";
