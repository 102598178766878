import { Action } from '@ngrx/store';

export enum InviteActionTypes {
  LoadPastInvites = '[Invite] Load Past Invites',
  LoadPastInvitesFailure = '[Invite] Load Past Invites Failure',
  LoadPastInvitesSuccess = '[Invite] Load Past Invites Success',
  ResetPastInvites = '[Invite] Reset Past Invites',
  SendInvites = '[Invite] Send Invites',
  SendInvitesFailure = '[Invite] Send Invites Failure',
  SendInvitesSuccess = '[Invite] Send Invites Success',
}

export class SendInvites implements Action {
  readonly type = InviteActionTypes.SendInvites;

  constructor(public payload: { invitations: any[], type: string }) {}
}

export class SendInvitesSuccess implements Action {
  readonly type = InviteActionTypes.SendInvitesSuccess;

  constructor() {}
}

export class SendInvitesFailure implements Action {
  readonly type = InviteActionTypes.SendInvitesFailure;

  constructor(public payload: { error: any }) {}
}

export class LoadPastInvites implements Action {
  readonly type = InviteActionTypes.LoadPastInvites;

  constructor(public payload: { status?: string, offset: number, limit: number }) {}
}

export class LoadPastInvitesSuccess implements Action {
  readonly type = InviteActionTypes.LoadPastInvitesSuccess;

  constructor(public payload: { invites: any, offset: number }) {}
}

export class LoadPastInvitesFailure implements Action {
  readonly type = InviteActionTypes.LoadPastInvitesFailure;

  constructor(public payload: { error: any }) {}
}

export class ResetPastInvites implements Action {
  readonly type = InviteActionTypes.ResetPastInvites;

  constructor() {}
}


export type InviteActionsUnion = LoadPastInvites
  | LoadPastInvitesSuccess
  | LoadPastInvitesFailure
  | ResetPastInvites
  | SendInvites
  | SendInvitesSuccess
  | SendInvitesFailure;
