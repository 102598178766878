import { SearchFiltersActions } from '../actions';

export interface State {
  pending: boolean;
  error: any | null;
  response: any;
}

export const initialState: State = {
  pending: false,
  error: null,
  response: []
};

export function reducer(
  state = initialState,
  action: SearchFiltersActions.SearchFiltersActionsUnion) {
  switch (action.type) {
    case SearchFiltersActions.SearchFiltersActionTypes.LoadSearchFilters:
      return {
        ...state,
        pending: true
      };
    case SearchFiltersActions.SearchFiltersActionTypes.LoadSearchFiltersSuccess:
      return {
        ...state,
        pending: false,
        response: action.payload.response ? action.payload.response : [],
      };
    case SearchFiltersActions.SearchFiltersActionTypes.LoadSearchFiltersFailure:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export const getLoadSearchFiltersError = (state: State) => state.error;
export const getLoadSearchFiltersPending = (state: State) => state.pending;
export const getLoadSearchFiltersSuccess = (state: State) => state.response;