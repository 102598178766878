import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'hotel-search-modal',
  templateUrl: './hotel-search-modal.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotelSearchModal {
  @Input() hotelSearch;
  @Input() recentSearches;
  @Input() showLocationSearch;
  @Output() searchInitiated = new EventEmitter();

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  dismissClick() {
    this.activeModal.dismiss();
  }
}
