<div class="modal-header border-bottom">
  <button class="btn-close" aria-label="Close" data-cy="close-hotel-search" (click)="dismissClick()"></button>
</div>
<div class="modal-body">
  <hotel-search
    [hotelSearch]="hotelSearch"
    [recentSearches]="recentSearches"
    [showLocationSearch]="showLocationSearch"
    (searchInitiated)="searchInitiated.emit($event)"
  ></hotel-search>
</div>
