import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { GeneralHelper } from '../helpers/general.helper';

@Directive({
  selector: '[appStoryblok]'
})
export class StoryblokDirective implements OnInit {

  @Input('appStoryblok') appStoryblok: string;

  constructor(
    private el: ElementRef,
    private generalHelper: GeneralHelper,
  ) {}

  ngOnInit() {
    if (this.generalHelper.isServer() || typeof this.appStoryblok === 'undefined') {
      return;
    }

    let options = JSON.parse(this.appStoryblok.replace('<!--#storyblok#', '').replace('-->', ''));

    this.el.nativeElement.setAttribute('data-blok-c', JSON.stringify(options));
    this.el.nativeElement.setAttribute('data-blok-uid', options.id + '-' + options.uid);
  }

}
