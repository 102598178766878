import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponsiveService } from 'app/shared/services/responsive/responsive.service';
import { Swiper, SwiperOptions } from 'swiper/types';
import { Controller, FreeMode, Navigation, Thumbs, Virtual } from 'swiper/modules';
@Component({
  selector: 'image-gallery',
  styleUrls: ['./image-gallery.scss'],
  templateUrl: './image-gallery.html',
  encapsulation: ViewEncapsulation.None
})

export class ImageGalleryComponent implements OnInit, AfterViewInit {
  @ViewChild('imageSwiper', { static: false }) imageSwiper: ElementRef<{ swiper: Swiper }>;
  @ViewChild('thumbsSwiper', { static: false }) thumbsSwiper: ElementRef<{ swiper: Swiper }>;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (Boolean(event.isTrusted)) {
      this.setupImages();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.imageSwiper.nativeElement.swiper.slidePrev();
    }
    if (event.key === 'ArrowRight') {
      this.imageSwiper.nativeElement.swiper.slideNext();
    }
  }

  images: any[];
  galleryImages: any[] = [];
  fullSizeImage: string;
  thumbnailImage: string;
  currentScreenSize: string;
  paginationValue: string;
  categories: any[] = [];
  categorySelected = 'All';
  galleryTopConfig: SwiperOptions = {
    modules: [Thumbs, Controller, Virtual, Navigation],
    slidesPerView: 1,
    grabCursor: true,
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    lazyPreloadPrevNext: 5,
    virtual: true,
  };

  thumbsConfig: SwiperOptions = {
    modules: [Thumbs, Controller, FreeMode, Virtual, Navigation],
    freeMode: false,
    spaceBetween: 16,
    centeredSlides: false,
    centeredSlidesBounds: false,
    slideToClickedSlide: true,
    lazyPreloadPrevNext: 5,
    virtual: true,
  };

  private readonly categoriesLength = 7;

  constructor(
    private activeModal: NgbActiveModal,
    private responsiveService: ResponsiveService,
  ) { }

  ngOnInit() {
    this.setupCategories();
    this.setupImages();
  }

  ngAfterViewInit(): void {
    this.setupVirtualSlides();
  }

  setupVirtualSlides() {
    if (!this.galleryImages) { return; }

    const primarySwiper = this.imageSwiper?.nativeElement?.swiper;
    const secondarySwiper = this.thumbsSwiper?.nativeElement?.swiper;

    if (primarySwiper) {
      primarySwiper.slideTo(0)
      this.setupSwiper(primarySwiper);

    }

    if (secondarySwiper) {
      secondarySwiper.slideTo(0)
      this.setupSwiper(secondarySwiper, false);

    }
  }

  setupSwiper(swiper: Swiper, isPrimarySwiper: boolean = true) {
    swiper.virtual.removeAllSlides();
    this.galleryImages.forEach((image, index) => {
      isPrimarySwiper ?
        this.appendHotelImage({ url: image.url, class: 'hotel-image', index }, swiper) :
        this.appendHotelImage({ url: image.thumb, class: 'thumb-image', index }, swiper, false);
    });
    swiper.virtual.update(true);
  }


  appendHotelImage(image: { url: string, class: string, index: number }, swiper: Swiper, isPrimarySwiper: boolean = true) {
    const hotelHtml = `<div class="swiper-slide"><img class="${image.class}" src="${image.url}" alt="Hotel"></div>`;

    const thumbnailHtml = `<div class="w-100 thumb-image"><img class="w-100" src="${image.url}" loading="lazy" alt="Hotel thumbnail"/></div>`;


    if (isPrimarySwiper) {
      swiper.virtual.appendSlide(hotelHtml);
    } else {
      swiper.virtual.appendSlide(thumbnailHtml);
    }
  }


  setupCategories() {
    const categoriesAux: Set<string> = new Set();
    this.images.forEach(image => categoriesAux.add(image.caption));
    categoriesAux.delete('Primary image');
    this.categories = this.buildCategories(Array.from(categoriesAux));
  }

  setupImages() {
    this.currentScreenSize = this.responsiveService.getScreenSizeAlias();
    const galleryImagesAux = [];
    const categorySelected = this.categories.find(category => category.key === this.categorySelected);

    categorySelected.images.forEach((image: any) => {
      const fullSizeImage = image.options && image.options.hasOwnProperty(this.currentScreenSize) ? image.options[this.currentScreenSize] : image.url;
      const thumbnailImage = image.options && image.options.thumbnail ? image.options.thumbnail : fullSizeImage;
      if (fullSizeImage && thumbnailImage) {
        galleryImagesAux.push({ url: fullSizeImage, thumb: thumbnailImage });
      }
    });

    this.galleryImages = galleryImagesAux;
    this.setPaginationTextValue(0);
    this.setupThumbSelected(0);
  }

  dismiss() {
    this.activeModal.dismiss();
  }


  show() {
    const index = this.thumbsSwiper?.nativeElement?.swiper?.clickedIndex;
    if (this.imageSwiper && index) {
      this.setPaginationTextValue(index);
      this.imageSwiper.nativeElement.swiper.slideTo(index);
    }
  }

  indexPadding = 0;

  changeThumbnail(event: CustomEvent) {
    const index = event?.detail[0]?.activeIndex;
    this.setupThumbSelected(index)
    this.setPaginationTextValue(index);
    if (this.thumbsSwiper && (index > 7 || index === 0)) {
      this.thumbsSwiper.nativeElement.swiper.slideTo(index ? index - 7 : index);
    }
  }

  setupThumbSelected(index: number) {
    this.thumbsSwiper?.nativeElement?.swiper.slides.forEach(slide => {
      const slideIndex = slide.attributes.getNamedItem('data-swiper-slide-index')
      if (Number(slideIndex.value) === index) {
        slide.classList.add('slider-thumb-active')
      } else {
        slide.classList.remove('slider-thumb-active')
      }
    })
  }

  setPaginationTextValue(index) {
    const categorySelected = this.categories.find(category => category.key === this.categorySelected);
    this.paginationValue = `${index + 1}/${categorySelected.images.length}`
  }

  buildCategories(categories: string[]) {
    const categoriesItems = [];
    const aux = categories.filter(category => !!category).map(category => {
      const itemSize = this.images.filter(image => image.caption === category);
      return {
        text: `${category} (${itemSize.length})`,
        key: category,
        images: itemSize
      };
    });
    aux.push({ text: `All (${this.images.length})`, key: 'All', images: this.images });
    aux.sort((a, b) => b.images.length - a.images.length);
    aux.forEach((category, index) => {
      if (index < this.categoriesLength) {
        categoriesItems.push(category)
      } else if (this.categoriesLength === index) {
        categoriesItems.push({ key: 'Others', images: category.images })
      } else {
        categoriesItems[this.categoriesLength].images.push(...category.images);
        categoriesItems[this.categoriesLength].text = `Others (${categoriesItems[this.categoriesLength].images.length})`
      }
    });
    return categoriesItems;
  }

  onCategoryPressed(categorySelected) {
    if (categorySelected !== this.categorySelected) {
      this.categorySelected = categorySelected;
      this.setupImages();
      this.setupVirtualSlides();
      this.setupThumbSelected(0);
    }
  }

}
