import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from 'app/shared/layouts/app-layout/app-layout';
import { FirstTimeLoginGuard } from 'app/auth/store/guards/first-time-login-guard';
import { GenericUserGuard } from 'app/auth/store/guards/generic-user.guard';
import { LoggedUserGuard } from 'app/auth/store/guards/logged-user.guard';
import { VerifiedUserGuard } from 'app/auth/store/guards/verified-user.guard';
import { Id90UserGuard } from 'app/auth/store/guards/id90user.guard';
import { OurServicesPage } from 'app/shared/containers/our-services/our-services.page';
import { WatchlistPage } from 'app/profile/containers/watchlist/watchlist.page';
import { ShareItineraryComponent } from 'app/auth/containers/share-itinerary/share-itinerary.component';
import { ContactUsPage } from 'app/shared/containers/contact-us/contact-us.page';
import { TermsAndConditionsPage } from 'app/shared/containers/terms-and-conditions/terms-and-conditions.page';
import { FlexAirlinePage } from 'app/shared/containers/flex/flex-airline.page';
import { LightLayoutComponent } from 'app/shared/layouts/light-layout/light-layout';
import { RegisterPage } from 'app/auth/containers/register.page';
import { InvitedUserGuard } from 'app/auth/store/guards/invited-user.guard';
import { ResendEmailConfirmation } from 'app/auth/containers/resend-email-confirmation.component';
import { RegisterInvitedPage } from 'app/auth/containers/register-invited.page';
import { ConfirmEmailComponent } from 'app/auth/containers/confirm-email.component';
import { RegisterEmailAddress } from 'app/auth/containers/register-email-address.page';
import { ForgotPasswordRequestPage } from 'app/auth/containers/forgot-password/request/request.page';
import { ForgotPasswordResetPage } from 'app/auth/containers/forgot-password/reset/reset.page';
import { FirstTimeLoginPage } from 'app/auth/containers/first-time-login.page';
import { RedirectComponent } from 'app/shared/components/redirect/redirect.component';
import { LogoutComponent } from 'app/auth/containers/logout.component';
import { EventLoginPage } from 'app/auth/containers/event-registration/event-login/event-login.page';
import { AirlineSelectPage } from 'app/auth/containers/event-registration/airline-select/airline-select.page';
import { MarketingUserGuard } from 'app/auth/store/guards/marketing-user.guard';
import { EventRegistrationPage } from 'app/auth/containers/event-registration/event-registration.page';
import { AccountAccessPage } from 'app/auth/containers/account-access/account-access.page';
import { AccountValidationPage } from 'app/auth/containers/account-validation/account-validation.page';
import { UnlinkEmailRedirectPage } from 'app/profile/containers/unlink-email-redirect/unlink-email-redirect.page';
import { WhiteLabelHomePage } from 'app/shared/containers/home/white-label/white-label-home.page';
import { NotFoundPage } from 'app/shared/containers/not-found/not-found.page';
import { MFAGuard } from 'app/auth/store/guards/mfa.guard';
import { SearchPage } from 'app/shared/containers/search/search.page';
import { PasswordOldCheckGuard } from './auth/store/guards/password-old-check.guard';
import { FeatureGuard } from 'app/shared/guards/feature.guard';
import { featureFlags } from 'app/shared/models/featureFlags';
import { JWTLoginGuard } from 'app/auth/store/guards/jwt-login.guard';
import { MissingInfoGuard } from './auth/store/guards/missing-info.guard';
import { ZendeskFormPage } from 'app/shared/containers/zendesk-form/zendesk-form.page';
import { GamificationGuard } from 'app/shared/guards/gamification.guard';
import { LoginPage } from './auth/containers/login.page';
import { StoryBlokRenderComponent } from './shared/containers/story-blok-render/story-blok-render.component';
import { LandingPageLayoutComponent } from './shared/components/landing-page-layout/landing-page-layout.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageLayoutComponent,
    data : { ssr: true },
    children: [
      {
        path: '',
        loadComponent: () => import('./shared/containers/home/home.page').then(m => m.HomePage),
        pathMatch: 'full',
        canActivate: [FeatureGuard, FirstTimeLoginGuard, GenericUserGuard, JWTLoginGuard],
        data: { feature: featureFlags.LOGIN },
      },
    ]
  },
  {
    path: 'home/white-label',
    component: WhiteLabelHomePage,
    canActivate: [JWTLoginGuard],
    data: { title: 'Whitelabel Home' }
  },
  {
    path: 'about',
    component: LandingPageLayoutComponent,
    data : {
      header: true,
      footer: true
    },
    children: [
      {
        path: ':page',
        data: {
          folder: 'about'
        },
        component: StoryBlokRenderComponent
      },
    ]
  },
  {
    path: 'navigation',
    component: LandingPageLayoutComponent,
    data : {
      header: true,
      footer: true,
    },
    children: [
      {
        path: ':page',
        data: {
          folder: 'navigation'
        },
        component: TermsAndConditionsPage
      }
    ]
  },
  {
    path: 'hotels',
    loadChildren: () => import('./hotels/hotels.module').then(m => m.HotelsModule),
    canLoad: [FeatureGuard],
    data: { showAnnouncement: false, feature: featureFlags.HOTELS }
  },
  {
    path: 'cruises',
    loadChildren: () => import('./cruises/cruises.module').then(m => m.CruisesModule),
    canLoad: [FeatureGuard],
    data: { showAnnouncement: false, feature: featureFlags.CRUISES }
  },
  {
    path: 'flights',
    loadChildren: () => import('./flights/flights.module').then(m => m.FlightsModule),
    canLoad: [FeatureGuard],
    data: { showAnnouncement: false, feature: featureFlags.FLIGHTS }
  },
  {
    path: 'cars',
    loadChildren: () => import('./cars/cars.module').then(m => m.CarsModule),
    canLoad: [FeatureGuard],
    data: { showAnnouncement: false, feature: featureFlags.CARS },
  },
  {
    path: 'insurances',
    pathMatch: 'full',
    redirectTo: 'trip_insurances'
  },
  {
    path: 'trip_insurances',
    loadChildren: () => import('./insurances/insurances.module').then(m => m.InsurancesModule),
    canLoad: [FeatureGuard],
    data: { showAnnouncement: false, feature: featureFlags.INSURANCE }
  },
  {
    path: 'invite',
    loadChildren: () => import('./invite/invite.module').then(m => m.InvitePageModule),
    canLoad: [FeatureGuard],
    data: { feature: featureFlags.INVITE },
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canLoad: [FeatureGuard],
    data: { feature: featureFlags.PROFILE_MENU }
  },
  {
    path: 'trips',
    loadChildren: () => import('./trips/trips.module').then(m => m.TripsModule),
    canLoad: [FeatureGuard],
    data: { feature: featureFlags.TRIPS }
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [FirstTimeLoginGuard, GenericUserGuard, JWTLoginGuard, PasswordOldCheckGuard, MFAGuard, MissingInfoGuard],
    children: [
      {
        path: 'search',
        component: SearchPage,
        pathMatch: 'full',
        canActivate: [LoggedUserGuard, GamificationGuard],
        data: { title: 'Search Hotels' }
      },
      {
        path: 'search/:tab',
        component: SearchPage,
        pathMatch: 'full',
        canActivate: [LoggedUserGuard, GamificationGuard],
        data: { showAnnouncement: false, feature: ':tab', title: 'Search', snapshotParams: true },
      },
      {
        path: 'our-services',
        component: OurServicesPage,
        pathMatch: 'full',
        data: { title: 'Our Services' }
      },
      {
        path: 'watchlist',
        component: WatchlistPage,
        pathMatch: 'full',
        canActivate: [FeatureGuard, VerifiedUserGuard, Id90UserGuard],
        data: { feature: featureFlags.WATCHLIST, title: 'Watchlist' }
      },
      {
        path: 'share-itinerary',
        component: ShareItineraryComponent,
        pathMatch: 'full',
        data: { title: 'Share Itinerary' }
      },
      {
        path: 'navigation/contact/new',
        component: ContactUsPage,
        pathMatch: 'full',
        canActivate: [FeatureGuard],
        data: { feature: featureFlags.CONTACT, title: 'Contact Us' }
      },
      {
        path: 'cobus/searches/new',
        redirectTo: '/flights/cobus/searches/new?key=03-01',
        pathMatch: 'full',
        data: { title: 'Flight Cobus Search' }
      },
      {
        path: 'contact-us',
        component: ContactUsPage,
        pathMatch: 'full',
        canActivate: [FeatureGuard],
        data: { feature: featureFlags.CONTACT, title: 'Contact Us' }
      },
      {
        path: 'flight-load-exchange/:airlineCode',
        component: FlexAirlinePage,
        pathMatch: 'full',
        data: { title: 'Flight Load Exchange' }
      },
      {
        path: 'flights/loads-marketplace/request/:requestId',
        component: FlexAirlinePage,
        pathMatch: 'full',
        data: { title: 'Flight Load MarketPlace' }
      },
      {
        path: 'hotel-review',
        component: SearchPage,
        pathMatch: 'full',
        canActivate: [Id90UserGuard, VerifiedUserGuard],
        data: { title: 'Hotel Review' }
      }
    ],
  },
  {
    path: '',
    component: LightLayoutComponent,
    children: [
      {
        path: 'create-account',
        component: RegisterPage,
        pathMatch: 'full',
        canActivate: [FeatureGuard, InvitedUserGuard],
        data: { feature: featureFlags.CREATE_ACCOUNT, title: 'Airline Create Account'}
      },
      {
        path: 'membership/resend-confirmation-email',
        component: ResendEmailConfirmation,
        pathMatch: 'full',
        data: { title: 'Resend Confirmation Email' }
      },
      {
        path: 'membership/new',
        component: RegisterInvitedPage,
        pathMatch: 'full',
        canActivate: [FeatureGuard],
        data: { feature: featureFlags.CREATE_ACCOUNT, title: 'Invite Create Account' }
      },
      {
        path: 'join/:username',
        component: RegisterInvitedPage,
        pathMatch: 'full',
        canActivate: [FeatureGuard],
        data: { feature: featureFlags.CREATE_ACCOUNT, title: 'Invite Create Account' }
      },
      {
        path: 'members/:id/confirm_email',
        component: ConfirmEmailComponent,
        pathMatch: 'full',
        data: { title: 'Confirm Email' }
      },
      {
        path: 'members/register-email',
        component: RegisterEmailAddress,
        pathMatch: 'full',
        data: { title: 'Register Email Address' }
      },
      {
        path: 'password/new',
        component: ForgotPasswordRequestPage,
        pathMatch: 'full',
        canActivate: [FeatureGuard],
        data: { feature: featureFlags.LOGIN, title: 'Airline Forgot Password' }
      },
      {
        path: 'password/new/:type',
        component: ForgotPasswordRequestPage,
        pathMatch: 'full',
        canActivate: [FeatureGuard],
        data: { feature: featureFlags.LOGIN, title: 'Invite Forgot Password' }
      },
      {
        path: 'password/edit',
        component: ForgotPasswordResetPage,
        pathMatch: 'full',
        canActivate: [FeatureGuard],
        data: { feature: featureFlags.LOGIN, title: 'Reset Password' }
      },
      {
        path: 'password/request',
        component: ZendeskFormPage,
        pathMatch: 'full',
        data: { title: 'Password Request Form' }
      },
      {
        path: 'membership/request',
        component: ZendeskFormPage,
        pathMatch: 'full',
        data: { title: 'Membership Request Form' }
      },
      {

        path: 'first_time/new',
        component: FirstTimeLoginPage,
        pathMatch: 'full',
        data: { title: 'First Time Login' }
      },
      {
        path: 'redirect',
        component: RedirectComponent,
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginPage,
        canActivate: [FeatureGuard],
        data: { feature: featureFlags.LOGIN, title: 'Airline Login' }
      },
      {
        path: 'login/:tab',
        component: LoginPage,
        canActivate: [FeatureGuard],
        data: { feature: featureFlags.LOGIN, title: 'Invite Login' }
      },
      {
        path: 'logout',
        component: LogoutComponent,
        pathMatch: 'full',
        canActivate: [FeatureGuard],
        data: { feature: featureFlags.PROFILE_MENU }
      },
      {
        path: 'logout-redirect',
        component: LogoutComponent,
        pathMatch: 'full'
      },
      {
        path: 'event-registration',
        component: EventLoginPage,
        pathMatch: 'full',
        data: { title: 'Event Login' }
      },
      {
        path: 'event-registration/airline',
        component: AirlineSelectPage,
        pathMatch: 'full',
        canActivate: [MarketingUserGuard],
        data: { title: 'Event Airline Select' }
      },
      {
        path: 'event-registration/new',
        component: EventRegistrationPage,
        pathMatch: 'full',
        canActivate: [MarketingUserGuard],
        data: { title: 'Event Registration' }
      },
      {
        path: 'access-account/:airline',
        component: AccountAccessPage,
        pathMatch: 'full',
        data: { title: 'Access Account' }
      },
      {
        path: 'validate-account/:airline/:organizationId/:username',
        component: AccountValidationPage,
        pathMatch: 'full',
        data: { title: 'Validate Account' }
      },
      {
        path: 'unlink_email',
        component: UnlinkEmailRedirectPage,
        pathMatch: 'full',
        data: { title: 'Unlink Email' }
      },
    ]
  },
  {
    path: '**',
    component: NotFoundPage,
    data: { title: 'Not Found' }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}
