import { InviteStatActions } from "../actions";

export interface State {
  error: any | null;
  pending: boolean;
  stats: InviteStats | null;
}

export type InviteStats = {
  total_invited_users: number,
  total_invites_with_bookings: number,
  total_rewards_earned: number,
  total_potential_rewards: number,
  total_pending_invites: number,
  total_registered_invites: number
}

export const initialState: State = {
  error: null,
  pending: false,
  stats: null
};

export function reducer(
  state = initialState,
  action: InviteStatActions.InvitesStatActionsUnion
): State {
  switch (action.type) {
    case InviteStatActions.InviteStatActionTypes.LoadInvitesStat:
      return { ...state, error: null, pending: true };
    case InviteStatActions.InviteStatActionTypes.LoadInvitesStatSuccess:
      return {
        ...state,
        error: null,
        pending: false,
        stats: action.payload.stats
      };
    case InviteStatActions.InviteStatActionTypes.LoadInvitesStatFailure:
      return { ...state, error: action.payload.error, pending: false };
    case InviteStatActions.InviteStatActionTypes.ResetInvitesStat:
      return { ...initialState };
    default:
      return state;
  }
}

export const getStats   = (state: State) => state.stats;
export const getPending = (state: State) => state.pending;
export const getError   = (state: State) => state.error;
