import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import {register} from 'swiper/element/bundle';
import { GeneralHelper } from '../helpers/general.helper';

register();

@Directive({
  selector: '[swiperElement]'
})
export class SwiperDirective implements AfterViewInit {
  @Input() config?: any;

  constructor(
    private element: ElementRef<HTMLElement>,
    private generalHelper: GeneralHelper,
  ) { }

  ngAfterViewInit(): void {
    if (!this.generalHelper.isServer()) {
      Object.assign(this.element.nativeElement, this.config)
      //@ts-ignore - We ignore this because there is no initialize method on the HTMLElement
      this.element.nativeElement.initialize();
    }
  }
}