import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ThirdPartiesFactory } from 'app/auth/strategies/third-parties.factory';
import { Socials } from 'app/profile/models/third-party';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { SamlHelper } from 'app/shared/helpers/saml.helper';
import { Modal } from 'app/shared/models/modal.model';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';
import { PlacesService } from 'app/shared/services/places/places.service';
import { SeoService } from 'app/shared/services/seo/seo.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { ModalActions } from 'app/shared/store/actions';
import * as AirlineActions from 'app/shared/store/actions/airlines.actions';
import * as fromShared from 'app/shared/store/reducers';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../../reducers';
import { LoginPageActions } from '../store/actions';
import * as fromAuth from '../store/reducers';

@Component({
  selector: 'login',
  templateUrl: './login.html'
})

export class LoginPage implements OnInit, OnDestroy {
  airline = this.activatedRoute.snapshot.queryParams['airline'] || '';
  shareCode = this.activatedRoute.snapshot.queryParams['share_code'] || '';
  sessionTimeOut$ = this.store.pipe(select(fromAuth.getSessionTimeOut));
  pending$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  message$ = this.store.pipe(select(fromAuth.getLoginPageMessage));
  error$ = this.store.pipe(select(fromAuth.getLoginPageError));
  userAccount$ = this.store.pipe(select(fromAuth.selectAuthStatusState));
  airlines$: any = this.store.pipe(select(fromShared.getAirlines));
  configFlags$ = this.store.pipe(select(fromShared.getConfigFlag));
  loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
  loading = true;
  airlines = [];
  employeeNumber = '';
  email = '';
  randomPlace: any = {};
  displayLoginWithConnection: boolean;
  private ngUnsubscribe$ = new Subject<void>();
  showPlaces = false;
  platformChosen = Socials.FACEBOOK;
  socials = Socials;
  isWhiteLabelEnabled = false;
  private authTokenFromSocialLogin = '';
  pendingSuccess = false;
  loggedIn = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private placesService: PlacesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<fromRoot.State>,
    private seoService: SeoService,
    private sessionService: SessionService,
    private translate: TranslateService,
    private generalHelper: GeneralHelper,
    private cookieService: SsrCookieService,
    private thirdPartiesFactory: ThirdPartiesFactory,
    private samlHelper: SamlHelper,
    private airlinesService: AirlinesService
  ) { }

  ngOnInit() {
    this.seoService.setTitle('Login');
    this.store.dispatch(new LoginPageActions.ClearError());
    this.saveCodes();

    if (isPlatformBrowser(this.platformId)) {
      this.showPlaces = true;
      this.getPlaces();
      this.loading = false;
    }

    this.airlines$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((airlines) => {
      if (!airlines.length) {
        this.store.dispatch(new AirlineActions.LoadAirlines());
      } else {
        this.airlines = airlines;
        this.isWhiteLabelEnabled = this.airlinesService.isWhiteLabelON(airlines) || false;
        this.airline = this.isWhiteLabelEnabled && this.airlinesService.getWhiteLabelAirlineCode(airlines) ? this.airlinesService.getWhiteLabelAirlineCode(airlines) : '';
      }
    });

    this.userAccount$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((auth) => {
      if (auth) {
        if (!auth.sessionTimeOut && this.shareCode) {
          return this.router.navigate([`/share-itinerary/${this.shareCode}`]);
        }
        if (auth.user.account.airlineCode) {
          this.airline = auth.user.account.airlineCode;
        }
        if (auth.user.account.invitedByUserId) {
          this.email = auth.user.account.employeeNumber.toString();
        } else {
          this.employeeNumber = auth.user.account.employeeNumber.toString();
        }
      }
    });

    combineLatest([this.loggedIn$, this.sessionTimeOut$]).pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([loggedIn, sessionTimeOut]) => {
        if (loggedIn && !sessionTimeOut) {
          this.loggedIn = true;
          this.pendingSuccess = loggedIn;
        }
    });
    this.loginErrorHandler();

    if (this.loggedIn) {
      const redirect = this.activatedRoute.snapshot.queryParams['redirect'] ?
        decodeURIComponent(this.activatedRoute.snapshot.queryParams['redirect']) : '/';
      return this.router.navigateByUrl(redirect);
    }
  }

  loginErrorHandler() {
    this.error$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((e: any) => {
      if (e) {
        if (this.generalHelper.isThirdPartError(e)) {
          this.displayLoginWithConnection = true;
          this.store.dispatch(new LoginPageActions.LoginShowMessage({ message: null }));
        }
      }
    });
  }

  saveCodes() {
    if (this.shareCode) {
      this.sessionService.setShareCode(this.shareCode);
    }
  }

  login(credentials) {
    const shareCode = this.sessionService.getShareCode();
    const redirect = shareCode ? `share-itinerary/${shareCode}` : this.activatedRoute.snapshot.queryParams['redirect'];
    credentials.remember_me = credentials.remember_me ? '1' : '0';
    credentials.redirect = redirect ? decodeURIComponent(redirect) : '/';
    credentials.check_generic = true; // Parameter to block generic users login
    this.store.dispatch(new LoginPageActions.Login({ credentials }));
  }

  async thirdPartiesLogin({ platform, remember }) {
    try {
      if (platform !== Socials.FACEBOOK && platform !== Socials.APPLE ) {
        this.platformChosen = Socials.GOOGLE;
        this.authTokenFromSocialLogin = platform;
        return this.store.dispatch(new LoginPageActions.LoginThirdParty({ platform: Socials.GOOGLE, remember, authToken: platform }));
      }
      this.platformChosen = platform;
      const platformProvider = this.thirdPartiesFactory.getProvider(platform);
      const service = this.thirdPartiesFactory.getService(platform);
      const user = await service.signIn(platformProvider);
      this.authTokenFromSocialLogin = this.thirdPartiesFactory.getToken(user, platform);
      this.store.dispatch(new LoginPageActions.LoginThirdParty({ platform, remember, authToken: this.authTokenFromSocialLogin }));
    } catch (e) {
      const data: Modal = {
        title: 'errors.error',
        body: this.translate.instant('login_page.third_parties.error', { platform }),
        modalOptions: { size: 'md' }
      };
      this.store.dispatch(new ModalActions.Show({ data }));
    }
  }

  async loginAndConnectAccount({ credentials, platform, isSaml, airline }) {
    try {
      const thirdPartyCookie = {
        platform,
        token: this.authTokenFromSocialLogin,
      };
      this.cookieService.set('thirdPartyConnect', JSON.stringify(thirdPartyCookie));
      this.cookieService.set('avoidDisplayFacebookModal', 'true');
      if (isSaml) {
        this.samlLogin(airline);
      } else {
        this.store.dispatch(new LoginPageActions.Login({ credentials }));
      }
      return;
    } catch (e) {
      const data: Modal = {
        title: 'errors.error',
        body: this.translate.instant('login_page.third_parties.error', { platform }),
        modalOptions: { size: 'md' }
      };
      this.store.dispatch(new ModalActions.Show({ data }));
    }
  }

  samlLogin(airline) {
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((params) => {
      const redirect = params['redirect'];
      if (redirect) {
        this.cookieService.set('redirectURL', redirect);
      }
      this.samlHelper.login(airline.code);
    });
  }

  closeAlert() {
    const message = null;
    this.store.dispatch(new LoginPageActions.LoginShowMessage({ message }));
  }

  getPlaces() {
    this.randomPlace = this.placesService.getRandomPlace();
  }

  ngOnDestroy() {
    this.store.dispatch(new LoginPageActions.ClearError());
    this.store.dispatch(new LoginPageActions.ClearMessage());
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
