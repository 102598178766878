<ngb-alert *ngIf="success" type="success" (closed)="closeAlert()">{{ success | translate }}</ngb-alert>
<ngb-alert *ngIf="error" type="danger" (closed)="closeAlert()">{{ error }}</ngb-alert>
<div class="d-sm-none back-button">
  <button type="button" class="btn btn-link" (click)="showTabs.emit()"><fa-icon [icon]="icons.faChevronLeft" aria-hidden="true"></fa-icon> {{'back' | translate}} </button>
</div>
<div class="profile-title primary-content">
  {{ "profile.notification_center.title" | translate }}
</div>
<div class="card shadow-none mb-4">
  <div class="card-body">
    <div class="container">
      <div class="row mb-2">
        <div class="col">
          <div class="main-title">{{ "profile.notification_center.email_subscriptions" | translate }}</div>
          <div class="flex-grow-1 bd-highlight section-title-divider"></div>
        </div>
      </div>
      <div class="mt-3">
        <loading-indicator [fullScreen]="false" *ngIf="(memberInterestsPending$ | async)"></loading-indicator>
        <form [formGroup]="subscriptionForm" *ngIf="!(memberInterestsPending$ | async)">
          <div formArrayName="interests">
            <div *ngFor="let interest of interests; let i = index" [formGroupName]="i">
              <div class="row mt-3">
                <div class="col-10">
                  <label class="secondary-content"> {{ getTranslateKey(interest.value.listId, 'title') | translate }} </label>
                  <div class="tertiary-content"> {{ getTranslateKey(interest.value.listId, 'description') | translate }} </div>
                </div>
                <div class="col-2 align-content-center">
                  <toggle
                    class="toggle-selector"
                    [id]="interest.value.listId"
                    [value]="interest.value.isSubscribed"
                    (change)="toggleSubscription(interest.value.listName)">
                  </toggle>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
