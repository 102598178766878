import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NeedARoomService } from 'app/shared/services/need-a-room/need-a-room.service';
import { isEmpty } from 'lodash';
import { MemberService } from 'app/shared/services/member/member.service';
import { AbandonedHotel } from 'app/shared/models/abandoned-hotel';
import { UserCredits } from 'app/profile/models/user-credits.interface';
import { take, takeUntil } from 'rxjs/operators';
import { User } from 'app/auth/models/user';
import { combineLatest, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromProfile from 'app/profile/store/reducers';
import { featureFlags } from 'app/shared/models/featureFlags';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import * as fromShared from 'app/shared/store/reducers';
import { StoryblokService } from 'app/shared/services/storyblok/storyblok.service';
import { ISbStoriesParams } from 'storyblok-js-client/dist/types/interfaces';
import { environment } from 'environments/environment';
import { loadRate, clear } from 'app/shared/store/actions/rate.actions';

@Component({
  selector: 'just-for-you',
  templateUrl: './just-for-you.html'
})

export class JustForYouComponent implements OnInit {
  @Input() userStatistics: any = {};
  @Input() userCredits: UserCredits = null;
  @Input() abandonedHotel: AbandonedHotel;

  showSection: boolean = false;
  needARoomData: any;
  showInviteAFriendCard: boolean = false;
  promoData: any;
  remotecard: Array<any> = null;
  showStaticCards = false;
  user: any;
  user$ = this.store.select(fromProfile.getUserProfile);
  featureFlags = featureFlags;
  private ngUnsubscribe = new Subject();
  appConfig$ = this.store.pipe(select(fromShared.getConfigFlag));
  rate$ = this.store.pipe(select(fromShared.getRate))
  storyblokParams : ISbStoriesParams;
  rate: any;


  constructor(
    private router: Router,
    private needARoomService: NeedARoomService,
    private memberService: MemberService,
    private storyblokService: StoryblokService,
    private store: Store<any>,
    private featureFlagsService: FeatureFlagsService,
  ) { }

  async ngOnInit() {
    this.storyblokParams = {
      version: environment.storyblok.version as 'draft' | 'published',
      starts_with: 'just-for-you/web'
    }
    
    this.showStaticCards = this.featureFlagsService.isFeatureEnabled(featureFlags.STATIC_CARD);
    this.initInviteFriend();
    this.initNeedARoom();
    this.initPromo();
    this.getUserData();
    await this.loadStoryBlokConfig();
    this.shouldShowSection();
  }

  private async loadStoryBlokConfig() {
    const remote = await this.storyblokService.getStories(this.storyblokParams);
    if (remote) {
      return this.remotecard = remote.data.stories;
    }
  }

  navigateTo(params) {
    return this.router.navigate([params.path, params.data]);
  }

  initInviteFriend() {
    const userStats = this.userStatistics;
    if (!isEmpty(userStats) && userStats.allowed_to_invite && userStats.total_invited_users === 0) {
      this.showInviteAFriendCard = true;
    } else {
      this.showInviteAFriendCard = false;
    }
  }

  initNeedARoom() {
    this.needARoomService.getCardContent().pipe(take(1)).subscribe((response) => {
      if (Object.keys(response).length > 0) {
        this.needARoomData = response;
      }
    });
  }

  initPromo() {
    this.memberService.getMemberPromotions().pipe(take(1)).subscribe((response) => {
      if (response) {
        this.promoData = response[0];
      }
    });
  }


  filterCardsByAirlineCode(card: any) {
    const companiesEnabled = card.companiesEnabled;
    const companiesDisabled = card.companiesDisabled;
    let result = false;
    const companyIncluded = companiesEnabled ? companiesEnabled.some((company) => (this?.user?.airlineCode && company?.toUpperCase() === this?.user?.airlineCode?.toUpperCase())) : false;
    if (!companiesEnabled || companyIncluded || companiesEnabled.length === 0) {
      result = true;
    }

    if (companiesDisabled && companiesDisabled.some((company) => (this?.user?.airlineCode && company?.toUpperCase() === this?.user?.airlineCode?.toUpperCase()))) {
      result = false;
    }
    return result;
  }

  getUserData() {
    combineLatest([this.rate$, this.user$]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([rate, user]) => {
      this.user = user;
      if (!rate) {
       return this.store.dispatch(loadRate({ currencyCode: user?.currency || 'USD' }));
      };
      this.rate = {
        conversionRate: rate,
        userCurrency: user?.currency || 'USD'
      };
    });
  }

  shouldShowSection() {
    const hasValidAirlineCard = this.hasValidAirlineCard();
    if (
      ( ( this.featureFlagsService.isFeatureEnabled(featureFlags.INVITE)
        && this.showInviteAFriendCard ) ||
        this.needARoomData != null ||
        this.promoData != null ||
        this.abandonedHotel != null ||
        ( this.userCredits && this.userCredits.creditUSD ) ||
        hasValidAirlineCard ) &&
        this.featureFlagsService.isFeatureEnabled(featureFlags.JUST_FOR_YOU)
    ) {
      this.showSection = true;
      return;
    }
    this.showSection = false;
  }

  hasValidAirlineCard() {
      return this.remotecard?.some(
          (card) => this.filterCardsByAirlineCode(card)
      );
  }
  formattedRichText(data: any, rate: any) {
   return this.storyblokService.parseTextRich(data, rate);
  }
}

