import { CobusAdminActions } from 'app/flights/store/actions';
import { CobusAdminReasonForTravel, CobusAdminEmployeeValidation, CobusAdminAuthorizationPage, CobusCreateAuthorizationRequest, CobusSearchFormConfigRequest } from 'app/flights/models/cobus-admin.interface';

export interface CobusAdminState {
  error: any;
  pending: {
    authorizationPage: boolean,
    reasonsForTravel: boolean,
    employeeValidation: boolean,
    createAuthorization: boolean,
    cancelAuthFlow: boolean,
    getCobusSearchForm: boolean,
    getCobusSearchFormConfig: boolean
  };
  authorizationPage: CobusAdminAuthorizationPage;
  reasonsForTravel: CobusAdminReasonForTravel;
  employeeValidation: CobusAdminEmployeeValidation;
  createAuthorization: CobusCreateAuthorizationRequest;
  cancelAuthFlow: any;
  getCobusSearchFormConfig: any;
}

export const initialState: CobusAdminState = {
  error: null,
  pending: {
    authorizationPage: false,
    reasonsForTravel: false,
    employeeValidation: false,
    createAuthorization: false,
    cancelAuthFlow: false,
    getCobusSearchForm: false,
    getCobusSearchFormConfig: false
  },
  authorizationPage: null,
  reasonsForTravel: null,
  employeeValidation: null,
  createAuthorization: null,
  cancelAuthFlow: null,
  getCobusSearchFormConfig: null,
};

export function reducer(
  state = initialState,
  action:
    | CobusAdminActions.CobusAdminActionsUnion
): any {
  switch (action.type) {
    case CobusAdminActions.CobusAdminActionTypes.LoadAuthorizationPage:
      return {
        ...state,
        pending: {
          ...state.pending,
          authorizationPage: true,
        },
        authorizationPage: null
      };
    case CobusAdminActions.CobusAdminActionTypes.LoadAuthorizationNoValidatedEmployeePage:
      return {
        ...state,
        pending: {
          ...state.pending,
          authorizationPage: true,
        },
        authorizationPage: null
      };
    case CobusAdminActions.CobusAdminActionTypes.LoadAuthorizationPageSuccess:
      return {
        ...state,
        pending: {
          ...state.pending,
          authorizationPage: false,
        },
        authorizationPage: action.payload.data,
        error: initialState.error
      };
    case CobusAdminActions.CobusAdminActionTypes.LoadAuthorizationPageFailure:
      return {
        ...state,
        pending: {
          ...state.pending,
          authorizationPage: false,
        },
        error: action.payload.error
      };
    case CobusAdminActions.CobusAdminActionTypes.SearchReasonsForTravel:
      return {
        ...state,
        pending: {
          ...state.pending,
          reasonsForTravel: true,
        },
      };
    case CobusAdminActions.CobusAdminActionTypes.SearchReasonsForTravelSuccess:
      return {
        ...state,
        pending: {
          ...state.pending,
          reasonsForTravel: false,
        },
        reasonsForTravel: action.payload.data,
        error: initialState.error
      };
    case CobusAdminActions.CobusAdminActionTypes.SearchReasonsForTravelFailure:
      return {
        ...state,
        pending: {
          ...state.pending,
          reasonsForTravel: false,
        },
        error: action.payload.error
      };
    case CobusAdminActions.CobusAdminActionTypes.ValidateEmployee:
      return {
        ...state,
        pending: {
          ...state.pending,
          employeeValidation: true,
        },
      };
    case CobusAdminActions.CobusAdminActionTypes.ValidateEmployeeSuccess:
      return {
        ...state,
        pending: {
          ...state.pending,
          employeeValidation: false,
        },
        employeeValidation: action.payload.data,
        error: initialState.error
      };
    case CobusAdminActions.CobusAdminActionTypes.ValidateEmployeeFailure:
      return {
        ...state,
        pending: {
          ...state.pending,
          employeeValidation: false,
        },
        error: action.payload.error
      };
    case CobusAdminActions.CobusAdminActionTypes.CreateAuthorization:
      return {
        ...state,
        pending: {
          ...state.pending,
          createAuthorization: true,
        },
      };
    case CobusAdminActions.CobusAdminActionTypes.CreateAuthorizationSuccess:
      return {
        ...state,
        pending: {
          ...state.pending,
          createAuthorization: false,
        },
        createAuthorization: action.payload.data,
        error: initialState.error
      };
    case CobusAdminActions.CobusAdminActionTypes.CreateAuthorizationFailure:
      return {
        ...state,
        pending: {
          ...state.pending,
          createAuthorization: false,
        },
        error: action.payload.error
      };
    case CobusAdminActions.CobusAdminActionTypes.CancelAuthFlow:
      return {
        ...state,
        pending: {
          ...state.pending,
          cancelAuthFlow: true,
        },
      };
    case CobusAdminActions.CobusAdminActionTypes.CancelAuthFlowSuccess:
      return {
        ...state,
        pending: {
          ...state.pending,
          cancelAuthFlow: false,
        },
        cancelAuthFlow: true,
        employeeValidation: initialState.employeeValidation,
        authorizationPage: initialState.authorizationPage,
        createAuthorization: initialState.createAuthorization,
        getCobusSearchFormConfig: initialState.getCobusSearchFormConfig,
        error: initialState.error
      };
    case CobusAdminActions.CobusAdminActionTypes.CancelAuthFlowFailure:
      return {
        ...state,
        pending: {
          ...state.pending,
          cancelAuthFlow: false,
        },
        error: action.payload.error
      };
    case CobusAdminActions.CobusAdminActionTypes.GetCobusSearchFormConfig:
      return {
        ...state,
        pending: {
          ...state.pending,
          getCobusSearchFormConfig: true,
        },
      };
    case CobusAdminActions.CobusAdminActionTypes.GetCobusSearchFormConfigSuccess:
      return {
        ...state,
        pending: {
          ...state.pending,
          getCobusSearchFormConfig: false,
        },
        getCobusSearchFormConfig: action.payload.data,
        error: initialState.error
      };
    case CobusAdminActions.CobusAdminActionTypes.GetCobusSearchFormConfigFailure:
      return {
        ...state,
        pending: {
          ...state.pending,
          getCobusSearchFormConfig: false,
        },
        error: action.payload.error
      };
    case CobusAdminActions.CobusAdminActionTypes.ClearCobusAdminErrors:
      return {
        ...state,
        error: null
      };

    default: {
      return state;
    }
  }
}

export const getCobusAdminAuthorizationPage = (state: CobusAdminState) => state.authorizationPage;
export const getReasonsForTravel = (state: CobusAdminState) => state.reasonsForTravel;
export const getReasonsForTravelPending = (state: CobusAdminState) => state.pending.reasonsForTravel;
