import { Component, HostListener, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { faFacebookF, faInstagram, faLinkedinIn, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import * as fromAuth from 'app/auth/store/reducers';
import { StoryBlokNameDirective } from 'app/shared/directives/storyblok-name.directive';
import { StoryblokService } from 'app/shared/services/storyblok/storyblok.service';
import moment from 'moment';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISbStoryParams } from 'storyblok-js-client';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-load/dynamic-script-load.service';
import * as fromRoot from '../../../../reducers';
import { GeneralHelper } from 'app/shared/helpers/general.helper';

@Component({
  selector: 'langing-page-layout-component',
  templateUrl: './landing-page-layout.component.html',
  styleUrls: ['./landing-page-layout.component.scss']
})
export class LandingPageLayoutComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('footerContainer', { read: ViewContainerRef }) footerContainer: ViewContainerRef;
  @ViewChildren(NgbDropdown) dropdown: QueryList<NgbDropdown>;
  @ViewChild('navContainerLink', { read: ViewContainerRef }) navContainerLink: ViewContainerRef;
  @ViewChild('navContainerButton', { read: ViewContainerRef }) navContainerButton: ViewContainerRef;

  @ViewChildren(StoryBlokNameDirective) templates: QueryList<StoryBlokNameDirective>;
  slugName: string = 'id90-config';
  story: any;
  pending = true;
  icons = { faFacebookF, faLinkedinIn, faInstagram, faYoutube, faTiktok, faBars, faXmark };
  currentYear = moment().year();
  isMenuCollapsed = true;
  scrollPosition = 0;
  showFooter: boolean = true;
  showHeader: boolean = true;
  renderContent = true; // for SSR purposes
  readonly FOLDER = 'header-footer';
  loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));


  private ngUnsubscribe = new Subject<void>();


  constructor(
  private storyblokService: StoryblokService,
  private router: Router,
  private activatedRoute: ActivatedRoute,
  private generalHelper: GeneralHelper,
  private store: Store<fromRoot.State>,
  private dynamicScriptLoader: DynamicScriptLoaderService
) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!this.generalHelper.isServer()) {
      if (changes.slugName.currentValue && !this.pending) {
        const params: ISbStoryParams = {
          version: environment.storyblok.version as "draft" | "published"
        };

        let data = await this.storyblokService.getStory(`${this.FOLDER}/${this.slugName}`, params);
        this.story = data.story;
        this.pending = true;
        this.footerContainer.clear();
        this.navContainerButton.clear();
        this.navContainerLink.clear();
        this.buildComponent(this.story.content);
        this.pending = false;
      }
    }
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const navbarDesktop = <HTMLElement>document.getElementById('navbar');
    if (this.isMobile() || !navbarDesktop) {
      return;
    }

    if (this.scrollPosition > 100) {
      navbarDesktop.classList.add('translucent-navbar');
      navbarDesktop.classList.remove('custom-nav');

    } else {
      navbarDesktop.classList.remove('translucent-navbar');
      navbarDesktop.classList.add('custom-nav');

    }
  }

  async ngOnInit(): Promise<void> {
    // Uncomment this when the TrustArc implementation is finished
    // if (!this.generalHelper.isServer()) {
    //   this.loadScript();
    // }
    const params: ISbStoryParams = {
      version: environment.storyblok.version as "draft" | "published"
    };
    combineLatest([this.loggedIn$, this.activatedRoute.data])
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(([loggedIn, data]) => {
      if (!data?.ssr && this.generalHelper.isServer()) {
        this.renderContent = false;
      }
      if (loggedIn && Object.keys(data).length === 0) {
        this.showHeader = false;
        this.showFooter = false;
      } else {
        this.showHeader = data?.header ?? true;
        this.showFooter = data?.footer ?? true;
      }
    });

    this.loadRemoteContent();
  }

  async loadRemoteContent() {
    const params: ISbStoryParams = {
      version: environment.storyblok.version as "draft" | "published"
    };
    let data = await this.storyblokService.getStory(`${this.FOLDER}/${this.slugName}`, params);
    this.pending = false;
    this.story = data.story;
    this.buildComponent(data.story.content);
    this.listenForChange();
  }

  async loadScript() {
    try {
      await this.dynamicScriptLoader.load('trustArc');
    } catch (error) {
      console.log(error, 'error')
    }
  }

  listenForChange() {
    this.storyblokService.listenForChanges$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (!event || !this.story) { return; }
      if (this.story && event?.story?.content?._uid === this.story?.content._uid && event) {
        this.story = event.story;
        this.pending = true;
        this.footerContainer.clear();
        this.navContainerButton.clear();
        this.navContainerLink.clear();
        this.buildComponent(this.story.content);
        this.pending = false;
      }
    });
  }


  private findTemplate(key): TemplateRef<any> {
    const templateRef = this.templates.find(component => component.name === key);
    return templateRef?.template || null;


  }

  private renderComponent(storyBlokComponent: TemplateRef<any>, data: any, container: any) {
    if (!container) { return }
    const view = storyBlokComponent.createEmbeddedView({ data });
    container.insert(view);

  }

  private buildComponent(content: any): void {
    for (const key in content) {
      if (Object.prototype.hasOwnProperty.call(content, key) && Array.isArray(content[key])) {
        for (let index = 0; index < content[key].length; index++) {
          const template = this.findTemplate(content[key][index].component);
          if (template) {
            const container = this.getContainer(key, content[key][index].component);
            this.renderComponent(template, content[key][index], container);

          }
        }
      }
    }
  }
  generateButtonStyle(baseStyle: string, color: string) {
    return `${baseStyle}-${color}`;
  }
  generateBootstrapColumn(column) {
    if (!column) return 'col-auto';
    const sizeMap = {
      "xsmall": "xs",
      "small": "sm",
      "medium": "md",
      "large": "lg",
      "xlarge": "xl"
    };
    let classes = [];
    for (const [key, value] of Object.entries(column)) {
      if (sizeMap[key]) {
        classes.push(`col-${sizeMap[key]}-${value ? value : 1}`);
      }
    }

    return classes.join(' ');
  }

  navigate(item) {
    if (typeof item === 'string') {
      return this.router.navigate([item]);
    }

    if (item.url.url.includes('mailto:') || item.url.url.includes('tel:') || item?.external) {
      window.location.href = item.url.url;
      return;
    }
    return this.router.navigate([item.url.url]);
  }

  onHover(drop) {
    this.dropdown.forEach((elem) => elem.close());
    drop.open();
  }

  leaveHover(drop) {
    drop.close();
  }

  clearDrops() {
    this.dropdown.forEach((elem) => elem.close());
  }

  generateCopyRight() {
    return `© ${this.currentYear}, All Rights Reserved - ID90 Travel, Inc.`;
  }

  ngOnDestroy() {
    this.ngUnsubscribe?.next();
    this.ngUnsubscribe?.complete();
    this.navContainerButton?.clear();
    this.navContainerLink?.clear();
    this.footerContainer?.clear();
  }

  getContainer(key: string, typeComponent: string): ViewContainerRef {
    if (key === 'footer') {
      return this.footerContainer;
    }

    if (key === 'header' && typeComponent === 'button') {
      return this.navContainerButton;
    }

    return this.navContainerLink;
  }

  get navbarClass() {
    if (this.isMobile()) {
      return 'collapse navbar-collapse p-0';
    }
    return 'collapse navbar-collapse nav-container p-0';
  }

  isMobile() {
    return  window.innerWidth <= 990; // if i use the General Helper is broken in iframe of storyblok
  }

  headerNavigate(data) {
    if (data?.external) {
      window.location.href = data.url.url;
      return;
    }
   return this.router.navigate(['/', { outlets: { primary: data.url.url }}]);

  }

  getImageUrlResized(url: string, resizeWidth: number = 600) {
    return `${url}/m/${resizeWidth}x0`;
  }
}
