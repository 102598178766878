import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { DatepickerRangeComponent } from '../components/datepicker/datepicker-range.component';

@Component({
  selector: 'app-inline-datepicker',
  templateUrl: './inline-datepicker.component.html',
  styleUrls: ['./inline-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InlineDatepickerComponent implements OnInit {
  @Input({ required: true }) currentDate: string;
  @Input({ required: true }) maxDate: string;
  @Input({ required: true }) minDate: string;
  @Output() selectDate = new EventEmitter<string>();
  @ViewChild(DatepickerRangeComponent) datepickerComponent: DatepickerRangeComponent;
  public maxDateMoment: moment.Moment;
  public minDateMoment: moment.Moment;
  public datepickerOpen = false;
  public icons = { faCalendar, faCaretDown, faCaretUp };
  public readonly DATE_FORMAT = 'YYYY-MM-DD';
  private readonly MOBILE_BREAKPOINT = 576;

  ngOnInit(): void {
    this.maxDateMoment = moment(this.maxDate);
    this.minDateMoment = moment(this.minDate);
  }

  public openDatePicker() {
    if (this.datepickerOpen) return;
    if (this.isMobileScreenView()) {
      this.datepickerComponent.openFullscreenModal();
    }
    else {
      this.datepickerComponent.toggleDatePicker();
    }
    this.datepickerOpen = true;
  }

  public onDateSelected(date: { startDate: string }) {
    this.datepickerOpen = false;
    this.selectDate.emit(date.startDate);
  }

  public onDatepickerClosed () {
    this.datepickerOpen = false;
  }

  private isMobileScreenView(): boolean {
    return window.innerWidth < this.MOBILE_BREAKPOINT;
  }
}
