import { HOTEL_RESULTS_PAGE_SIZE } from "app/hotels/containers/results/hotel-results.const";

export const environment = {
  androidStore:
    'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.id90travel.id90app&ddl=1&pcampaignid=web_ddl_1',
  env: 'beta',
  apiUrl: 'https://beta.id90travel.com',
  avatarsBucket: 'id90-avatars-beta',
  geocodingVendor: 'google',
  googleContactsApikey: 'AIzaSyCcH69myiWDhlu9ex3MM_MTU-n2HCtQvPU',
  googleContactsClientID: '334125093575-g7o6if0kmf3lonejaqe321a7kajrv4cl.apps.googleusercontent.com',
  googlePlacesApiKey: 'AIzaSyCaqfeEqPRDex9tu-B1pwJ_dFa_WYlUAnc',
  insurancePolicyUrl: 'https://policy.travelexinsurance.com/501ZPD-0924',
  insurancePolicyPlanNumber: '501ZPD-0924',
  inviteLink: 'https://qf6w.test-app.link/Dr8XOFtjzK',
  iosStore: 'https://itunes.apple.com/us/app/id90travel/id1032894432?ls=1&mt=8',
  locationiqApiKey: '0e2245d19cf447',
  mapkitEnabled: false,
  leafletEnabled: true,
  // mapkit expiration Jan 10th 2024 - domain restricted token
  mapkitJWT:
    'eyJraWQiOiJNWVpGNE44UDZCIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJWOUc2UDNYMlpHIiwiaWF0IjoxNzM1OTE0MzI4LCJvcmlnaW4iOiIqLmlkOTB0cmF2ZWwuY29tIiwiZXhwIjoxNzY4NjE4ODAwfQ.GWLlhXsI2ZQU_YQbkSqQm80wSH8LgC_Onv6LdPpS-doDDfSNSUn78BYTFjF2Vfb8kk8H1OUbu4FVvtSgIdz4-A',
  paypalEnv: 'sandbox',
  paypalClientId:
    'ARUfjvpVNZVc9qwNADkrXNcsCk73ezSLJ1egvemMN1Qs3tJL72QyGvL8zz8IHrd799GVwscQb50yDkKS',
  platform: 'Web 2.0',
  production: false,
  recaptchaPublicKey: '6Lcw6aYaAAAAAE4cln_s-bW1kwOzNHmIojCnpyrG',
  s3Bucket: 'https://id90-beta1-assets.s3.amazonaws.com',
  secureCookies: true,
  sentryDsn: 'https://550f3c00d21248b196d12a8d6dc1369a@o93272.ingest.us.sentry.io/258251',
  siteUrl: 'https://beta.id90travel.com',
  tripsPageSize: 6,
  useCookies: true,
  receiptsS3Bucket: 'https://flights-receipts-beta.s3.amazonaws.com/',
  firebaseConfig: {
    apiKey: 'AIzaSyBeaWPJXxwfxzSmaMEfrTUJrnJy96OMw_c',
    authDomain: 'id90travel-mobile-develop.firebaseapp.com',
    databaseURL: 'https://id90travel-mobile-develop.firebaseio.com',
    projectId: 'id90travel-mobile-develop',
    storageBucket: 'id90travel-mobile-develop.appspot.com',
    messagingSenderId: '334125093575',
    appId: '1:334125093575:web:5c779eb46d4533cb27e4e2',
    measurementId: 'G-K9QRFSWDWQ'
  },
  oauth: {
    facebookApp: '294210299441487',
    googleApp: '334125093575-ptqavhdt9pnc87qtldg1iptn6via3tqt.apps.googleusercontent.com'
  },
  hotelResultsCacheMaxTime: 6,
  hotelResultsLayoutSwitchEnabled: true,
  hotelResultsMapClusteringMin: HOTEL_RESULTS_PAGE_SIZE ?? 30,
  hotelResultsMapClusteringMinMobile: 50,
  enableThirdPartiesModal: false,
  logInHelpInfoAirlines: 'https://id90travel.zendesk.com/hc/en-us/categories/28540175302299-Your-Account',
  logInHelpInfoFriends: 'https://id90travel.zendesk.com/hc/en-us/categories/28540175302299-Your-Account',
  flightCheckoutSurveyUrl: 'https://www.surveymonkey.com/r/KTVVXM9',
  smartlookEnabled: true,
  smartlookKey: '348c2eb0fb7decb1c5bf6830c6b3e571f3c96fb3',
  affirmLibraryURL: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
  affirmKey: 'I99D6FF314VOSSBZ',
  storyblok: {
    version: 'draft',
    region: 'us',
    apiKey: '65L9RHSBt741vJjtwU33oAtt'
  },
  hubspot: {
    region: 'na1',
    portalId: '870903',
  },
  carsProvider: {
    meili: {
      connect: {
        url: 'https://uat-connect-ux.meili.travel/index.js',
        ptid: '141.10'
      },
      search: {
        url: 'https://uat-search-ux.meili.travel/index.js',
        ptid: '141.10'
      },
      direct: {
        url: 'https://uat-direct-ux.meili.travel/index.js',
        ptid: '141.9'
      },
      booking: {
        url: 'https://uat-booking-ux.meili.travel/index.js',
        ptid: '141.27'
      }
    }
  },
  cruiseExternalUrls: {
    cruises: {
      url: 'https://cruise.id90travel.com/cs/forms/CruiseResultsPage.aspx',
      skin: '583'
    },
    voyages: {
      url: 'https://cs.cruisebase.com/cs/forms/CruiseResultsPage.aspx',
      skin: '636'
    },
    searchCriteria: 'https://cruise.id90travel.com/csa/GetSCriteria'
  },
  launchDarkly: {
    clientId: '66f19b9cb3bac21011af4769',
    environment: 'test'
  }
};
