import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { faArrowRight, faCalendarAlt, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { NgbDate, NgbDateAdapter, NgbDatepickerConfig, NgbInputDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { DemandCalendar } from 'app/hotels/models/demand-calendar';
import { DemandCalendarActions } from 'app/hotels/store/actions';
import { datesConfig } from 'configs/dates';
import { forEach } from 'lodash';
import * as moment from 'moment';
import { Subject, take, takeUntil } from 'rxjs';

// translations between different date types
class DateAdapter extends NgbDateAdapter<Date> {

  fromModel(date: Date): NgbDate {
    return date ? NgbDate.from({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    }) : null;
  }

  toModel(date: NgbDate): Date {
    return date ? new Date(date.year, date.month - 1, date.day, 0, 0, 0) : null;
  }
}

@Component({
  selector: 'datepicker-range',
  styleUrls: ['./datepicker-range.scss'],
  templateUrl: 'datepicker-range.html',
  encapsulation: ViewEncapsulation.None
})

export class DatepickerRangeComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('datepicker', { static: true }) datepicker: NgbInputDatepicker;
  @ViewChild('checkinContainer', { static: true }) checkinContainer;
  @ViewChild('fullscreenModalTemplate', { static: false }) fullscreenModalTemplate: TemplateRef<Element>;

  @Input() displayFormat = 'MMM DD, YYYY'; // dates display format
  @Input() emitFormat = 'MMM DD, YYYY'; // dates output format
  @Input() startDate: string = moment().format(this.displayFormat); // initial value
  @Input() endDate: string = moment().add(1, 'days').format(this.displayFormat); // initial value
  @Input() minDate = moment();
  @Input() maxDate = moment().add(364, 'days');
  @Input() inputName = '';
  @Input() singleDate = false;
  @Input() singleInput = true;
  @Input() initBlank = false;
  @Input() startLabel = '';
  @Input() endLabel = '';
  @Input() placeholderStart = moment(this.startDate, datesConfig.momentFormats).format(this.displayFormat);
  @Input() placeholderEnd = moment(this.endDate, datesConfig.momentFormats).format(this.displayFormat);
  @Input() narrowWidth = false;
  @Input() showCalendarIcon = false;
  @Input() showCalendarIconRight = false;
  @Input() isError = false;
  @Input() navigation = 'arrows';
  @Input() outsideDays: NgbDatepickerConfig['outsideDays'] = 'collapsed';
  @Input() placement = 'bottom-left';
  @Input() enableOneDayOption: true | false = false;
  @Input() showModalForMobile = false;
  @Input() openDatePicker = false;
  @Input() showSeparator = true;
  @Input() readOnly = false;
  @Input() enableSelectSameDate = false;
  @Input() enableClearAction = false;
  @Input() removePadding = false;
  @Input() displayLabel = true;
  @Input() disabledModifyDate = false;
  @Input()
  set displayMonths(val: number) {
    this._displayMonths = val;
  }
  @Input() showDemandCalendar = false;
  @Input() demandCalendar: DemandCalendar[] = [];
  @Input() showApplyButton = false;
  @Input() autoClose: boolean | 'outside' | 'inside' = true;
  @Input() datepickerClass = '';

  // when dates are selected we'll fire this event
  @Output() datesSelected = new EventEmitter();
  @Output() datepickerClosed = new EventEmitter();

  public icons = { faCalendarAlt, faArrowRight, faCircleInfo };
  _displayMonths:number = 2;
  hoveredDate: NgbDate;
  _fromDate: NgbDate; // currently selected date (object)
  _toDate: NgbDate; // currently selected date (object)
  mode: 'from' | 'to' = 'from'; // which date is the user selecting?
  isFirstSelect = false; // this tells us if the user already selected anythong
  _closed = true;
  showFullScreenModal = false;
  fullscreenModalRef;

  allowAutoOpenModal = false;

  dateAdapter = new DateAdapter;

  observer: MutationObserver;
  private ngUnsubscribe = new Subject<void>();
  private readonly TABLET_BREAKPOINT = 768;

  constructor(
    private actionsSubject: ActionsSubject,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // for sm and below we display only 1 month, for the rest of the viewports we display 2 months
      // for sm and below we allow calendar modal to auto open
      if (!window.matchMedia(`(min-width: ${this.TABLET_BREAKPOINT}px)`).matches) {
        this.allowAutoOpenModal = true;
      }
    }
  }

  get displayMonths():number {
    let result = this._displayMonths;
    if (isPlatformBrowser(this.platformId) && !window.matchMedia(`(min-width: ${this.TABLET_BREAKPOINT}px)`).matches) {
      result = 1;
    }
    return result;
  }

  ngOnInit() {
    if (this.initBlank) {
      this.startDate = '';
      this.endDate = '';
      this.placeholderStart = '';
      this.placeholderEnd = '';
    }

    this._fromDate = this.parseDate(this.startDate);
    this._toDate = this.parseDate(this.endDate);
    if (this._fromDate && this._fromDate.equals(this._toDate) && !this.enableSelectSameDate) {
      this._toDate = null;
    }

    this.listenToDemandCalendar();
  }

  ngOnChanges(changes) {
    if (changes.startDate) {
      this._fromDate = this.parseDate(this.startDate);
      this.datepicker.startDate = this._fromDate;
    }
    if (changes.endDate) {
      this._toDate = this.parseDate(this.endDate);
    }
    if (this.allowAutoOpenModal && changes.openDatePicker && changes.openDatePicker.currentValue) {
      this.openFullscreenModal();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    if (this.observer) {
      this.observer.disconnect();
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.observer = new MutationObserver((mutations) => {
        forEach(mutations, mutation => this.onDatepickerMutation(mutation));
      });
      this.observer.disconnect();
      this.observer.observe(this.checkinContainer.nativeElement, {
        attributes: false,
        childList: true,
        characterData: false
      });
    }
  }

  onDatepickerMutation(mutation) {
    forEach(mutation.removedNodes, (x) => {
      if (x.tagName.toUpperCase() === 'NGB-DATEPICKER') {
        this.onPopupClose();
      }
    });
  }

  onPopupClose() {
    this._closed = true;
    this.emitDates();
  }

  // returns the date that should have the "from" styles (fromDate or hoveredDate)
  get popupFrom() {
    let result = this.fromDate;
    if (!this.singleDate && this.mode === 'from' && this.isFirstSelect) {
      result = null;
    }
    return result;
  }

  // returns the date that should have the "to" styles (toDate or hoveredDate)
  get popupTo() {
    if (this.singleDate) { return null };
    return this.isFirstSelect ? null : this._toDate;
  }

  isFrom = (date: NgbDate) => date.equals(this.popupFrom);
  isTo = (date: NgbDate) => !this.singleDate && date.equals(this.popupTo);
  isDisabled = (date: NgbDate) => date.before(this.minNgbDate) || date.after(this.maxNgbDate);
  isEdge = (date: NgbDate) => this.isFrom(date) || (!this.singleDate && this.isTo(date));
  isInside = (date: NgbDate) => !this.singleDate && (date.after(this.popupFrom) && date.before(this.popupTo));
  isRange = (date: NgbDate) => !this.singleDate && (this.isEdge(date) || this.isInside(date));
  isSingleDate = (date: NgbDate) => this.isFrom(date) && this.popupTo === null || this.isSameDate(date);
  isSameDate = (date: NgbDate) => this.enableSelectSameDate && this.isFrom(date) && this.isTo(date);

  onDateSelection(event, date: NgbDate) {
    event.stopPropagation(); // dont let NgbInputDatepicker handle the click
    if (this.isDisabled(date)) {
      return;
    }

    if (this.mode === 'from') {
      this.fromDate = date;

    } else if (this.mode === 'to') {
      this.toDate = date;
    }
  }

  // Open/close date picker popup
  toggleDatePicker(mode: 'from' | 'to' = 'from') {
    if (!this.readOnly) {
      if (!this.datepicker.isOpen()) {
        this.mode = mode;
        this.isFirstSelect = false;
        let popupStartDate = this.fromDate; // open popup on this date
        if (this.enableClearAction) {
          if (mode === 'from' && !this.singleDate) {
            this._fromDate = null;
            this._toDate = null;
          } else {
            this._toDate = null;
            popupStartDate = this.toDate;
          }
        }
        this.datepicker.startDate = popupStartDate;
        this._closed = false;
      }
      this.datepicker.toggle();
    }
  }

  onDateInput(event: Event) {
    if (this.disabledModifyDate) {
      const input = event.target as HTMLInputElement;
      const validDate = moment(input.value, datesConfig.momentFormats, true).isValid();
      const birthDate = validDate ? moment(input.value).format('YYYY-MM-DD') : input.value;
      this.datesSelected.emit({
        startDate: birthDate
      });
    }
  }

  // returns a formatted string from a Ngb date
  formatDate(date: NgbDate, format = this.displayFormat): string {
    return date ? moment(this.dateAdapter.toModel(date), datesConfig.momentFormats).format(format) : '';
  }

  // returns a Ngb date from a formatted string
  parseDate(str: string): NgbDate {
    return str ? this.dateAdapter.fromModel(moment(str, datesConfig.momentFormats).toDate()) : null;
  }

  // Ngb undestands Ngb Dates so we need to translate
  get minNgbDate(): NgbDate {
    return this.dateAdapter.fromModel(this.minDate.toDate());
  }

  // Ngb undestands Ngb Dates so we need to translate
  get maxNgbDate(): NgbDate {
    return this.dateAdapter.fromModel(this.maxDate.toDate());
  }

  get fromSelectionInProgress() {
    return this.datepicker.isOpen() && this.mode === 'from';
  }

  get toSelectionInProgress() {
    return this.datepicker.isOpen() && this.mode === 'to';
  }

  set fromDate(date: NgbDate) {
    this._fromDate = date;
    if (this.showApplyButton) {
      return;
    }
    // toDate cant be prior to fromDate
    if (!date || !date.before(this.toDate)) {
      this.toDate = null;
    }

    if (this.singleDate) {
      this.datepicker.close();
    } else {
      this.mode = 'to';
    }

    // values are emitted every time they change
    this.emitDates();
  }

  get fromDate(): NgbDate {
    let res = this._fromDate;
    // if there's no "from" date selected, the default is today
    if (!res && !this.initBlank) {
      res = this.dateAdapter.fromModel(moment().toDate()); // today
    }
    return res;
  }

  set toDate(date: NgbDate) {
    // toDate cant be prior to fromDate
    if (this.isInvalidDate(date)) {
      this._fromDate = date;
      if (this.enableOneDayOption) {
        this._toDate = date;
      }
    } else {
      this._toDate = date;
      this.mode = 'from';
      this.isFirstSelect = false;
    }

    // values are emitted every time they change
    this.emitDates();
  }

  isInvalidDate(date: NgbDate) {
    return (this.enableSelectSameDate) ?
      date && !date.after(this.fromDate) && !date.equals(this.fromDate) :
      date && !date.after(this.fromDate);
  }

  get toDate(): NgbDate {
    let res = this._toDate;
    // if there's no "to" date selected, the default is the next day
    if (!res && !this.initBlank) {
      const auxDate = this.dateAdapter.toModel(this.fromDate);
      const aditionalDays = (this.enableSelectSameDate) ? 0 : 1;
      auxDate.setDate(auxDate.getDate() + aditionalDays);
      res = this.dateAdapter.fromModel(auxDate); // the next day
    }
    return res;
  }

  // returns the fromDate formatted according to displayFormat
  get fromDisplay(): string {
    if (this.mode === 'to') {
      this._fromDate = this.fromDate;
    }
    return this.formatDate(this._fromDate, this.displayFormat);
  }

  // returns the toDate formatted according to displayFormat
  get toDisplay(): string {
    return this.formatDate(this._toDate, this.displayFormat);
  }

  // returns the fromDate formatted according to emitFormat
  get fromValue(): string {
    return this.formatDate(this.fromDate, this.emitFormat);
  }

  // returns the toDate formatted according to emitFormat
  get toValue(): string {
    return this.formatDate(this.toDate, this.emitFormat);
  }

  emitDates(fromLink: boolean = false) {
    if (this.singleDate) {
      this.datesSelected.emit({
        fromLink,
        startDate: this.fromValue
      });

    } else {
      this.datesSelected.emit({
        fromLink,
        startDate: this.fromValue,
        endDate: this.toValue,
        selectionReady: this._closed
      });
    }
  }

  reset() {
    if (this.enableClearAction) {
      this._fromDate = null;
      this._toDate = null;
      this.mode = 'from';
      this.isFirstSelect = false;
    }
  }

  apply() {
    this.datepicker.close();
    this.closeFullscreenModal();
    this.emitDates(true);
  }

  open() {
    this.datepicker.navigateTo(this.fromDate);
    this.datepicker.open();
  }

  modalClosed() {
    this.showFullScreenModal = false;
    this.datepickerClosed.emit();
  }

  openFullscreenModal() {
    this.showFullScreenModal = true;
    this.fullscreenModalRef = this.modalService.open(this.fullscreenModalTemplate, { fullscreen: true, windowClass: 'datepicker-range-modal' });
    this.fullscreenModalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.modalClosed();
    });
  }

  closeFullscreenModal() {
    this.fullscreenModalRef?.dismiss();
  }

  listenToDemandCalendar() {
    if (!this.showDemandCalendar) { return; }

    this.actionsSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      ofType(DemandCalendarActions.DemandCalendarActionTypes.GetDemandCalendarSuccess))
      .subscribe(({ payload: { calendar } }) => {
        this.demandCalendar = calendar;
      });

      this.actionsSubject.pipe(
        takeUntil(this.ngUnsubscribe),
        ofType(DemandCalendarActions.DemandCalendarActionTypes.ClearDemandCalendar))
        .subscribe(() => {
          this.demandCalendar = [];
        });
  }

  public onClosed() {
    this.datepickerClosed.emit();
  }
}
