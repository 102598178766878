import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models/user';
import { AuthApiActionTypes } from 'app/auth/store/actions/auth-api.actions';
import { AuthEffects } from 'app/auth/store/effects';
import { FirebaseHelper } from "app/shared/helpers/firebase.helper";
import { RedirectHelper } from 'app/shared/helpers/redirect.helper';
import { ConfigFlags } from 'app/shared/models/configFlags';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-load/dynamic-script-load.service';
import { LaunchdarklyService } from 'app/shared/services/launchdarkly/launchdarkly.service';
import { MemberService } from 'app/shared/services/member/member.service';
import {
  FlagsActions,
  GamificationActions,
  MailchimpParametersActions,
  UTMParametersActions
} from 'app/shared/store/actions';
import keysJson from 'configs/pathsKeysConfig.json';
import moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, debounceTime, EMPTY, filter, map, mergeMap, Subject, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs';
import * as fromAuth from '../app/auth/store';
import * as fromRoot from '../reducers';
import { AuthApiActions, LogoutComponentActions } from './auth/store/actions';
import { SimpleModalComponent } from './shared/components/simple-modal/simple-modal.component';
import { GeneralHelper } from './shared/helpers/general.helper';
import { ParametersHelper } from './shared/helpers/parameters.helper';
import { SmartlookHelper } from './shared/helpers/smartlook.helper';
import * as AirlineActions from './shared/store/actions/airlines.actions';
import * as fromShared from './shared/store/reducers';
import * as Sentry from '@sentry/angular';
require('./scripts/zendesk.js'); // Call Zendesk Script to define window.zendesk
declare global {
  interface Window { zendesk: any; affirm: any; FB: any, google: any, StoryblokBridge: any;  hbspt: any, location: Location, }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isPending = false;
  shouldDisplayMobileSelector: boolean;
  user$ = this.store.pipe(select(fromAuth.getUser));
  loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
  sessionTimeOut$ = this.store.pipe(select(fromAuth.getSessionTimeOut));
  pending$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  pendingLogout$ = this.store.pipe(select(fromAuth.getLogoutPending));
  availableTools$ = this.store.pipe(select(fromAuth.getAvailableTools));
  utmParameters$ = this.store.pipe(select(fromShared.getUTMParameters));
  airlines$: any = this.store.pipe(select(fromShared.getAirlines));
  configFlags$ = this.store.pipe(select(fromShared.getConfigFlag));
  tools: any;
  user: any;
  webButtons;
  previousUrl: string;
  private smartLookUnsubscribe$ = new Subject<void>();
  private ngUnsubscribe = new Subject<void>();
  private readonly EXPIRATIONUTM = 'expiration_time';
  private readonly DEFAULTEXP = '15';
  private readonly LOADUSERTOOLSPATHS = [/^\/flights(?:\?.*)?$/, /^\/profile/, /^\/search/];

  constructor(
    private generalHelper: GeneralHelper,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private parametersHelper: ParametersHelper,
    private authService: AuthService,
    private router: Router,
    private store: Store<fromRoot.State>,
    private translate: TranslateService,
    private meta: Meta,
    private titleService: Title,
    private redirectHelper: RedirectHelper,
    private deviceService: DeviceDetectorService,
    private authEffects : AuthEffects,
    private firebaseHelper: FirebaseHelper,
    private smartlookHelper: SmartlookHelper,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    private memberService: MemberService,
    private launchdarklyService: LaunchdarklyService,
    private actionsListener$: ActionsSubject
  ) {}

  ngOnInit() {
    if (!this.generalHelper.isServer()) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap(route => route.data.pipe(
          map(data => ({
            data,
            params: route.snapshot.params
          }))
        )),
      takeUntil(this.ngUnsubscribe)
      ).subscribe(({ data, params }) => {
        this.analyticsService.logAutomaticPageView(data, params);
      });
    }

    this.store.dispatch(new AirlineActions.LoadAirlines());
    this.store.dispatch(FlagsActions.loadConfigFlags());

    this.translate.setDefaultLang('en');

    this.setMetaTags();

    if (!this.generalHelper.isServer()) {
      this.loadGenericModal();
      this.displayMobileSelector();

      this.configFlags$
        .pipe(
        filter((configFlags: ConfigFlags) => !!configFlags),
        take(1)
        )
        .subscribe((configFlags: ConfigFlags) => {
          if (configFlags?.ld_client_desktop_enabled === '1') {
            this.listenToLaunchdarklyLogin();
          }
      });

      combineLatest([this.route.params, this.route.queryParams])
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(async ([arParams, arQueryParams]) => {
          if (arQueryParams && !arQueryParams.redirect) {
            const url = `${window.location.pathname}${window.location.href.split(window.location.pathname).pop()}`;
            if (this.generalHelper.getMobileOperatingSystem()) {
            await this.redirectHelper.openApp(url);
            }
          }
          if (arQueryParams && arParams) {
            const params = arQueryParams.redirect ? this.parametersHelper.createObjFromURIParams(arQueryParams.redirect) : Object.assign({}, arParams, this.parametersHelper.parseQueryParams(arQueryParams));
            const setUtmParams = params.utm_campaign || params.utm_medium || params.utm_source || params.utm_affiliate;
            const setMailChimpParams = params.mc_cid;

            if (setUtmParams) {
              await this.setUtmParams(params);
            }
            if (setMailChimpParams) {
              this.setMailchimpParams(params);
            }
            if (arQueryParams.sso_token || arQueryParams.sso) {
              this.authService.loginByParams(arQueryParams);
            }
          }
        }
      );
    }

    if (window.location.pathname === '/logout') {
      return;
    }

    if (!this.generalHelper.isServer()) {
      combineLatest<[boolean, User, any]>([this.loggedIn$, this.user$, this.airlines$])
        .pipe(take(1))
        .subscribe(async ([loggedIn, user, airlines]) => {
          if (loggedIn && user) {
            this.store.dispatch(new AuthApiActions.LoadUserPreferences());
            this.store.dispatch(FlagsActions.initFeatureFlags());
            this.store.dispatch(GamificationActions.loadActiveCampaigns());
            this.store.dispatch(GamificationActions.getStatistics());
            this.setUserConfiguration(user);
            await this.analyticsService.setUserPropertiesAndUserId(user.member.id, {
              account_id: user.member.id90_user_id,
              account_type: this.memberService.getMemberType(user, airlines),
              account_airline: user.member.airline_code,
              account_total_spend: user.total_spent
            });
          }
        }
      );

      this.actionsListener$
      .pipe(
        ofType(AuthApiActions.AuthApiActionTypes.LoginSuccess),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(({payload: { user }}) => {
        this.setUserConfiguration(user);
      });

      combineLatest([this.loggedIn$, this.user$])
      .pipe(takeUntil(this.smartLookUnsubscribe$))
      .subscribe(async ([loggedIn, user]) => {
        if (loggedIn && user && !user?.call_center_admin) {
          this.smartlookHelper.startSmartlook();
          this.smartLookUnsubscribe$.next();
          this.smartLookUnsubscribe$.complete();
        }
      });
    }


    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (!(event instanceof NavigationEnd) || event.url == this.previousUrl) { return; }

      this.previousUrl = event.url;
      const config = keysJson.config;
      const url = event.url;
      const isPath = config.includedPaths.some((rx: string) => {
        const re = new RegExp(rx);
        return re.test(url);
      });

      if(!isPath) { return; }

      let queryParams:any = url.split('?')[1] ?? '';
      queryParams = new URLSearchParams(queryParams);
      const isModifying = queryParams.has('modifying') ? queryParams.get('modifying') : false;
      const currentKey = queryParams.has('key') ? queryParams.get('key') : false;
      let key: any = null;
      (keysJson.paths ?? []).some((item: any) => {
        const reg = new RegExp(item.pattern);
        const needsKeyParam = url.match(reg);
        if (needsKeyParam) {
          const toolKey = config[needsKeyParam[1]] ?? '01';
          // tool
          key = `${toolKey}-`;
          let params = [];
          // step
          const pathHasParams = item?.hasParams ?? false;
          let step = item.step;
          if (pathHasParams) {
            for(let key of queryParams.keys()) {
              params.push(key);
            }
            const hasParams = item?.hasParams.filter(value => params.includes(value));

            if (hasParams.length > 0) {
              if (item.alternativeStep ?? false) {
                step = item.alternativeStep;
              }
              if (item.hasOwnProperty('hasModification') && isModifying) {
                step = item.modifyStep
              }
            }
            if (item.hasOwnProperty('hasNewFilters')) {
              step = `${step}a`
            }
            key += `${step}`;
            // leg
            if (item.hasLegs) {
              const segment = needsKeyParam[2].padStart(2, '0');
              key += `-${segment}`
            }
            return key;
          }
          key += `${step}`;
          // leg
          if (item.hasLegs) {
            const segment = needsKeyParam[2].padStart(2, '0');
            key += `-${segment}`
          }
          return key;
        }
      });
      if (key && key != currentKey) {
        this.checkValidEligibleTraveler();
        this.router.navigate([],{
          queryParams: { key: key },
          queryParamsHandling: 'merge',
          replaceUrl: true
        }).then();
      }

    });
    if (!this.generalHelper.isServer()) {
      this.authEffects.loadInvitedTravelerInfo$.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res)  => this.watchInvalidEligibleTraveler(res));
      this.setupFB();
      this.loadUserTools();
    }
  }

  ngAfterViewInit(): void {
    this.checkExpirationTimeUTM();
  }

  setZendeskUserInfo(user: User) {
    const name = `${user.account.firstName} ${user.account.lastName}`;
    const userData = {
      name,
      email: user.member.email,
      airline: user.account.airlineCode
    };

    if (window.zendesk) {
      window.zendesk.identify(userData);
    }
  }


  onActivate() {
    this.generalHelper.scrollToHeader();
  }

  private displayMobileSelector() {
    const openInAppActive = localStorage.getItem('openInAppActive') === 'true';
    if (window.innerWidth > 768 || !openInAppActive) { return; }
    this.webButtons = [
      { image: 'angular_assets/images/icons/id90-app-icon.png', display: 'ID90 App', url: this.deviceUrl, external: true },
    ];
    const browser = this.getBrowserObject();
    this.webButtons = [...this.webButtons, browser];
    this.shouldDisplayMobileSelector = true;
  }

  private getBrowserObject() {
    const card = { image: '', display: '', url: '', external: false, class: '' };
    card.class = 'btn btn-outline-primary btn-lg text-center border rounded-pill w-100 button-list';
    const device = this.deviceService.getDeviceInfo();
    const browser = device.browser ? device.browser : 'Chrome';
    card.display = browser;
    card.image = `angular_assets/images/icons/${browser}.png`;
    card.url = this.router.url;
    return card;
  }

  get deviceUrl() {
    const os = this.generalHelper.getMobileOperatingSystem();
    return os === 'Android' ? environment.androidStore : environment.iosStore;
  }

  screenClicked() {
    this.shouldDisplayMobileSelector = false;
  }

  buttonSelected(button) {
    if (button.display === 'Google' || 'Safari') {
      this.shouldDisplayMobileSelector = false;
    }
  }

  setMetaTags() {
    this.translate.get(['home_page.thumbnail_title', 'home_page.thumbnail_text', 'meta.description']).pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe((translations) => {
      const title = translations['home_page.thumbnail_title'];
      const text = translations['home_page.thumbnail_text'];
      const description = translations['meta.description'];
      this.titleService.setTitle(title);
      if (title && title.length) {
        this.meta.updateTag({ name: 'twitter:title', content: text });
        this.meta.updateTag({ name: 'twitter:image:alt', content: title });
        this.meta.updateTag({ property: 'og:image:alt', content: title });
        this.meta.updateTag({ property: 'og:title', content: text });
        this.meta.updateTag({ name: 'title', content: title });
        this.meta.updateTag({ property: 'og:description', content: description });
      } else {
        this.meta.removeTag("name='twitter:title'");
        this.meta.removeTag("name='twitter:image:alt'");
        this.meta.removeTag("property='og:image:alt'");
        this.meta.removeTag("property='og:title'");
        this.meta.removeTag("name='title'");
        this.meta.removeTag("name='description'");
      }
    });
  }

  loadGenericModal() {
    this.store.select(fromShared.getData).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((state) => {
      if (!this.modalService.hasOpenModals() && state.show) {
        const modalOptions: {[k: string]: any}  = state.data.modalOptions ? state.data.modalOptions : {};
        modalOptions.backdrop = 'static';
        modalOptions.keyboard = false;
        const modalRef = this.modalService.open(SimpleModalComponent, modalOptions);
        modalRef.componentInstance.title = state.data.title;
        if (state.data.imageTitleSrc) {
          modalRef.componentInstance.imageTitleSrc = state.data.imageTitleSrc;
        }

        if (state.data.body) {
          modalRef.componentInstance.content = state.data.body;
        }

        if (state.data.titleTranslationKey) {
          modalRef.componentInstance.titleTranslationKey = state.data.titleTranslationKey;
        }

        if (state.data.bodyTranslationKey) {
          modalRef.componentInstance.contentTranslationKey = state.data.bodyTranslationKey;
          modalRef.componentInstance.contentTranslationVars = state.data.bodyTranslationVars;
        }

        if (state.data.subtitleTranslationKey) {
          modalRef.componentInstance.subtitleTranslationKey = state.data.subtitleTranslationKey;
        }

        if (state.data.imageSrc) {
          modalRef.componentInstance.imageSrc = state.data.imageSrc;
        }

        if (state.data.imageCls) {
          modalRef.componentInstance.imageCls = state.data.imageCls;
        }

        if (state.data.secondaryButtonLink) {
          modalRef.componentInstance.secondaryButtonLink = state.data.secondaryButtonLink;
        }

        if (state.data.secondaryButton) {
          modalRef.componentInstance.secondaryButton = state.data.secondaryButton;
        }
      }
    });
  }

  setUserConfiguration(user: User, loggedIn: boolean = true) {
    this.isPending = false;
    this.setSentryUserInfo(loggedIn, user);
    this.checkPasswordLastChange(user);
    this.setZendeskUserInfo(user);
  }

  checkPasswordLastChange(storedUserData) {
    this.authService.currentUser().pipe(take(1)).subscribe((memberInfo) => {
      const storagedPasswordUpdateDateTime = moment(storedUserData.member.password_updated_at).format();
      const lastPasswordUpdateDateTime = moment(memberInfo.password_updated_at).format();
      if (storagedPasswordUpdateDateTime !== lastPasswordUpdateDateTime) {
        this.store.dispatch(new AuthApiActions.UpdateMemberChangePasswordDate({ newChangePasswordDate: new Date(memberInfo.password_updated_at) }));
      }
    });
  }

  setSentryUserInfo(loggedIn: boolean, user: User) {
    if (loggedIn && user) {
      Sentry.setUser({
        email: user.member.email,
        id: user.member.id90_user_id,
        username: user.member.employee_number
      });
    } else {
      Sentry.setUser(null);
    }
  }

 async setUtmParams(params) {
    const payload: any = {};
    if (params.utm_campaign) {
      payload.utmCampaign = params.utm_campaign;
    }
    if (params.utm_medium) {
      payload.utmMedium = params.utm_medium;
    }
    if (params.utm_source) {
      payload.utmSource = params.utm_source;
    }
    if (params.utm_affiliate) {
      payload.utmAffiliate = params.utm_affiliate;
    }
    if (payload) {
      payload.expirationTime = await this.getExpirationUTM();
      this.store.dispatch(new UTMParametersActions.Load(payload));
    }
  }

  setMailchimpParams(campaignId: string) {
    this.store.dispatch(new MailchimpParametersActions.Load({ campaignId }));
  }

  private checkValidEligibleTraveler(){
    this.user$.pipe(take(1)).subscribe((user) => {
      if (user?.tools?.includes('ticketing_invited') && user?.account?.employeeNumber) {
        this.store.dispatch(new AuthApiActions.LoadInvitedTravelerInfo({ employeeNumber: user.account.employeeNumber }));
      }
    });
  }
  private checkExpirationTimeUTM() {
    this.utmParameters$.pipe(take(1)).subscribe((utmParams) => {
      const today = moment();
      if(utmParams?.expirationTime) { return }
      if (today > moment(utmParams.expirationTime)) {
        this.store.dispatch(new UTMParametersActions.Clear());
      }
    })
  }

  private async getExpirationUTM() {
    const remote = await this.firebaseHelper.getKey(this.EXPIRATIONUTM);
    if (!remote) { return moment().add(remote ? remote : this.DEFAULTEXP, 'days').toString(); }
  }

  private watchInvalidEligibleTraveler(res: any) {
    if (res.type === AuthApiActionTypes.LoadInvitedTravelerInfoFailure) {
      this.store.dispatch(new LogoutComponentActions.Logout());
    }
  }

  public setupFB() {
    this.dynamicScriptLoader.load('facebook')
      .then(() => {
        window?.FB?.init({
          appId: environment.oauth.facebookApp,
          autoLogAppEvents: true,
          cookie: true,
          xfbml: true,
          version: 'v2.8'
        });
      })
      .catch(error => console.log(error, 'errorLoadingFBScript'));
  }

  private listenToLaunchdarklyLogin() {
    // Automatic Login
    combineLatest([this.loggedIn$, this.user$]).pipe(
      take(1),
      switchMap(([loggedIn, user]) => {
        if (loggedIn && user) {
          return this.launchdarklyService.initialize(user);
        } else {
          return EMPTY;
        }
      })
    ).subscribe();

    // Manual Login
    this.actionsListener$
      .pipe(
      ofType(AuthApiActions.AuthApiActionTypes.LoginSuccess),
      switchMap(({ payload: { user } }) => this.launchdarklyService.initialize(user)),
      takeUntil(this.ngUnsubscribe),
      )
      .subscribe();

    // Logout
    this.actionsListener$
      .pipe(
        ofType(AuthApiActions.AuthApiActionTypes.LogoutSuccess),
        switchMap(() =>
          this.launchdarklyService.close()
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
    }

  loadUserTools() {
    if (this.generalHelper.isServer()) return;
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(
          (event) =>
            event instanceof NavigationEnd &&
            this.LOADUSERTOOLSPATHS.some(
              (path) => path.test(event.url) || path.test(event.urlAfterRedirects)
            ),
        ),
        debounceTime(5000),
        withLatestFrom(this.user$),
        tap(([_event, user]: [any, User]) => {
          this.store.dispatch(new AuthApiActions.LoadUserTools({ user }));
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

