import { ActionReducerMap, createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from 'reducers';
import * as fromInvite from './invite.reducer';
import * as fromInviteStats from './invite-stats.reducer';

export const inviteFeatureKey = 'invite';

export interface InviteState {
  invites: fromInvite.State;
  invitesStat: fromInviteStats.State;
}

export interface State extends fromRoot.State {
  invite: InviteState;
}

export const reducers: ActionReducerMap<InviteState> = {
  invites: fromInvite.reducer,
  invitesStat: fromInviteStats.reducer,
};

export const selectInviteState = createFeatureSelector<InviteState>(inviteFeatureKey);
export const selectInvitesState = createSelector(selectInviteState, (state: InviteState) => state.invites);
export const selectInvitesStatState = createSelector(selectInviteState, (state: InviteState) => state.invitesStat);

export const getInvites = createSelector(selectInvitesState, fromInvite.getInvites);
export const getInvitesPending = createSelector(selectInvitesState, fromInvite.getPending);
export const getInvitesError = createSelector(selectInvitesState, fromInvite.getError);
export const getInvitesSentError = createSelector(selectInvitesState, fromInvite.getSentError);
export const getInvitesSentPending = createSelector(selectInvitesState, fromInvite.getSentPending);

export const getInvitesStats = createSelector(selectInvitesStatState, fromInviteStats.getStats);
export const getInvitesStatsPending = createSelector(selectInvitesStatState, fromInviteStats.getPending);
export const getInvitesStatsError = createSelector(selectInvitesStatState, fromInviteStats.getError);
