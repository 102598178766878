<nav *ngIf="showHeader" id="navbar" class="navbar navbar-expand-lg fixed-top p-0" [ngClass]="isMobile() === true ? 'translucent-navbar': 'custom-nav'">
  <div class="container-fluid">
    <a routerlink="/" class="navbar-brand me-0 min-logo-width  justify-content-center d-flex col-lg-2 col-md-6" href="/">
      <img alt="ID90 Travel" class="d-inline-block align-top" src="angular_assets/images/id90travel-white-logo.png">
    </a>
    <div class="d-block d-lg-none">
      <div class="row align-items-center">
        <div class="col btn-login">
          <button (click)="navigate('login')" class="btn-login btn btn-navbar text-white nav-buttons btn-outline-transparent-light"> {{ 'action_terms.login' | translate }}</button>
        </div>
        <div class="col">
          <button class="navbar-toggler text-white no-border" type="button" (click)="isMenuCollapsed = !isMenuCollapsed" aria-controls="navbarNav"
          aria-expanded="!isMenuCollapsed" aria-label="Toggle navigation">
          <fa-icon *ngIf="isMenuCollapsed" class="fa-icon" [icon]="icons.faBars" aria-hidden="true"></fa-icon>
          <fa-icon *ngIf="!isMenuCollapsed" class="fa-icon" [icon]="icons.faXmark" aria-hidden="true"></fa-icon>
        </button>
        </div>
      </div>
    </div>
    <div [ngbCollapse]="isMenuCollapsed" [ngClass]="navbarClass" id="navbarNav">
      <ul class="navbar-nav justify-content-center col-lg-8">
        <ng-container #navContainerLink></ng-container>
      </ul>
      <ul class="navbar-nav justify-content-start col-lg-4 col-md-12">
        <ng-container #navContainerButton></ng-container>
      </ul>
    </div>
  </div>
</nav>

<main (mouseenter)="clearDrops()">
  <router-outlet *ngIf="renderContent"></router-outlet>
</main>

<footer *ngIf="showFooter">
  <div id="consent_blackbar"></div>
  <div class="container-fluid footer-container">
    <div class="row h-100 py-5">
      <ng-container #footerContainer></ng-container>
    </div>
  </div>
</footer>

<ng-template let-data="data" name="links">
  <li class="nav-item hover-line">
    <a class="nav-link text-white"
    [appStoryblok]="data._editable"
    aria-current="page"
    (click)="headerNavigate(data)">
    {{data.label}}</a>
  </li>
</ng-template>

<ng-template let-data="data" name="nav-dropdown">
  <li ngbDropdown #drop="ngbDropdown"  (mouseover)="onHover(drop)" class="nav-item dropdown"
    [appStoryblok]="data._editable">
    <a class="nav-link dropdown-toggle text-white hover-line" ngbDropdownToggle> {{ data.name }}</a>
    <div ngbDropdownMenu (mouseout)="leaveHover(drop)">
      <a *ngFor="let subMenu of data?.submenu" ngbDropdownItem
        class="nav-link dropdown-item d-flex justify-content-left text-start" [routerLink]="['/', { outlets: { primary: subMenu.link.url }}]">{{ subMenu.label
        }}</a>
    </div>
  </li>
</ng-template>

<ng-template let-data="data" name="button">
  <li class="nav-item ms-1 mb-1" *ngIf="!data.force_position_header_mobile || (data.force_position_header_mobile && !this.isMobile())">
    <div [appStoryblok]="data._editable">
     <button type="button" class="btn btn-navbar w-20 text-white nav-buttons mt-3 ms-1"  (click)="navigate(data.link.url)" [ngClass]="generateButtonStyle(data.style, data.background_color)">
        {{data.label}}
     </button>
  </div>
  </li>

</ng-template>

<ng-template let-data="data" name="footer-link">
  <div class="p-3" [ngClass]="generateBootstrapColumn(data.column)" [ngStyle]="{
    'text-align': data?.text_align,
    'line-height': data?.line_height,
  }" [appStoryblok]="data._editable">
    <h5 class="primary-content">{{ data.text }}</h5>
    <a class="d-block secondary-content gtm-footer-link mb-2 mt-2" (click)="navigate(subLinkItem)"
      href="javascript:void(0);" *ngFor="let subLinkItem of data?.subLink"> {{ subLinkItem.label }}
    </a>
  </div>
</ng-template>

<ng-template let-data="data" name="footer-imagen">
  <div [ngClass]="generateBootstrapColumn(data.column)" [ngStyle]="{
    'text-align': data?.text_align,
  }" [appStoryblok]="data._editable">
    <img class="img-fluid footer-image" [src]="getImageUrlResized(data?.image?.filename, 200)" />
  </div>
</ng-template>

<ng-template let-data="data" name="footer-bottom">
  <div class="col-md-6 d-flex align-items-center tertiary-content pt-2 pb-2" [appStoryblok]="data._editable">
    <em>{{ generateCopyRight() }}</em>
  </div>
  <div class="col-md-6  social-icon pb-2 pt-2">
    <a *ngFor="let socialLink of data?.content" [href]="socialLink.link.url" target="_blank"
      class="btn-social gtm-footer-social"><fa-icon [icon]="icons[socialLink.icon]"></fa-icon>
    </a>
  </div>
</ng-template>
