import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AuthApiActions,
  EventRegistrationActions,
  ForgotPasswordActions,
  LoginPageActions,
  RegisterPageActions,
  ThirdPartyActions
} from '../actions';
import { catchError, concatMap, exhaustMap, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/services/authentication.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Modal } from 'app/shared/models/modal.model';
import * as fromShared from 'app/shared/store/reducers';
import {
  CountryActions,
  FlagsActions,
  GamificationActions,
  LanguageActions,
  ModalActions,
  UTMParametersActions
} from 'app/shared/store/actions';
import * as fromFlights from 'app/flights/store/actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from 'app/shared/services/profile/profile.service';
import { MemberService } from 'app/shared/services/member/member.service';
import {
  CreditActions,
  CreditHistoryActions,
  ProfileUserActions,
  PromoCodeAction,
  SettingsActions
} from 'app/profile/store/actions';
import { WindowService } from 'app/shared/services/window/window.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { UserPreferencesService } from 'app/shared/services/user-preferences/user-preferences.service';
import { EligibleTravelersService } from 'app/shared/services/eligible-travelers/eligible-travelers.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { RedirectHelper } from 'app/shared/helpers/redirect.helper';
import { User } from '../../models/user';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { featureFlags } from 'app/shared/models/featureFlags';
import { AffirmService } from 'app/shared/services/affirm/affirm.service';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { PhoneNumberHelper } from 'app/shared/helpers/phone-number.helper';
import { SmartlookHelper } from 'app/shared/helpers/smartlook.helper';
import { ConfigFlags } from 'app/shared/models/configFlags';
import { UserToolsService } from 'app/auth/services/user-tools.service';
import { AuthService } from 'app/shared/services/auth/auth.service';
import * as Sentry from '@sentry/angular';
@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
  this.actions$.pipe(
    ofType<LoginPageActions.Login>(LoginPageActions.LoginPageActionTypes.Login),
    map(action => action.payload),
    switchMap((data: any) => {
      return this.authenticationService.login(data.credentials).pipe(
        withLatestFrom(this.store.select<ConfigFlags>(fromShared.getConfigFlag)),
        map(([user, configFlags]: [User, ConfigFlags]) => {
          if (user.generic_user && !configFlags.generic_user_airlines?.includes(user.account.airlineCode)) {
            throw Error('generic_user_error');
          }
          if (user.is_admin && user.redirect) {
            this.router.navigate(['/redirect'], {queryParams: { url: encodeURIComponent(user.redirect) }});
            return new AuthApiActions.LoginAdminSuccess();
          }
          user.member.redirect = this.redirectHelper.redirectFromLogin(user, data);
          user.showNewAccountModal = data.showNewAccountModal;
          user.cobus_authorization = (data.credentials.password && data.credentials.password.indexOf('cobus_') !== -1) ?
            data.credentials.password.split('cobus_')[1] :
            null;
          const payload = data.showNewAccountModal ? {
            user,
            showNewAccountModal: data.showNewAccountModal
          } : {user};
          return new AuthApiActions.LoginSuccess({
            ... payload,
            fromRegister: data.fromRegister,
          });
        }),
        catchError((error) => {
          const errorDescriptionCb = (errorDescription) => {
            const message = {
              type: 'danger',
              message: errorDescription
            };
            this.analyticsService.logEvent('login_error', {
              method: 'Email',
              success: false,
              message: errorDescription
            });
            this.smartlookHelper.customEvent('Login Error', { message: errorDescription });
            this.store.dispatch(new LoginPageActions.LoginShowMessage({message}));
          };
          if (error && error.message === 'generic_user_error') {
            this.translate.get('login_page.generic_user_error').pipe(take(1)).subscribe((errorDescription) => {
              errorDescriptionCb(errorDescription);
            });
            return of(new AuthApiActions.LoginFailure({error}));
          }
          if (error && error?.error?.error_code === 'MembershipRenewalRequired') {
            this.translate.get('login_page.inactive').pipe(take(1)).subscribe((errorDescription) => {
              errorDescriptionCb(errorDescription);
            });
            return of(new AuthApiActions.LoginFailure({error}));
          }
          if (error && error.error && error.error.error_description) {
            errorDescriptionCb(error.error.error_description);
          } else {
            this.translate.get('login_page.invalid').pipe(take(1)).subscribe((errorDescription) => {
              errorDescriptionCb(errorDescription);
            });
          }
          return of(new AuthApiActions.LoginFailure({error}));
        })
      );
    })
  )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
     ofType<AuthApiActions.LoginSuccess>(AuthApiActions.AuthApiActionTypes.LoginSuccess),
     withLatestFrom(this.store.select(fromShared.getAirlines)),
     map( ([action, airlines]) => {
      return {
       ... action.payload,
       airlines
      }
     }),
     tap((action) => {
       const data = action;
       const eventType = action.fromRegister ? 'sign_up' : 'login';
       const user = data.user;
       if (!user.first_time_login) { this.analyticsService.logFirebaseSessionEvent(data, action.airlines, eventType); }
       this.phoneNumberHelper.isValid(user.account?.homePhone)
         ? localStorage.setItem('dontAskAgain', 'true')
         : localStorage.setItem('dontAskAgain', 'false');

       // We must do this because we need to load the translations for a user's airline
       // and the translator loader does not load the default language again.
       this.translate.resetLang('en');
       this.translate.reloadLang('en');

       this.sessionService.setState(user);
       this.smartlookHelper.resumeRecording();
       this.smartlookHelper.identifyUser();
       this.smartlookHelper.customEvent('Login Success', { airline: user.member.airline_code, employeeNumber: user.member.employee_number, email: user.member.email });
       this.store.dispatch(new LanguageActions.LoadLanguages());
       if (this.sessionService.getPromoCode()) {
         const params = this.sessionService.getPromoCode();
         this.store.dispatch(new PromoCodeAction.RedeemPromoCode({code: params}));
         this.store.dispatch(new CreditActions.LoadCredits());
         this.store.dispatch(new CreditHistoryActions.LoadCredits());
       }
       this.store.dispatch(new SettingsActions.LoadSettings());
       this.affirmService.checkAndStartAffirmScript();
       this.store.dispatch(new fromFlights.SearchFormActions.ClearSearchFlights());
       this.store.dispatch(new CountryActions.LoadCountries());
       this.store.dispatch(new UTMParametersActions.Clear());
       this.store.dispatch(new ForgotPasswordActions.ResetAttempts());
       this.store.dispatch(FlagsActions.initFeatureFlags());
       this.store.dispatch(GamificationActions.loadActiveCampaigns());
       this.store.dispatch(GamificationActions.getStatistics());

       if (this.cookieService.get('thirdPartyConnect')) {
         const thirdPartyCookie = JSON.parse(this.cookieService.get('thirdPartyConnect'));
         this.store.dispatch(new ThirdPartyActions.ConnectThirdParty({
           platform: thirdPartyCookie['platform'],
           authToken: thirdPartyCookie['token'],
           userInfo: user,
           showModalError: true
         }));
         this.cookieService.delete('thirdPartyConnect');
         this.cookieService.set('avoidDisplayFacebookModal', 'true');
       }
       this.store.dispatch(new LanguageActions.LoadLanguages());
       if (user.tools.includes('ticketing_invited')) {
         this.store.dispatch(new AuthApiActions.LoadInvitedTravelerInfo({employeeNumber: user.account.employeeNumber}));
       }
       if (!user.first_time_login) {
         this.store.dispatch(new AuthApiActions.LoadUserConfigSuccess());
       }

       if (!user.first_time_login && user.account.id90Status === 'active') {
        this.store.dispatch(new AuthApiActions.LoadStatistics());
       }
       this.store.dispatch(new AuthApiActions.LoadUserPreferences());
       this.store.dispatch(new AuthApiActions.LoadUserTools({user}));
       if (user.showNewAccountModal) {
         const data: Modal = {
           imageSrc: 'angular_assets/images/homepage/paper_plane_2.png',
           imageCls: 'register-success-img',
           subtitleTranslationKey: 'mobile.registration.confirm_your_account_title',
           bodyTranslationKey: 'mobile.registration.confirm_your_account_content',
           bodyTranslationVars: {}
         };
         this.store.dispatch(new ModalActions.Show({data}));
       }
       if (user.member.redirect) {
         return this.router.navigateByUrl(user.member.redirect);
       }
     })
    ),
    {dispatch: false}
  );

  loadUserConfigSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthApiActions.LoadUserConfigSuccess>(AuthApiActions.AuthApiActionTypes.LoadUserConfigSuccess),
      tap(() => {
        this.store.dispatch(new ProfileUserActions.LoadBusinessToggle());
        this.store.dispatch(new ProfileUserActions.LoadUserProfile());
        this.store.dispatch(new CreditActions.LoadCredits());
        this.store.dispatch(new CreditHistoryActions.LoadCredits());
      })
    ),
  {dispatch: false}
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
     ofType<RegisterPageActions.Register>(RegisterPageActions.RegisterPageActionTypes.Register),
     map(action => action.payload.credentials),
     exhaustMap((auth: any) =>
                  this.authenticationService.register(auth).pipe(
                    map((resp) => {
                      return new AuthApiActions.RegisterSuccess({auth});
                    }),
                    catchError((error) => {
                      if (error?.error?.error_code === 'EmailInUse' || error?.error?.error_code === 'EmployeeNumberInUse') {
                        this.analyticsService.logEvent('sign_up_error', {
                          method: 'Email',
                          success: false,
                          message: error.error.error_description
                        });
                      } else {
                        Sentry.captureException(error);
                      }
                      return of(new AuthApiActions.RegisterFailure({error}));
                    })
                  )
     )
    )
  );

  registerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthApiActions.RegisterSuccess>(AuthApiActions.AuthApiActionTypes.RegisterSuccess),
      map(action => action.payload.auth),
      exhaustMap((auth: any) => {
        this.smartlookHelper.customEvent('Create Account', { airline: auth.airline_code, employeeNumber: auth.employee_number, email: auth.email });
        if (auth.traveler_code) {
          this.store.dispatch(new AuthApiActions.UpdateInvitedTraveler({
           travelerCode: auth.traveler_code,
           employeeNumber: auth.employee_number,
           password: auth.password
         }));
        }
        return of(new LoginPageActions.Login({
         credentials: auth,
         showNewAccountModal: auth.airline_code !== AuthService.INVITED_MEMBERS_AIRLINE_CODE,
         fromRegister: true
       }));
      })
    )
  );

  loadInvitedTravelerInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthApiActions.LoadInvitedTravelerInfo>(AuthApiActions.AuthApiActionTypes.LoadInvitedTravelerInfo),
      map(action => action.payload.employeeNumber),
      switchMap((employeeNumber: string) => {
        return this.eligibleTravelersService.getInvitedTravelerInfo(employeeNumber).pipe(
          map((resp) => {
            return new AuthApiActions.LoadInvitedTravelerInfoSuccess({airlines: resp.data});
          }),
          catchError((error) => {
            return of(new AuthApiActions.LoadInvitedTravelerInfoFailure({error}));
          })
        );
      })
    )
  );

  updateInvitedTraveler$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthApiActions.UpdateInvitedTraveler>(AuthApiActions.AuthApiActionTypes.UpdateInvitedTraveler),
      switchMap((action) => {
        return this.eligibleTravelersService.addMemberToTraveler(
          action.payload.travelerCode,
          action.payload.password,
          action.payload.employeeNumber
        ).pipe(
          map(() => {
            return new AuthApiActions.UpdateInvitedTravelerSuccess();
          }),
          catchError((error) => {
            return of(new AuthApiActions.UpdateInvitedTravelerFailure({error}));
          })
        );
      })
    ),
  {dispatch: false}
  );

  avatarUpload$ = createEffect(() =>
    this.actions$.pipe(
     ofType<AuthApiActions.AvatarUpload>(AuthApiActions.AuthApiActionTypes.AvatarUpload),
     map(action => action.payload.avatarUrl),
     exhaustMap((avatarUrl: any) =>
                  this.profileService.updateProfile({image: avatarUrl}).pipe(
                    map(() => {
                      return new AuthApiActions.AvatarUploadSuccess({avatarUrl});
                    })
                  )
     )
    )
  );

  marketingEventLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginPageActions.MarketingLogin>(LoginPageActions.LoginPageActionTypes.MarketingLogin),
      map(action => action.payload.credentials),
      switchMap((data: any) => {
        return this.authenticationService.marketingLogin(data).pipe(
          map(() => {
            return new AuthApiActions.LoginMarketingEventSuccess({});
          }),
          catchError((error) => {
            return of(new AuthApiActions.LoginMarketingEventFailure({error}));
          })
        );
      })
    )
  );

  offlineRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EventRegistrationActions.OfflineMemberRegister>(EventRegistrationActions.EventRegistrationActionTypes.OfflineMemberRegister),
      map(action => action.payload.credentials),
      exhaustMap((auth: any) =>
                   this.authenticationService.register(auth).pipe(
                     map(() => {
                       return new EventRegistrationActions.MemberRegisterSuccess({auth});
                     }),
                     catchError((error) => {
                       if (!error.error.error_description) {
                         return of(new EventRegistrationActions.MemberRegisterSendLater({auth}));
                       }
                       return of(new EventRegistrationActions.MemberRegisterFailure({error}));
                     })
                   )
      )
    )
  );

  memberRegister$ = createEffect(() =>
    this.actions$.pipe(
     ofType<EventRegistrationActions.MemberRegisterSync>(EventRegistrationActions.EventRegistrationActionTypes.MemberRegisterSync),
     map(action => action.payload.credentials),
     concatMap((auth: any) => {
       return this.authenticationService.register(auth).pipe(
         map(() => {
           return new EventRegistrationActions.MemberRegisterSyncSuccess({auth});
         }),
         catchError((error) => {
           return of(new EventRegistrationActions.MemberRegisterSyncFailure({
                                                                              error,
                                                                              auth
                                                                            }));
         })
       );
     })
    )
  );

  loadStatistics$ = createEffect(() =>
    this.actions$.pipe(
     ofType<AuthApiActions.LoadStatistics>(AuthApiActions.AuthApiActionTypes.LoadStatistics),
     switchMap(() => {
                 return this.memberService.getMemberStats().pipe(
                   map((statistics) => {
                     return new AuthApiActions.LoadStatisticsSuccess({statistics});
                   }),
                   catchError((error) => {
                     return of(new AuthApiActions.LoadStatisticsFailure(error));
                   })
                 );
               }
     )
    )
  );

  selectUser$ = createEffect(() =>
    this.actions$.pipe(
     ofType<AuthApiActions.SelectUser>(AuthApiActions.AuthApiActionTypes.SelectUser),
     switchMap((action) => {
                 return this.authenticationService.selectEmployee(action.payload.employeeNumber).pipe(
                   map((response) => {
                     if (response.error) {
                       return new AuthApiActions.SelectUserFailure({error: response.error});
                     }
                     this.store.dispatch(new CreditHistoryActions.LoadCredits());
                     return new AuthApiActions.SelectUserSuccess({accessToken: response.access_token});
                   }),
                   catchError((error) => {
                     return of(new AuthApiActions.SelectUserFailure({error}));
                   })
                 );
               }
     )
    )
  );

  selectUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthApiActions.SelectUserSuccess>(AuthApiActions.AuthApiActionTypes.SelectUserSuccess),
      map((action) => {
        return new AuthApiActions.ReloadUser({accessToken: action.payload.accessToken});
      })
    )
  );

  selectEmployee$ = createEffect(() =>
    this.actions$.pipe(
     ofType<AuthApiActions.SelectEmployee>(AuthApiActions.AuthApiActionTypes.SelectEmployee),
     switchMap((action) => {
                 return this.authenticationService.selectUser(action.payload.employeeNumber).pipe(
                   map((response) => {
                     if (response.error) {
                       return new AuthApiActions.SelectUserFailure({error: response.error});
                     }
                     this.store.dispatch(new CreditHistoryActions.LoadCredits());
                     return new AuthApiActions.SelectUserSuccess({accessToken: response.access_token});
                   }),
                   catchError((error) => {
                     return of(new AuthApiActions.SelectUserFailure({error}));
                   })
                 );
               }
     )
    )
  );

  reloadUser$ = createEffect(() =>
    this.actions$.pipe(
     ofType<AuthApiActions.ReloadUser>(AuthApiActions.AuthApiActionTypes.ReloadUser),
     switchMap((action) => {
       return this.authenticationService.currentSession(action.payload.accessToken).pipe(
         map((user) => {
           user.member.redirect = '/hotels';
           if (user && user.member.member_id && user.first_time_login) {
             user.member.redirect = '/first_time/new';
           } else if (this.router.url === '/hotels/gift-card-checkout') {
             this.router.routeReuseStrategy.shouldReuseRoute = () => false;
             user.member.redirect = '/hotels/gift-card-checkout#op';
           }
           return new AuthApiActions.LoginSuccess({user});
         }),
         catchError((error) => {
           const errorDescriptionCb = (errorDescription) => {
             const message = {
               type: 'danger',
               message: errorDescription
             };
             this.store.dispatch(new LoginPageActions.LoginShowMessage({message}));
           };
           if (error && error.error && error.error.error_description) {
             errorDescriptionCb(error.error.error_description);
           } else {
             this.translate.get('login_page.invalid').pipe(take(1)).subscribe((errorDescription) => {
               errorDescriptionCb(errorDescription);
             });
           }
           return of(new AuthApiActions.LoginFailure({error}));
         })
       );
     })
    )
  );

  reloadSSOUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthApiActions.ReloadSSOUser>(AuthApiActions.AuthApiActionTypes.ReloadSSOUser),
      switchMap((action) => {
        return this.authenticationService.currentSession(action.payload.accessToken).pipe(
          map((user) => {
            const method = action.payload.platform ? action.payload.platform.toLowerCase() : 'Saml';
            user.member.redirect = this.selectRedirectRoute(action.payload.redirect);
            return new AuthApiActions.LoginSuccess({user, method});
          }),
          catchError((error) => {
            const errorDescriptionCb = (errorDescription) => {
              const message = {
                type: 'danger',
                message: errorDescription
              };
              this.store.dispatch(new LoginPageActions.LoginShowMessage({message}));
            };
            if (error && error.error && error.error.error_description) {
              errorDescriptionCb(error.error.error_description);
            } else {
              this.translate.get('login_page.invalid').pipe(take(1)).subscribe((errorDescription) => {
                errorDescriptionCb(errorDescription);
              });
            }
            return of(new AuthApiActions.LoginFailure({error}));
          })
        );
      })
    )
  );

  checkVerifyUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthApiActions.CheckVerifyUser>(AuthApiActions.AuthApiActionTypes.CheckVerifyUser),
      switchMap((action) => {
                  return this.authenticationService.currentSession(action.payload.accessToken).pipe(
                    map((user) => {
                      if (!user.member.confirmed) {
                        const data: Modal = {
                          title: 'flights.search.modals.passengers.title',
                          bodyTranslationKey: 'flash_messages.user_not_confirmed',
                          bodyTranslationVars: {email: user.member.email},
                          secondaryButton: 'members.confirm_email.resend_email',
                          secondaryButtonLink: '/membership/resend-confirmation-email',
                          modalOptions: {size: 'lg'}
                        };
                        this.store.dispatch(new ModalActions.Show({data}));
                      } else {
                        this.windowService.assignLocation(action.payload.url);
                      }
                      return new AuthApiActions.LoginSuccess({user});
                    }),
                    catchError((error) => {
                      return of(new AuthApiActions.CheckVerifyFailure({
                                                                        error,
                                                                        url: action.payload.url
                                                                      }));
                    })
                  );
                }
      )
    )
  );

  checkVerifyFailure$ = createEffect(() =>
    this.actions$.pipe(
     ofType<AuthApiActions.CheckVerifyFailure>(AuthApiActions.AuthApiActionTypes.CheckVerifyFailure),
     tap((action) => {
       if (this.featureFlagsService.isFeatureEnabled(featureFlags.LOGIN)) {
         this.redirectHelper.openApp(action.payload.url);
         const data: Modal = {
           title: 'flash_messages.sign_in',
           bodyTranslationKey: 'flash_messages.user_not_logged_in',
           modalOptions: {size: 'lg'}
         };
         this.store.dispatch(new ModalActions.Show({data}));
       }
       return this.router.navigate(['/login'], {queryParams: {redirect: encodeURIComponent(action.payload.url)}});
     })
    ),
    {dispatch: false}
  );

  loginWithJWT$ = createEffect(() =>
    this.actions$.pipe(
     ofType<LoginPageActions.LoginWithJWT>(LoginPageActions.LoginPageActionTypes.LoginWithJWT),
     switchMap((action) => {
       return this.authenticationService.loginJWT({
            jwt: action.payload.accessToken,
            is_whitelabel: action.payload.isWhiteLabel,
            utm_campaign: action.payload.utm_campaign,
            utm_medium: action.payload.utm_medium,
            utm_source: action.payload.utm_source
          }).pipe(
         map((user) => {
           user.member.redirect = action.payload.redirect;
           return new AuthApiActions.LoginSuccess({user, method: 'JWT'});
         }),
         catchError((error) => {
           this.analyticsService.logEvent('login_error', {
             method: 'JWT',
             success: false,
             message: error?.error
           });
           return of(new AuthApiActions.CheckVerifyFailure({
                                                             error,
                                                             url: action.payload.redirect
                                                           }));
         })
       );
     })
    )
  );

  updateUserLanguage$ = createEffect(() =>
   this.actions$.pipe(
     ofType<AuthApiActions.UpdateUserLanguage>(AuthApiActions.AuthApiActionTypes.UpdateUserLanguage),
     switchMap((action) => {
       this.translate.use(action.payload.language);
       return this.userPreferencesService.updatePreferences({language: action.payload.language}).pipe(
         map(() => {
           return new AuthApiActions.UpdateUserLanguageSuccess();
         }),
         catchError((error) => {
           return of(new AuthApiActions.UpdateUserLanguageFailure({error}));
         })
       );
     })
   ),
 {dispatch: false}
  );

  loadUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthApiActions.LoadUserPreferences>(AuthApiActions.AuthApiActionTypes.LoadUserPreferences),
      switchMap(() => {
        return this.userPreferencesService.getPreferences().pipe(
          map((preferences) => {
            this.store.dispatch(new AuthApiActions.UpdateUserLanguage({language: preferences.language}));
            return new AuthApiActions.LoadUserPreferencesSuccess(preferences);
          }),
          catchError((error) => {
            return of(new AuthApiActions.LoadUserPreferencesFailure({error}));
          })
        );
      })
    )
  );

  loadUserTools$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthApiActions.LoadUserTools>(AuthApiActions.AuthApiActionTypes.LoadUserTools),
      switchMap((action) => {
        let tools = {};
        const user = action.payload.user;
        if (user.account.airlineCode === '100' && user.tools.includes('ticketing_invited')) {
          tools = {
            ticketing_invited: {
              employeeNumber: user.account.employeeNumber,
            },
          };
        } else {
          if (!user.cobus_authorization) {
            tools = {
              cobus_admin: {
                employeeNumber: user.account.employeeNumber,
                airlineCode: user.account.airlineCode,
              },
            };
          }
        }
        return this.userToolsService.loadUserTools(tools).pipe(
          map((tools) => {
            return new AuthApiActions.LoadUserToolsSuccess({tools});
          }),
          catchError((error) => {
            return of(new AuthApiActions.LoadUserToolsFailure({error}));
          }),
        );
      }),
    ),
  );
  private selectRedirectRoute(route: string) {
    if (route === '/') {
      return 'search/hotels';
    }
    return route;
  }

  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private translate: TranslateService,
    private profileService: ProfileService,
    private store: Store<fromShared.State>,
    private memberService: MemberService,
    private windowService: WindowService,
    private sessionService: SessionService,
    private userPreferencesService: UserPreferencesService,
    private eligibleTravelersService: EligibleTravelersService,
    private cookieService: SsrCookieService,
    private redirectHelper: RedirectHelper,
    private featureFlagsService: FeatureFlagsService,
    private affirmService: AffirmService,
    private phoneNumberHelper: PhoneNumberHelper,
    private smartlookHelper: SmartlookHelper,
    private userToolsService: UserToolsService
  ) {
  }
}
