import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { versions } from '@env/version';
import { Loader } from "@googlemaps/js-api-loader";
import * as Sentry from '@sentry/angular';

const useSentry = typeof(environment.sentryDsn) === 'string' && environment.sentryDsn !== '';
if (useSentry) {
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [environment.siteUrl],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    environment: environment.env,
    release: versions.revision,
    // This option receives a list of strings and regular expressions to match against the error message.
    // When using strings, partial matches will be filtered out. If you need to filter by exact match,
    // use regex patterns instead.
    ignoreErrors: [
      'HttpErrorResponse',
      'idpiframe_initialization_failed',
      'ChunkLoadError',
    ],
  });

  // We will use this again after working on FES-733
  // const originalWarn = console.warn;
  // console.warn = function (message?: any, ...optionalParams: any[]) {
  //   if (typeof message === 'string' && message.includes('value provided is not in a recognized RFC2822 or ISO format')) {
  //     Sentry.captureMessage(message, 'warning');
  //   }
  //   originalWarn.apply(console, [message, ...optionalParams]);
  // };
}

(async () => {
  const loader = new Loader({
    apiKey: `${environment.googlePlacesApiKey}`,
    version: "quarterly",
    libraries: ['places']
  });
  return await loader.importLibrary('places');
})();

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
