import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ISbRichtext, ISbStory } from 'storyblok-js-client';
import { SearchHeroBannerButton, SearchHeroBannerContent, SearchHeroBannerItem, SearchHeroBannerStoryblok } from 'app/shared/models/search-hero.type';
import { StoryblokService } from 'app/shared/services/storyblok/storyblok.service';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { ContentInteractionData } from 'app/shared/models/analytics-data';

@Component({
  selector: 'app-search-hero',
  templateUrl: './search-hero.component.html',
  styleUrls: ['./search-hero.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchHeroComponent implements OnInit {
  public content = '';
  public items: SearchHeroBannerItem[] = [];
  public loading = true;
  private folder = 'hotel-home';
  private slugName = 'hotel-search-page';

  constructor(
    private analyticsService: AnalyticsService,
    private storyblokService: StoryblokService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    await this.loadRemoteContent();
    this.loading = false;
  }

  public buttonClick(button: SearchHeroBannerButton, contentInteractionData: Omit<ContentInteractionData, 'content_id'>): void {
    this.analyticsService.logContentInteraction({ ...contentInteractionData, content_id: button.contentId });

    if (button.external) {
      window.open(button.url, '_blank');
      return;
    }
    this.router.navigateByUrl(button.url);
  }

  private async loadRemoteContent() {
    const storyblokData: ISbStory['data'] | { error } = await this.storyblokService.getStory(`${this.folder}/${this.slugName}`);
    if ('error' in storyblokData) {
      return;
    }

    const storyblokContent: SearchHeroBannerStoryblok[] = storyblokData.story.content.hero_banner;

    if (!storyblokContent) {
      return;
    }

    this.items = storyblokContent.map((heroBannerItem, i): SearchHeroBannerItem => {
      const content = this.parseRichTextContent(heroBannerItem.text.content);
      return {
        backgroundImage: {
          url: heroBannerItem.background_image?.filename,
          alt: heroBannerItem.background_image?.alt
        },
        content,
        contentInteractionData: {
          content_slot: `Homepage_Banner_${i}`,
          content_type: 'Banner',
        },
        button: content.find((c): c is SearchHeroBannerButton => c.cType === 'button')
      };
    });
  }

  private parseRichTextContent(richTextContent: ISbRichtext[]): SearchHeroBannerContent[] {
    return richTextContent.map(richText => this.parseRichTextComponent(richText)).flat();
  }

  private parseRichTextComponent(richTextComponent: ISbRichtext): SearchHeroBannerContent | SearchHeroBannerContent[] {
    switch (richTextComponent.type) {
      case 'blok':
        return this.parseSearchHeroBannerButtons(richTextComponent.attrs.body);
      case 'paragraph':
      default:
        if (!richTextComponent.content) {
          return {
            cType: 'text',
            text: '',
            cssClass: 'blank-space',
          };
        }
        return {
          cType: 'text',
          text: this.storyblokService.parseTextRich(richTextComponent),
          cssClass: '',
        };
    }
  }

  private parseSearchHeroBannerButtons(buttonsStoryblok: Record<string, string>[]): SearchHeroBannerButton[] {
    return buttonsStoryblok.map(buttonStoryblok => this.parseSearchHeroBannerButton(buttonStoryblok));
  }

  private parseSearchHeroBannerButton(buttonStoryblok: Record<string, string>): SearchHeroBannerButton {
    return {
      cType: 'button',
      label: buttonStoryblok.label,
      url: buttonStoryblok.link,
      style: this.getButtonStyle(buttonStoryblok.button_style),
      contentId: buttonStoryblok.content_id,
      external: !!buttonStoryblok.external
    };
  }

  private getButtonStyle(style: string) {
    switch (style) {
      case 'outline':
        return 'btn-outline-white';
      case 'white':
      default:
        return 'btn-white';
    }
  }
}
