<header-component
  *ngIf="showNavbar"
  [scrollable]="scrollableHeader"
  [showAnnouncement]="showAnnouncement"
></header-component>
<main>
  @defer {
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  } @placeholder {
    <loading-indicator></loading-indicator>
  }
</main>
<footer-component></footer-component>
