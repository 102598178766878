import { InviteActions } from "../actions";

export interface State {
  error: any | null;
  pending: boolean;
  invites: Invites;
  sentError: any | null;
  sentPending: boolean;
}

export type Invites = {
  offset: number,
  rewarded: Invite[],
  confirmed: Invite[],
  pending: Invite[],
};

export type Invite = {
  id: number,
  referee_id: number,
  referrer_id: number,
  email: string,
  type: string,
  source_program: string,
  alias: string,
  description: string,
  reminders: number,
  referrer_ip: string,
  referee_ip: string,
  status: string,
  amount: number,
  created_at: string,
  updated_at: string,
}

export const initialState: State = {
  error: null,
  pending: false,
  invites: {
    offset: 0,
    rewarded: [],
    confirmed: [],
    pending: []
  },
  sentError: null,
  sentPending: false,
};

export function reducer(
  state = initialState,
  action: InviteActions.InviteActionsUnion
): State {
  switch (action.type) {
    case InviteActions.InviteActionTypes.SendInvites:
      return { ...state, sentPending: true, sentError: null };
    case InviteActions.InviteActionTypes.SendInvitesSuccess:
      return { ...state, sentPending: false, sentError: null };
    case InviteActions.InviteActionTypes.SendInvitesFailure:
      return { ...state, sentPending: false, sentError: action.payload.error };
    case InviteActions.InviteActionTypes.LoadPastInvites:
      return { ...state, error: null, pending: true };
    case InviteActions.InviteActionTypes.LoadPastInvitesSuccess:
      return {
        ...state,
        error: null,
        pending: false,
        invites: {
          offset: action.payload.offset,
          rewarded: [...state.invites.rewarded, ...action.payload.invites.rewarded],
          confirmed: [...state.invites.confirmed, ...action.payload.invites.confirmed],
          pending: [...state.invites.pending, ...action.payload.invites.pending],
        },
      };
    case InviteActions.InviteActionTypes.LoadPastInvitesFailure:
      return { ...state, error: action.payload.error, pending: false };
    case InviteActions.InviteActionTypes.ResetPastInvites:
      return { ...initialState };
    default:
      return state;
  }
}

export const getInvites = (state: State) => state.invites;
export const getPending = (state: State) => state.pending;
export const getError   = (state: State) => state.error;
export const getSentError   = (state: State) => state.sentError;
export const getSentPending = (state: State) => state.sentPending;
