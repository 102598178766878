import { throwError, of, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { SessionService } from '../session/session.service';
import { catchError, first, mergeMap, take, tap } from 'rxjs/operators';
import * as fromAuth from 'app/auth/store/reducers';
import * as fromRoot from '../../../../reducers';
import { Store } from '@ngrx/store';
import { AuthApiActions, LogoutComponentActions } from '../../../auth/store/actions';
import { isPlatformBrowser } from '@angular/common';
import * as LoginPageActions from 'app/auth/store/actions/login-page.actions';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { MemberService } from 'app/shared/services/member/member.service';

interface SessionResponse {
  access_token: string;
  user: object;
  remember_me_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static INVITED_MEMBERS_AIRLINE_CODE = '100';
  static INVITED_MEMBERS_ORGANIZATION_ID = '1326';
  credentials = {
    airline: '',
    employee_number: '',
    password: '',
    sso: '',
    recovery_from_admin: false,
    remember_me: 1,
    redirect: ''
  };

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private store: Store<fromRoot.State>,
    private airlinesService: AirlinesService,
    private cookieService: SsrCookieService,
    private analyticsService: AnalyticsService,
    private memberService: MemberService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  loginByParams(arQueryParams) {
    this.SSOLogin(arQueryParams);
    this.samlOauth2Login(arQueryParams);
  }

  private SSOLogin(queryParams) {
    const sso = queryParams.sso;
    this.credentials.airline = queryParams.uairline;
    this.credentials.employee_number = queryParams.uname;
    this.credentials.password = queryParams.sso;
    this.credentials.sso = '1';
    if (sso && sso.indexOf('cobus_') !== -1) {
      localStorage.removeItem('flights');
      this.credentials.redirect = 'cobus/searches/new';
      this.loginWithCredentials();
    }
    if (queryParams.recovery && sso) {
      this.credentials.recovery_from_admin = queryParams.recovery.indexOf('#login') > -1 ?
        queryParams.recovery.replace('#login', '') : queryParams.recovery;
      this.credentials.sso = '0';
      this.loginWithCredentials();
    }
    if (queryParams.adminLogin) {
      this.loginWithCredentials();
    }
  }

  loginWithCredentials() {
    this.store.dispatch(new LogoutComponentActions.Logout());
    this.store.dispatch(new LoginPageActions.Login({ credentials: { ...this.credentials } }));
  }

  private samlOauth2Login(queryParams) {
    if (queryParams.sso_token) {
      this.getSSOTokenInfo(queryParams.sso_token).pipe(
        take(1)
      ).subscribe(
        (data) => {
          const prevRedirection = this.getRedirectionURL();
          const redirect = data.redirect ? decodeURIComponent(prevRedirection ? prevRedirection : data.redirect) : '/';
          this.callAction(data.access_token, redirect);
        }
      );
    }
  }

  private getRedirectionURL(): string {
    const redirectURL = this.cookieService.get('redirectURL');
    this.cookieService.delete('redirectURL');
    return redirectURL ? redirectURL : '';
  }

  loginThirdPartyAccount(token, platform, remember: '0' | '1' = '0') {
    const credentials = {
      token,
      platform,
      is_desktop: true,
      remember_me: remember
    };
    return this.http.post<any>(`${environment.apiUrl}/api/v1/third_parties/authenticate`, credentials, {});
  }

  connectThirdPartyAccount(token, platform) {
    const credentials = {
      token,
      is_desktop: true,
      platform
    };
    return this.http.post<any>(`${environment.apiUrl}/api/v1/third_parties/connect`, credentials, {});
  }

  disconnectThirdPartyAccount(platform) {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/third_parties/disconnect`, { platform }, {});
  }

  load_from_cookie() {
    this.sessionService.loadFromCookie();
    if (!this.sessionService.getAccessToken() && !this.sessionService.getRememberMe()) {
      return of(null);
    }

    return this.load_session()
      .pipe(
        mergeMap(
          (response) => {
            this.sessionService.setPartialState(response);
            return of(response);
          }
        )
      );
  }

  login(credentials) {
    return this.create_session(credentials)
      .pipe(
        mergeMap(
          (response) => {
            this.sessionService.setState(response);
            return of(response);
          }
        )
      );
  }

  refreshAccessToken() {
    return this.store.select(fromAuth.getUser).pipe(
      first(),
      mergeMap((user) => {
        if (!user.access_token || !user.remember_me_token) {
          const err = new Error('Remember me was not configured');
          err['status'] = 401;
          this.sessionService.clearState();
          return throwError(err);
        }

        return this.load_session({
          headers: new HttpHeaders({
            'No-Auth': 'true',
            Authorization: `Token token=${user.access_token}`,
            'X-AIRLINE': user.member.airline_code,
            'X-API-CALL-V2': 'true',
            'X-REMEMBER-ME-TOKEN': user.remember_me_token,
            'X-USER': user.member.employee_number
          })
        }).pipe(
          mergeMap((response) => {
            this.sessionService.extendRememberMeExpiration();
            return of(response);
          }),
          catchError(
            (error) => {
              if (error.status === 401) {
                this.sessionService.clearState();
              }

              return throwError(error);
            }
          )
        );
      })
    );

  }

  protected create_session(credentials) {
    return this.http.post<SessionResponse>(
      `${environment.apiUrl}/api/v1/sessions`,
      credentials,
      {
        headers: new HttpHeaders().set('No-Auth', 'true'),
      }
    );
  }

  protected load_session(options = {}) {
    return this.http.get<SessionResponse>(`${environment.apiUrl}/api/v1/sessions/show`, options);
  }

  memberInformation(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/current_account.json`);
  }

  currentUser(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/current_user.json`);
  }

  getSSOTokenInfo(ssoToken): Observable<any> {
    const params = new HttpParams().set('sso_token', ssoToken);
    return this.http.get(`${environment.apiUrl}/sso/token-info.json`, { params });
  }

  firstTime(credentials): Observable<any> {
    return this.http.post(`${environment.apiUrl}/first_time.json`, credentials, {});
  }

  callAction(token: string, url: string) {
    this.store.dispatch(new AuthApiActions.ReloadSSOUser({ accessToken: token, redirect: url }));
  }
  returnStoreUser() {
    return this.store.select<any>(fromAuth.selectAuthState);
  }
}

export function authFactory(authService: AuthService) {
  return () => authService.load_from_cookie()
    .pipe(
      tap((response) => {
        if (!response) { return; }
        const url = window.location.href.split(window.location.origin).pop();
        authService.returnStoreUser().pipe(take(1)).subscribe((auth) => {
          if ((!auth || !auth.status || !auth.status.user || !auth.status.user.access_token)) {
            return authService.callAction(response.access_token, url);
          }
          return false;
        });
      })
    )
    .toPromise()
    .catch((err: any) => {
      console.error('Uncaught authFactory Error', err);
    });
}
