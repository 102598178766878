import { environment } from '../environments/environment';
import { ActionReducerMap, ActionReducer, MetaReducer, INIT } from '@ngrx/store';

import { localStorageSync } from 'ngrx-store-localstorage';
import * as fromRouter from '@ngrx/router-store';
import { AuthApiActionTypes } from 'app/auth/store/actions/auth-api.actions';
import { State as AuthStatusState } from 'app/auth/store/reducers/auth.reducer';
import { TransferState, makeStateKey } from '@angular/core';

declare var IS_SERVER: boolean;

export interface State {
  router: fromRouter.RouterReducerState;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer
};

export const selectRouter = (state: State) => state.router;
export const {
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = fromRouter.getRouterSelectors(selectRouter);

function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  const isServer = (typeof IS_SERVER !== 'undefined') && IS_SERVER;
  if (isServer) { return reducer; }
  return (state: State, action: any): any => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    return localStorageSync({
      checkStorageAvailability: true,
      keys: ['auth', 'flights', 'hotels', 'profile', 'shared', 'trips', 'cruises'],
      rehydrate: true,
      removeOnUndefined: false,
    })(reducer)(state, action);
  }
}

function clearState(reducer: ActionReducer<State>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === AuthApiActionTypes.LogoutSuccess) {
      const reducers = {
        ...state,
        auth: {},
        cruises: {},
        flights: {},
        hotels: {},
        profile: {},
        trips: {},
        router: null,
        shared: {
          airlines: state.shared.airlines,
          flags: {
            airlineFlags: state.shared.flags?.airlineFlags,
            config: state.shared.flags?.config
          },
          modals: state.shared.modals
        }
      };
      return reducer(reducers, action);
    }

    return reducer(state, action);
  };
}

export function transferSsrNgrxStateFactory(transferState: TransferState): MetaReducer<State> {
  return (reducer: ActionReducer<State>): ActionReducer<State> => {
    const ngrxStateKey = makeStateKey<AuthStatusState>('NGRX_STATE');
    const isServer = (typeof IS_SERVER !== 'undefined') && IS_SERVER;

    return (state: State, action: any): any => {
      if (isServer) {
        transferState.set(ngrxStateKey, (state as any)?.auth?.status || {});
        return reducer(state, action);

      } else {
        if (action.type === INIT) {
          const serverState = transferState.get(ngrxStateKey, null);
          if (serverState) { // if ssr is active
            const mergedState = { ...state };
            if (typeof mergedState['auth'] === 'undefined') {
              mergedState['auth'] = {};
            }
            mergedState['auth'].status = serverState;
            return reducer(mergedState, action);
          }
        }
        return reducer(state, action);
      }
    }
  }
}

export const metaReducers: MetaReducer<State>[] = environment.production ?
  [clearState] :
  [clearState, logger];
