import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RateService {
  
  constructor(private http: HttpClient) {}

  getRate(currencyCode:string) {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/member_credits/rates?userCurrency=${currencyCode}`, {});
  }

}
