import { createReducer, on, Action } from '@ngrx/store';
import * as RateActions from '../actions/rate.actions';

export interface State {
  rate: any;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  rate: null,
  loading: false,
  error: null,
};

const rateReducerInternal = createReducer(
  initialState,
  on(RateActions.loadRate, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(RateActions.loadRateSuccess, (state, { rate }) => ({
    ...state,
    rate,
    loading: false,
    error: null,
  })),
  on(RateActions.loadRateFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(RateActions.clear, () => ({
    rate : null,
    loading: false,
    error: null
  })),);

export function reducer(state: State | undefined, action: Action) {
  return rateReducerInternal(state, action);
}
