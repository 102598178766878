import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfilePage } from './containers/profile/profile.page';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { MemberSavingsCardComponent } from './components/member-savings-card/member-savings-card.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProfileAddressComponent } from './components/address/profile-address.component';
import { EmploymentInformationComponent } from './components/employment-information/employment-information.component';
import { PassportComponent } from './components/passport/passport.component';
import { EligibleTravelersComponent } from './components/eligible-travelers/eligible-travelers.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { LoginAndSecurityComponent } from './components/login-and-security/login-and-security.component';
import { PaymentMethodsCardComponent } from './components/payment-card/payment-methods-card.component';
import { MomentModule } from 'ngx-moment';
import { AddPaymentMethodComponent } from './components/add-payment/add-payment-method.component';
import { WatchlistPage } from './containers/watchlist/watchlist.page';
import { BaseWatchlistCardComponent } from './components/watchlist-card/base-watchlist-card.component';
import { FlightWatchlistCardComponent } from './components/flight-watchlist/flight-watchlist-card.component';
import { HotelWatchlistCardComponent } from './components/hotel-watchlist/hotel-watchlist-card.component';
import { WatcherStatusComponent } from './components/flights/watcher-status.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { EligibleTravelerComponent } from './components/eligible-travelers/eligible-traveler/eligible-traveler.component';
import { DeleteProfileModal } from 'app/profile/components/delete-profile/delete-profile-modal';
import { InviteEligibleTravelerModal } from 'app/profile/components/eligible-travelers/invite-eligible-traveler-modal/invite-eligible-traveler-modal';
import { HotelCreditsComponent } from './components/hotel-credits/hotel-credits.component';
import { HotelCreditHistoryComponent } from './components/hotel-credits-history/hotel-credit-history.component';
import { MemberLoyaltyCardComponent } from 'app/profile/components/member-loyalty-card/member-loyalty-card.component';
import { EffectsModule } from '@ngrx/effects';
import { UnlinkEmailRedirectPage } from './containers/unlink-email-redirect/unlink-email-redirect.page';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GamificationProfileSectionComponent } from './components/gamification-profile-section/gamification-profile-section.component';
import { EligibleTravelerCardComponent } from './components/eligible-travelers/eligible-traveler-card/eligible-traveler-card.component';
import { EligibleTravelerFlightAccessComponent } from './components/eligible-travelers/eligible-traveler-flight-access/eligible-traveler-flight-access.component';
import { EligibleTravelerFlightAccessButtonComponent } from './components/eligible-travelers/eligible-traveler-flight-access-button/eligible-traveler-flight-access-button.component';
import { HotelPricingModule } from 'app/hotels/modules/hotel-pricing/hotel-pricing.module';
import { effects as profileEffects } from "app/profile/store/effects";
import { ProfileRoutingModule } from './profile-routing.module';
import { DateAgoPipe } from 'app/shared/pipes/date/date-ago.pipe';
import { HotelCreditsHistoryMobileComponent } from 'app/profile/components/hotel-credits-history-mobile/hotel-credits-history-mobile.component';
import { NotificationCenterComponent } from './components/notification-center/notification-center.component';

export const COMPONENTS = [
  AddPaymentMethodComponent,
  MemberSavingsCardComponent,
  MemberLoyaltyCardComponent,
  BaseWatchlistCardComponent,
  ChangePasswordComponent,
  DeleteProfileModal,
  EditProfileComponent,
  EligibleTravelerComponent,
  EligibleTravelersComponent,
  EligibleTravelerCardComponent,
  EligibleTravelerFlightAccessComponent,
  EligibleTravelerFlightAccessButtonComponent,
  EmploymentInformationComponent,
  FlightWatchlistCardComponent,
  HotelWatchlistCardComponent,
  PassportComponent,
  PaymentMethodsCardComponent,
  PaymentMethodsComponent,
  ProfileAddressComponent,
  ProfilePage,
  WatcherStatusComponent,
  WatchlistPage,
  InviteEligibleTravelerModal,
  LoginAndSecurityComponent,
  HotelCreditsComponent,
  HotelCreditHistoryComponent,
  NotificationCenterComponent,
  UnlinkEmailRedirectPage,
  GamificationProfileSectionComponent,
  HotelCreditsHistoryMobileComponent,
  DateAgoPipe,
];

@NgModule({
  imports: [
    ProfileRoutingModule,
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MomentModule,
    NgbModule,
    FormsModule,
    FontAwesomeModule,
    NgSelectModule,
    HotelPricingModule,
    EffectsModule.forFeature(profileEffects),
  ],
  declarations: COMPONENTS,
  providers: [
    DateAgoPipe,
  ],
})
export class ProfileModule {}
