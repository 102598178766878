import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  flags = {
    en: 'us'
  };

  constructor(
    private http: HttpClient
  ) { }

  getLanguages(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/ups_user_preferences/available_languages`, {}).pipe(
      map((resp: any[]) => {
        const languages = [];

        resp.forEach((language) => {
          languages.push({
            ...language,
            flag: this.flags[language.code] || language.code
          });
        });

        return languages;
      })
    );
  }

  getPreferences(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/ups_user_preferences`);
  }

  updatePreferences(params: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/ups_user_preferences`, params);
  }

  getMemberSubscriptionLists(email: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/ups_email_subscriptions/interests?email=${encodeURIComponent(email)}`);
  }

  setMemberStatus(name: string, subscribed: boolean): Observable<any> {
    const params = { name, subscribed };
    return this.http.patch(`${environment.apiUrl}/api/v1/ups_email_subscriptions/updateSubscription`, params);
  }
}
