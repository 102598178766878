import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { MemberInterestsActions } from '../actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserPreferencesService } from 'app/shared/services/user-preferences/user-preferences.service';

@Injectable()
export class MemberInterestsEffects {

  getMemberInterests$ = createEffect(()=>
    this.actions$.pipe(
      ofType<MemberInterestsActions.GetMemberInterests>(MemberInterestsActions.MemberInterestsActionTypes.GetMemberInterests),
      switchMap((action) => {
        return this.userPreferencesService.getMemberSubscriptionLists(action.payload.email).pipe(
          map(response => new MemberInterestsActions.GetMemberInterestsSuccess({ interests: response })),
          catchError(error => of(new MemberInterestsActions.GetMemberInterestsFailure(error)))
        );
      })
    )
  );

  setMemberStatus$ = createEffect(()=>
    this.actions$.pipe(
      ofType<MemberInterestsActions.SetMemberStatus>(MemberInterestsActions.MemberInterestsActionTypes.SetMemberStatus),
      switchMap((action) => {
        return this.userPreferencesService.setMemberStatus(action.payload.listName, action.payload.subscribed).pipe(
          map(response => new MemberInterestsActions.SetMemberStatusSuccess({ response: response.interests })),
          catchError(error => of(new MemberInterestsActions.SetMemberStatusFailure(error)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private userPreferencesService: UserPreferencesService
  ) {}
}
