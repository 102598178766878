import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from 'reducers';
import * as fromSearchForm from './search-form.reducer';
import * as fromCobusAdmin from './cobus-admin.reducer';
import * as fromSelectedFlights from './selected-flights.reducer';
import { SearchFormState } from 'app/flights/models/searches.interface';

export interface FlightsState {
  searchForm: SearchFormState;
  cobusAdmin: fromCobusAdmin.CobusAdminState;
  selectedFlights: fromSelectedFlights.SelectedFlightsState;
}

export interface State extends fromRoot.State {
  flights: FlightsState;
}

export const reducers: ActionReducerMap<FlightsState> = {
  searchForm: fromSearchForm.reducer,
  cobusAdmin: fromCobusAdmin.reducer,
  selectedFlights: fromSelectedFlights.reducer,
};

export const selectFlightsState = createFeatureSelector<FlightsState>('flights');
export const selectSearchFormState = createSelector(selectFlightsState, (state: FlightsState) => state.searchForm);
export const selectCobusAdminState = createSelector(selectFlightsState, (state: FlightsState) => state.cobusAdmin);
export const selectSelectedFlightsState = createSelector(selectFlightsState, (state: FlightsState) => state.selectedFlights);

export const getSearchFormConfigTicketing = createSelector(selectSearchFormState, fromSearchForm.getSearchFormConfigTicketing);
export const getSearchFormConfigListing = createSelector(selectSearchFormState, fromSearchForm.getSearchFormConfigListing);
export const getSearchFormConfigCobusAuthorization =
  createSelector(selectSearchFormState, fromSearchForm.getSearchFormConfigCobus);

export const searchFlights = createSelector(selectSearchFormState, fromSearchForm.searchFlights);
export const initialTransportingAirlines = createSelector(selectSearchFormState, fromSearchForm.initialTransportingAirlines);
export const selectSearchFlightState = createSelector(selectSearchFormState, state => state.searchFlights);
export const selectLegsState = createSelector(selectSearchFormState, state => state.searchFlights.searchParams.legs);
export const selectPassengersState = createSelector(selectSearchFormState, state => state.searchFlights.searchParams.passengers);
export const selectSpecialPassengersState = createSelector(
  selectSearchFormState, state => state.searchFlights.searchParams.special_passengers
);
export const getSearchFormPendingTicketing = createSelector(selectSearchFormState, fromSearchForm.getPendingTicketing);
export const getSearchFormPendingListing = createSelector(selectSearchFormState, fromSearchForm.getPendingListing);
export const getSearchFormPendingCobusAuthorization = createSelector(selectSearchFormState, fromSearchForm.getPendingCobus);
export const getParentAirlineCode = createSelector(selectSearchFormState, state => state.searchFormConfig.ticketing.passengers.employee?.carrierCode);

export const getCobusAdminAuthorizationPage = createSelector(selectCobusAdminState, state =>
  state.authorizationPage);
export const getCobusAdminAuthorizationPagePending = createSelector(selectCobusAdminState, state =>
  state.pending.authorizationPage);
export const getCobusAdminReasonsForTravel = createSelector(selectCobusAdminState, state =>
  state.reasonsForTravel);
export const getCobusAdminReasonsForTravelPending = createSelector(selectCobusAdminState, state =>
  state.pending.reasonsForTravel);
export const getValidatedEmployeeData = createSelector(selectCobusAdminState, state => state.employeeValidation);
export const getValidatedEmployeeDataPending = createSelector(selectCobusAdminState, state =>
  state.pending.employeeValidation);
export const getCreateAuthorizationPending = createSelector(selectCobusAdminState, state => state.pending.createAuthorization);
export const getCreateAuthorization = createSelector(selectCobusAdminState, state => state.createAuthorization);
export const getCobusSearchFormConfig = createSelector(selectCobusAdminState, state => state.getCobusSearchFormConfig);
export const getCobusAdminErrorState = createSelector(selectCobusAdminState, state => state.error);
export const getCobusAdminSearchFormPending = createSelector(selectCobusAdminState, state =>
  state.pending.getCobusSearchFormConfig);
export const getCobusAdminSearchForm = createSelector(selectCobusAdminState, state =>
  state.getCobusSearchFormConfig);

export const getSelectedFlights = createSelector(selectSelectedFlightsState, state => state.flights);
export const getSelectedFlightsPending = createSelector(selectSelectedFlightsState, state => state.pending);
