import { NgModule, TransferState } from "@angular/core";
import { META_REDUCERS, StoreModule } from "@ngrx/store";
import { reducers, metaReducers, transferSsrNgrxStateFactory, localStorageSyncReducer } from 'reducers';
import { reducers as authReducers } from './auth/store';
import { reducers as cruisesReducers } from './cruises/store/reducers';
import { reducers as flightsReducers } from './flights/store';
import { reducers as hotelsReducers } from './hotels/store/reducers';
import { reducers as profileReducers } from './profile/store/reducers';
import { reducers as sharedReducers } from './shared/store/reducers';
import { reducers as tripsReducers } from './trips/store/reducers';
import { inviteFeatureKey, reducers as inviteReducers } from "./invite/store/reducers";
import { RouterState, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from '@env/environment';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    StoreDevtoolsModule.instrument({
      name: 'ID90 Desktop',
      logOnly: environment.production,
      connectInZone: true,
    }),
    StoreModule.forFeature('auth', authReducers),
    StoreModule.forFeature('cruises', cruisesReducers),
    StoreModule.forFeature('flights', flightsReducers),
    StoreModule.forFeature('hotels', hotelsReducers),
    StoreModule.forFeature(inviteFeatureKey, inviteReducers),
    StoreModule.forFeature('profile', profileReducers),
    StoreModule.forFeature('shared', sharedReducers),
    StoreModule.forFeature('trips', tripsReducers),
  ],
  providers: [
    {
      provide: META_REDUCERS,
      useValue: localStorageSyncReducer,
      multi: true,
    },
    {
      provide: META_REDUCERS,
      deps: [ TransferState ],
      useFactory: transferSsrNgrxStateFactory,
      multi: true,
    },
  ],
})
export class CoreStoreModule { }
