import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-storyblok',
  templateUrl: './card-storyblok.component.html',
  styleUrls: ['./card-storyblok.component.scss']
})
export class CardStoryblokComponent {
  @Input() template: any;
  @Input() primaryText: any;
  @Input() secondaryText: any;
  @Input() tertiaryText: any;
  @Input() imageSrc: any;
  @Input() linkUrl: any;
  @Input() debug: any;

  constructor(
  ) {}

  onClicked() {
    if (!this.linkUrl) { return; }
    window.open(this.linkUrl, '_blank');
  }
}
