import { SearchFormActions } from 'app/flights/store/actions';
import { AuthApiActions } from 'app/auth/store/actions';
import { SearchFormState } from 'app/flights/models/searches.interface';

export const initialState: SearchFormState = {
  error: null,
  pending: {
    ticketing: false,
    listing: false,
    cobus: false,
  },
  searchFlights: null,
  initialTransportingAirlines: null,
  searchFormConfig: {
    listing: null,
    ticketing: null,
    cobus: null,
  },
  searchId: ''
};

const isAnObject = (o => o !== undefined && o !== null) as (o: unknown) => o is {}

export function reducer(
  state = initialState,
  action:
    | SearchFormActions.SearchFormActionsUnion
    | AuthApiActions.AuthApiActionsUnion
    | SearchFormActions.SearchFlights
): SearchFormState {
  switch (action.type) {
    case SearchFormActions.SearchFormActionTypes.LoadSearchForm:
      return {
        ...state,
        pending: {
          ticketing: action.payload.options.toolKey === 'ticketing',
          listing:  action.payload.options.toolKey === 'listing',
          cobus: action.payload.options.toolKey === 'cobus',
        }
      };
    case SearchFormActions.SearchFormActionTypes.LoadSearchFormSuccess:
      return {
        ...state,
        pending: {
          ticketing: false,
          listing: false,
          cobus: false,
        },
        searchFormConfig: {
          ticketing: action.payload.toolKey === 'ticketing' ? action.payload.data : state.searchFormConfig.ticketing,
          listing: action.payload.toolKey === 'listing' ? action.payload.data : state.searchFormConfig.listing,
          cobus: action.payload.toolKey === 'cobus' ? action.payload.data : state.searchFormConfig.cobus,
        },
        error: initialState.error
      };
    case SearchFormActions.SearchFormActionTypes.LoadSearchFormFailure:
      return {
        ...state,
        pending: {
          ticketing: false,
          listing: false,
          cobus: false,
        },
        error: action.payload.error
      };
    case SearchFormActions.SearchFormActionTypes.LoadSearchFormRedirect:
      return {
        ...state,
        pending: {
          ticketing: false,
          listing: false,
          cobus: false,
        },
        searchFormConfig: {
          ticketing: initialState.searchFormConfig.ticketing,
          listing: initialState.searchFormConfig.listing,
          cobus: initialState.searchFormConfig.cobus,
        },
        error: initialState.error
      };
    case AuthApiActions.AuthApiActionTypes.LoginSuccess:
    case AuthApiActions.AuthApiActionTypes.LogoutSuccess:
      return {
        ...state,
        searchFormConfig: {
          ticketing: initialState.searchFormConfig.ticketing,
          listing: initialState.searchFormConfig.listing,
          cobus: initialState.searchFormConfig.cobus,
        }
      };
    case SearchFormActions.SearchFormActionTypes.SearchFlights:
      const newTransportingAirlines = action.payload.searchParams.searchParams.transporting_airline;
      const newInitialTransportingAirline = !isAnObject(newTransportingAirlines) ||
        newTransportingAirlines.toString() === ''? ['all'] : newTransportingAirlines;
      return {
        ...state,
        pending: {
          ticketing: true,
          listing: true,
          cobus: true,
        },
        searchFlights: action.payload.searchParams,
        initialTransportingAirlines: action.payload.searchParams.isInitialSearch?
          newInitialTransportingAirline : state.initialTransportingAirlines,
        error: initialState.error
      };
    case SearchFormActions.SearchFormActionTypes.UpdateSearchFlights:
      return {
        ...state,
        pending: {
          ticketing: false,
          listing: false,
          cobus:false,
        },
        searchFlights: action.payload.searchParams,
        error: initialState.error
      };
    case SearchFormActions.SearchFormActionTypes.UpdateTypeOfTrip:
      return {
        ...state,
        searchFlights: {
          ...state.searchFlights,
          searchParams: {
            ...state.searchFlights.searchParams, type_of_trip: action.payload.type
          }
        },
        error: initialState.error
      };
    case SearchFormActions.SearchFormActionTypes.UpdateFlightLegs:
      return {
        ...state,
        searchFlights: {
          ...state.searchFlights,
          searchParams: {
            ...state.searchFlights.searchParams, legs: action.payload.legs
          }
        },
        error: initialState.error
      };
    case SearchFormActions.SearchFormActionTypes.SearchFlightsSuccess:
      return {
        ...state,
        pending: {
          ticketing: false,
          listing: false,
          cobus: false,
        },
        searchId: action.payload.searchId,
        error: initialState.error
      };
    case SearchFormActions.SearchFormActionTypes.ClearSearchFlights:
      return {
        ...state,
        pending: initialState.pending,
        searchFormConfig: initialState.searchFormConfig,
        searchId: initialState.searchId
      };
    case SearchFormActions.SearchFormActionTypes.SetFirstSearchFlag:
      return {
        ...state,
      };
    default: {
      return state;
    }
  }
}

export const getError = (state: SearchFormState) => state.error;

export const getPendingTicketing = (state: SearchFormState) => state.pending.ticketing;
export const getPendingListing = (state: SearchFormState) => state.pending.listing;
export const getPendingCobus = (state: SearchFormState) => state.pending.cobus;

export const getSearchFormConfigTicketing = (state: SearchFormState) => state.searchFormConfig.ticketing;
export const getSearchFormConfigListing = (state: SearchFormState) => state.searchFormConfig.listing;
export const getSearchFormConfigCobus = (state: SearchFormState) => state.searchFormConfig.cobus;

export const searchFlights = (state: SearchFormState) => state.searchFlights;
export const initialTransportingAirlines = (state: SearchFormState) => state.initialTransportingAirlines;
