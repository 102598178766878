import { AccountValidationEffects } from './account-validation.effects';
import { AuthEffects } from './auth.effects';
import { ForgotPasswordEffects } from './forgot-password.effects';
import { LogoutEffects } from './logout-component.effect';
import { MultiFactorAuthenticationEffects } from './multifactor-authentication.effects';
import { ThirdPartyEffects } from './third-party.effects';

export const effects: any[] = [
  AccountValidationEffects,
  AuthEffects,
  ForgotPasswordEffects,
  LogoutEffects,
  MultiFactorAuthenticationEffects,
  ThirdPartyEffects,
];

export * from './account-validation.effects';
export * from './auth.effects';
export * from './forgot-password.effects';
export * from './logout-component.effect';
export * from './multifactor-authentication.effects';
export * from './third-party.effects';
