<ng-template *ngIf="template === 'wide'; else shortCard">
  <div class="card my-2" >
    <div class="row card-body" (click)="onClicked()">
      <div class="col-12 col-md-4 text-center d-md-block">
        <img class="pe-2 destination-image" src = '{{imageSrc}}'/>
      </div>
      <div class="col-12 col-md-8">
        <div class="primary-content">{{primaryText}}</div>
        <div *ngIf="secondaryText" class="secondary-content">{{secondaryText}}</div>
        <div  *ngIf="tertiaryText" class="tertiary-content mb-2">{{tertiaryText}}</div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #shortCard>
  <div class="card clickable-card my-2" (click)="onClicked()">
    <img *ngIf="imageSrc" class="destination-image" src = '{{imageSrc}}' />
    <div class="card-body">
      <div class="primary-content" [innerHTML]="primaryText"></div>
      <div *ngIf="secondaryText"  class="secondary-content" [innerHTML]="secondaryText"></div>
      <div  *ngIf="tertiaryText" class="tertiary-content mb-2" [innerHTML]="tertiaryText"></div>
    </div>
  </div>
</ng-template>

