import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RateService } from 'app/shared/services/rates/rate.service';
import { RateActions } from '../actions';

@Injectable()
export class RateEffects {
  
  loadRate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RateActions.loadRate),
      mergeMap(action =>
        this.rateService.getRate(action.currencyCode).pipe(
          map(response => RateActions.loadRateSuccess({ rate: response.rate })),
          catchError(error => of(RateActions.loadRateFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private rateService: RateService) {}
}
