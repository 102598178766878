import { SelectedFlightsActions } from 'app/flights/store/actions';
import { SelectedFlight } from 'app/flights/models/selected-flight.interface';

export interface SelectedFlightsState {
  flights: SelectedFlight[];
  pending: boolean;
}

export const initialState: SelectedFlightsState = {
  flights: [],
  pending: false,
};

export function reducer(
  state = initialState,
  action:
    | SelectedFlightsActions.SelectedFlightsActionsUnion
): any {
  switch (action.type) {
    case SelectedFlightsActions.SelectedFlightsActionTypes.SelectFlight:
      return {
        ...state,
        pending: true,
      }
    case SelectedFlightsActions.SelectedFlightsActionTypes.SelectFlightSuccess:
      const selectedFlight = action.payload.params;
      const flights = [...state.flights];
      flights[selectedFlight.index] = selectedFlight;
      return {
        ...state,
        flights: flights,
        pending: false,
      }
    case SelectedFlightsActions.SelectedFlightsActionTypes.SelectFlightFailure:
      return {
        ...state,
        pending: false,
      }
    case SelectedFlightsActions.SelectedFlightsActionTypes.ResetFlights:
      return {
        ...state,
        flights: [],
        pending: false,
      }
    default: {
      return state;
    }
  }
}
