import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { AddressFormComponent } from './components/address/address-form.component';
import { CreditComponent } from './components/credit/credit.component';
import { CustomSelectComponent } from './components/select/custom-select.component';
import { PhoneInputComponent } from './components/phone/phone-input.component';
import { DatepickerRangeComponent } from './components/datepicker/datepicker-range.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { LocationsAutocompleteComponent } from './components/locations/locations.autocomplete.component';
import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { PaymentCardComponent } from './components/payment-card/payment-card.component';
import { PaymentComponent } from './components/payment/payment.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomLoaderFactory } from 'app/custom-loader-factory';
import { HttpClient } from '@angular/common/http';
import { NgbModule, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';
import { SimpleModalLinkComponent } from './components/simple-modal-link/simple-modal-link.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { TextPanelComponent } from './components/text/panel/text-panel.component';
import { TruncatedTextComponent } from './components/text/truncated-text/truncated-text.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { FloatingContainerBottom } from './components/floating/floating-container-bottom.component';
import { FullScreenModal } from './components/full-screen-modal/full-screen-modal.component';
import { CounterComponent } from './components/counter/counter.component';
import { ErrorComponent } from './components/error/error.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { GuestLoginBoxComponent } from '../auth/components/guest-login-box.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { CarSearchComponent } from './components/search/cars/car-search.component';
import { CruiseMerchandiseComponent } from './components/merchandise/cruise-merchandise.component';
import { HotelSearchComponent } from './components/search/hotels/hotel-search.component';
import { HotelSearchModal } from './components/search/hotels/modal/hotel-search-modal';
import { MultipleGuestsSectionComponent } from './components/search/hotels/multiple-guests/multiple-guests-section.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SearchComponent } from './components/search/search.component';
import { JustForYouCardComponent } from './components/just-for-you/just-for-you-card.component';
import { JustForYouComponent } from './components/just-for-you/just-for-you.component';
import { RecentViewsSectionComponent } from './components/recent-views/recent-views-section.component';
import { RecommendedDestinationsSectionComponent } from './components/recommended-destinations/recommended-destinations-section.component';
import { AllInclusiveSectionComponent } from './components/all-inclusive/all-inclusive-section.component';
import { DestinationsComponent } from './components/destinations/destinations.component';
import { InviteAFriendCardComponent } from './components/invite/invite-a-friend-card.component';
import { RouterModule } from '@angular/router';
import { PromoCardComponent } from './components/promo/promo-card.component';
import { NeedARoomCardComponent } from './components/need-a-room/need-a-room-card.component';
import { AbandonHotelCardComponent } from './components/abandon-hotel/abandon-hotel-card.component';
import { UserCreditsCardComponent } from './components/user-credits-card/user-credits-card.component';
import { HotelsRecentSearchesSectionComponent } from './components/recent-searches/hotels-recent-searches-section.component';
import { CarsRecentSearchesSectionComponent } from './components/recent-searches/cars-recent-searches-section.component';
import { OurServicesPage } from './containers/our-services/our-services.page';
import { ContactUsPage } from './containers/contact-us/contact-us.page';
import { TermsAndConditionsPage } from './containers/terms-and-conditions/terms-and-conditions.page';
import { GmailContactsComponent } from './components/gmail-contacts/gmail-contacts.component';
import { GmailContactsModalComponent } from './components/gmail-contacts/gmail-contacts-modal.component';
import { RatingComponent } from './components/rating/rating.component';
import { StoredPaymentSelectionComponent } from './components/stored-payment-selection/stored-payment-selection.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NotFoundPage } from './containers/not-found/not-found.page';
import { SimpleModalComponent } from './components/simple-modal/simple-modal.component';
import { UcwordsPipe } from './pipes/ucwords/ucwords.pipe';
import { ContactFilterPipe } from './pipes/contact-filter/contact-filter.pipe';
import { CurrencyConvertPipe } from './pipes/currency-convert/currency-convert.pipe';
import { ClearInputDirective } from './directives/clear-input.directive';
import { NumberOnly } from './directives/number-only.directive';
import { MapComponent } from './components/map/map.component';
import { InsuranceComponent } from './components/insurance/insurance.component';
import { TravelerComponent } from './components/traveler/traveler.component';
import { TripInsuranceInfoComponent } from './components/trip-insurance-info/trip-insurance-info.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ServerNoRenderDirective } from './directives/server-no-render.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NeedARoomWidgetComponent } from './components/need-a-room-widget/need-a-room-widget.component';
import { NeedACarWidgetComponent } from './components/need-a-car-widget/need-a-car-widget.component';
import { CvvModalComponent } from './components/cvv-modal/cvv-modal.component';
import { CvvInformationComponent } from './components/cvv-information/cvv-information.component';
import { PasswordInputComponent } from './components/password/password-input.component';
import { IeNotOptimizedWarningComponent } from './components/ie-not-optimized-warning/ie-not-optimized-warning.component';
import { AvatarCircleComponent } from './components/avatar-circle/avatar-circle.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { AvatarComponent } from 'app/profile/components/avatar/avatar.component';
import { AnnouncementBarComponent } from './components/announcement-bar/announcement-bar.component';
import { FlexAirlinePage } from './containers/flex/flex-airline.page';
import { LinkSocialAccountComponent } from 'app/profile/components/social-account/link-social-account/link-social-account.component';
import { MemberRewardsHomeComponent } from 'app/hotels/components/member-rewards-home/member-rewards-home.component';
import { MemberProgressBarComponent } from 'app/hotels/components/member-progress-bar/member-progress-bar.component';
import { ButtonListComponent } from './components/button-list/button-list.component';
import { CardStoryblokComponent } from './components/card-storyblok/card-storyblok.component';
import { MandatoryInsuranceComponent } from './components/mandatory-insurance/mandatory-insurance.component';
import { ItemComponentDirective } from './components/loading-indicator/item-component.directive';
import { UnlinkEmailModal } from './components/unlink-email-modal/unlink-email-modal';
import { DateTranslatePipe } from 'app/shared/pipes/date-translate/date-translate.pipe';
import { TranslateRelationshipPipe } from './pipes/relationship-translation/translate-relationship.pipe';
import { LoginFormComponent } from 'app/auth/components/login-form.component';
import { AirlinesAutocompleteComponent } from 'app/auth/components/airlines/airlines.autocomplete.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { HiddenInputComponent } from './components/hidden-input/hidden-input.component';
import { TwoStepSecurityModal } from './containers/two-step-security/two-step-security-modal';
import { VerificationCodeFormComponent } from 'app/shared/components/verification-code/verification-code-form.component';
import { FraudAlertComponent } from './components/fraud-alert/fraud-alert.component';
import { SearchPage } from 'app/shared/containers/search/search.page';
import { SocialButtonsComponent } from './components/social-buttons/social-buttons.component';
import { EffectsModule } from '@ngrx/effects';
import { effects as sharedEffects } from "app/shared/store/effects";
import { FeatureFlagDirective } from 'app/shared/directives/feature-flag.directive';
import { AirlinesService } from 'app/shared/services/airlines/airlines.service';
import { ChildAgeSelectorComponent } from './components/child-age-selector/child-age-selector.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AllInclusiveComponent } from './components/search/all-inclusive/all-inclusive.component';
import { CurrencySelectorComponent } from './components/currency-selector/currency-selector.component';
import { JsonLdComponent } from 'app/shared/containers/jsonld/jsonld.component';
import { SwiperDirective } from './directives/swiper.directive';
import { GamificationComponent } from './components/gamification/gamification.component';
import { ParticipationModalComponent } from 'app/shared/components/gamification/participation-modal.component';
import { AnimationCampaignComponent } from './components/animation-campaign/animation-campaign.component';
import { ReviewStaticCardComponent } from './components/review-static-card/review-static-card.component';
import { CompaniesCarouselComponent } from './components/companies-carousel/companies-carousel.component';
import { EditPaymentComponent } from 'app/profile/components/edit-payment/edit-payment.component';
import { DeletePaymentMethodComponent } from 'app/profile/components/delete-payment/delete-payment-method.component';
import { DemandCalendarPipe } from 'app/shared/pipes/demand-calendar/demand-calendar.pipe';
import { StoryblokDirective } from './directives/storyblok.directive';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicPageComponent } from './components/dynamic-page/dynamic-page.component';
import { StoryBlokNameDirective } from './directives/storyblok-name.directive';
import { StoryBlokRenderComponent } from './containers/story-blok-render/story-blok-render.component';
import { SafePipe } from './pipes/safe/safe.pipe';
import { LandingPageLayoutComponent } from './components/landing-page-layout/landing-page-layout.component';
import { HbsptFormComponent } from './components/hbspt-form/hbspt-form.component';
import { HotelPricingModule } from 'app/hotels/modules/hotel-pricing/hotel-pricing.module';
import { CarSearchInternalComponent } from 'app/shared/components/search/cars/internal/car-search-internal.component';
import { CarSearchExternalComponent } from 'app/shared/components/search/cars/external/car-search-external.component';
import { SmartAppBannerModule } from '../hotels/modules/smart-app-banner/smart-app-banner.module';
import { StepperComponent } from './components/stepper/stepper.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { InsuranceTableComponent } from './components/insurance-table/insurance-table.component';
import { ActivitiesBannerComponent } from 'app/shared/components/activities-banner/activities-banner.component';
import { SearchHeroComponent } from './components/search-hero/search-hero.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { InlineDatepickerComponent } from './inline-datepicker/inline-datepicker.component';

const COMPONENTS = [
  AbandonHotelCardComponent,
  ActivitiesBannerComponent,
  AddressFormComponent,
  AirlinesAutocompleteComponent,
  AllInclusiveComponent,
  AllInclusiveSectionComponent,
  AnimationCampaignComponent,
  AnimationCampaignComponent,
  AnnouncementBarComponent,
  AvatarCircleComponent,
  AvatarComponent,
  BarChartComponent,
  BarChartComponent,
  BreadcrumbsComponent,
  ButtonListComponent,
  CardStoryblokComponent,
  CarouselComponent,
  CarSearchComponent,
  CarSearchExternalComponent,
  CarSearchInternalComponent,
  CarsRecentSearchesSectionComponent,
  ChildAgeSelectorComponent,
  ChildAgeSelectorComponent,
  ClearInputDirective,
  CompaniesCarouselComponent,
  ConfirmationDialogComponent,
  ContactFilterPipe,
  ContactUsPage,
  CounterComponent,
  CreditComponent,
  CruiseMerchandiseComponent,
  CurrencyConvertPipe,
  CurrencySelectorComponent,
  CustomSelectComponent,
  CvvInformationComponent,
  CvvModalComponent,
  DatepickerRangeComponent,
  DateTranslatePipe,
  DeletePaymentMethodComponent,
  DeletePaymentMethodComponent,
  DemandCalendarPipe,
  DestinationsComponent,
  DynamicFormComponent,
  DynamicPageComponent,
  EditPaymentComponent,
  EditPaymentComponent,
  ErrorComponent,
  FeatureFlagDirective,
  FlexAirlinePage,
  FloatingContainerBottom,
  FooterComponent,
  FraudAlertComponent,
  FraudAlertComponent,
  FullScreenModal,
  GamificationComponent,
  GmailContactsComponent,
  GmailContactsModalComponent,
  GuestLoginBoxComponent,
  HbsptFormComponent,
  HeaderComponent,
  HiddenInputComponent,
  HotelSearchComponent,
  HotelSearchModal,
  HotelsRecentSearchesSectionComponent,
  IeNotOptimizedWarningComponent,
  ImageGalleryComponent,
  InfiniteScrollComponent,
  InlineDatepickerComponent,
  InsuranceComponent,
  InsuranceTableComponent,
  InviteAFriendCardComponent,
  ItemComponentDirective,
  JsonLdComponent,
  JsonLdComponent,
  JustForYouCardComponent,
  JustForYouComponent,
  LandingPageLayoutComponent,
  LanguageSelectorComponent,
  LinkSocialAccountComponent,
  LoadingIndicatorComponent,
  LocationsAutocompleteComponent,
  LoginFormComponent,
  MandatoryInsuranceComponent,
  MapComponent,
  MemberProgressBarComponent,
  MemberRewardsHomeComponent,
  MultipleGuestsSectionComponent,
  NeedACarWidgetComponent,
  NeedARoomCardComponent,
  NeedARoomWidgetComponent,
  NotFoundPage,
  NumberOnly,
  OurServicesPage,
  ParticipationModalComponent,
  PasswordInputComponent,
  PaymentCardComponent,
  PaymentComponent,
  PersonalDetailsComponent,
  PhoneInputComponent,
  PromoCardComponent,
  RatingComponent,
  RecentViewsSectionComponent,
  RecommendedDestinationsSectionComponent,
  RedirectComponent,
  ReviewStaticCardComponent,
  SafePipe,
  SearchComponent,
  SearchHeroComponent,
  SearchPage,
  ServerNoRenderDirective,
  SimpleModalComponent,
  SimpleModalLinkComponent,
  SocialButtonsComponent,
  SocialButtonsComponent,
  StepperComponent,
  StepperComponent,
  StoredPaymentSelectionComponent,
  StoryblokDirective,
  StoryBlokNameDirective,
  StoryBlokRenderComponent,
  SvgIconComponent,
  SwiperDirective,
  TermsAndConditionsPage,
  TextPanelComponent,
  ToggleComponent,
  TranslateRelationshipPipe,
  TravelerComponent,
  TripInsuranceInfoComponent,
  TruncatedTextComponent,
  TwoStepSecurityModal,
  UcwordsPipe,
  UnlinkEmailModal,
  UserCreditsCardComponent,
  VerificationCodeFormComponent,
];

@NgModule({
  imports: [
    CommonModule,
    LazyLoadImageModule,
    MomentModule,
    NgbModule,
    NgSelectModule,
    NgxCaptchaModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    InfiniteScrollModule,
    HotelPricingModule,
    SmartAppBannerModule,
    NgbOffcanvasModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: CustomLoaderFactory,
        deps: [HttpClient, Store, AirlinesService],
      },
    }),
    EffectsModule.forFeature(sharedEffects),
  ],
  declarations: COMPONENTS,
  exports: [
    ...COMPONENTS,
    CommonModule,
    CurrencyPipe,
    FontAwesomeModule,
    FormsModule,
    LazyLoadImageModule,
    MomentModule,
    NgbModule,
    NgSelectModule,
    NgxSliderModule,
    ReactiveFormsModule,
    TitleCasePipe,
    TranslateModule,
  ],
  providers: [
    UcwordsPipe,
    CurrencyPipe,
    SwiperDirective,
    TitleCasePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
