import { CreditHistoryEffects } from "./credit-history.effects";
import { CreditEffects } from "./credit.effects";
import { MemberInterestsEffects } from "./member-interests.effects";
import { MemberLoyaltyEffects } from "./member-loyalty.effects";
import { PaymentMethodsEffects } from "./payment-methods.effects";
import { ProfileUserEffects } from "./profile-user.effects";
import { PromoCodeEffects } from "./promo-code.effects";
import { SettingsEffects } from "./settings.effects";

export const effects = [
  CreditHistoryEffects,
  CreditEffects,
  MemberInterestsEffects,
  MemberLoyaltyEffects,
  PaymentMethodsEffects,
  ProfileUserEffects,
  PromoCodeEffects,
  SettingsEffects,
];

export * from "./credit-history.effects";
export * from "./credit.effects";
export * from "./member-interests.effects";
export * from "./member-loyalty.effects";
export * from "./payment-methods.effects";
export * from "./profile-user.effects";
export * from "./promo-code.effects";
export * from "./settings.effects";
